import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, tagActions, notifyActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";

const { TAG: ACTION_HEADER } = types;
const { requestGetTag, successGetTag, fail } = tagActions;
const { apiError } = notifyActions;

const baseURL = backendUrl + "/tag";

function* requestGetTagSaga() {
  try {
    const resp = yield axiosInstance().get(baseURL);
    yield put(successGetTag(resp.data));
  } catch (e) {
    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(fail(error.msg));
  }
}

function* requestCreateTag(action) {
  const { title, color } = action;
  try {
    yield axiosInstance().post(baseURL, { title, color });
    yield put(requestGetTag());
  } catch (e) {
    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(fail(error.msg));
  }
}

function* requestDeleteTag(action) {
  const { id } = action;
  try {
    yield axiosInstance().delete(`${baseURL}/${id}`);
    yield put(requestGetTag());
  } catch (e) {
    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(fail(error.msg));
  }
}

function* tagSaga() {
  yield all([
    takeLatest(ACTION_HEADER.REQUEST_GET_TAG, requestGetTagSaga),
    takeLatest(ACTION_HEADER.REQUEST_CREATE_TAG, requestCreateTag),
    takeLatest(ACTION_HEADER.REQUEST_DELETE_TAG, requestDeleteTag),
  ]);
}

export default tagSaga;
