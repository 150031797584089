import { types } from "../actions";

const { CAMPAIGN: ACTION_HEADER } = types;

const initialState = {
  campaigns: [],
  tmpCampaigns: [],
  campaign: null,
  assignLoading: false,
  loading: false,
  error: "",
  fetched: false,
  gsheetURL: {
    loading: false,
    error: "",
    url: "",
  },
};

function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_CREATE: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.SUCCESS_CREATE: {
      const campaigns = state.campaigns ? state.campaigns.slice(0) : [];
      const { _id } = action.campaign;

      const index = campaigns.findIndex((camp) => camp.id === _id);
      if (index === -1) {
        campaigns.push(action.campaign);
      } else {
        campaigns[index] = action.campaign;
      }
      return { ...state, loading: false, campaigns };
    }

    case ACTION_HEADER.REQUEST_DELETE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_DELETE: {
      const campaigns = state.campaigns;
      const { _id } = action;
      const index = campaigns.findIndex((seq) => seq.id === _id);
      if (index !== -1) {
        campaigns.splice(index, 1);
      }
      return { ...state, loading: false, campaigns };
    }

    case ACTION_HEADER.REQUEST_START:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_START: {
      const campaigns = state.campaigns;
      const { data: camp } = action;
      const index = campaigns.findIndex((seq) => seq.id === camp._id);
      if (index !== -1) {
        campaigns[index].isRunning = true;
      }

      return { ...state, loading: false, campaigns };
    }

    case ACTION_HEADER.REQUEST_PAUSE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_PAUSE: {
      const campaigns = state.campaigns;
      const { data: camp } = action;
      const index = campaigns.findIndex((el) => el.id === camp._id);
      if (index !== -1) {
        campaigns[index].isRunning = false;
      }

      return { ...state, loading: false, campaigns };
    }

    case ACTION_HEADER.REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case ACTION_HEADER.REQUEST_GET_FULL:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.SUCCESS_GET_FULL:
      return {
        ...state,
        loading: false,
        fetched: true,
        tmpCampaigns: action.campaigns,
        error: "",
      };

    case ACTION_HEADER.SUCCESS_GET:
      return {
        ...state,
        loading: false,
        campaigns: action.campaigns,
      };

    case ACTION_HEADER.REQUEST_GET_BY_ID:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case ACTION_HEADER.SUCCESS_GET_BY_ID:
      return {
        ...state,
        loading: false,
        campaign: action.campaign,
      };

    case ACTION_HEADER.EXCLUDE_TARGET:
      return { ...state, loading: false };
    case ACTION_HEADER.SUCCESS_EXCLUDE:
      {
        const { campIdentifier, targetIndex } = action;
        const campaigns = state.tmpCampaigns.slice(0);
        const campaign = campaigns.filter(
          (camp) => camp.identifier === campIdentifier
        )[0];
        if (campaign) {
          campaign.targets[targetIndex].notExcluded = !campaign.targets[
            targetIndex
          ].notExcluded;
          return { ...state, loading: false, campaigns };
        }
      }
      break;
    case ACTION_HEADER.UPDATE_NOTE:
      return { ...state, loading: true };
    case ACTION_HEADER.SUCCESS_NOTE:
      return { ...state, loading: false };

    case ACTION_HEADER.REQUEST_ASSIGN_TAGS:
      return { ...state, assignLoading: true };
    case ACTION_HEADER.SUCCESS_ASSIGN_TAGS:
      {
        const { tags, campIdentifier, targetIndex } = action;
        const campaign = state.tmpCampaigns.filter(
          (camp) => camp.identifier === campIdentifier
        )[0];
        if (campaign) {
          campaign.targets[targetIndex].tags = tags;
          let updatedCampaigns = state.tmpCampaigns.filter(
            (camp) => camp.identifier !== campIdentifier
          );
          return {
            ...state,
            assignLoading: false,
            tmpCampaigns: [...updatedCampaigns, campaign],
          };
        }
      }
      break;
    case ACTION_HEADER.REQUEST_EXPORT:
      return { ...state, gsheetURL: { loading: true, error: "", url: "" } };
    case ACTION_HEADER.SUCCESS_EXPORT: {
      const { url } = action;
      return {
        ...state,
        gsheetURL: { ...state.gsheetURL, loading: false, url: url },
      };
    }
    case ACTION_HEADER.FAIL_EXPORT: {
      const { err } = action;
      return {
        ...state,
        gsheetURL: { ...state.gsheetURL, loading: false, error: err },
      };
    }

    // eslint-disable-next-line
    case ACTION_HEADER.REQUEST_CONTACT:
      return { ...state, loading: true };

    case ACTION_HEADER.SUCCESS_CONTACT: {
      const campaigns = state.campaigns.slice(0);
      const { campIdentifier, targetIndex, data } = action;
      const campaign = campaigns.filter(
        (camp) => camp.identifier === campIdentifier
      )[0];
      if (campaign) {
        campaign.targets[targetIndex].contactInfo = data.contactInfo
          ? JSON.parse(data.contactInfo)
          : {};

        return { ...state, campaigns };
      }
    }

    // eslint-disable-next-line
    case ACTION_HEADER.FINISH_LOADING:
      return { ...state, loading: false };

    case ACTION_HEADER.FAIL:
      return { ...state, loading: false, error: action.error };

    case ACTION_HEADER.NEED_FETCH:
      return { ...state, fetched: false };

    case ACTION_HEADER.RESET_CAMPAIGN:
      return {
        ...state,
        campaigns: [],
        tmpCampaigns: [],
        fetched: false,
      };
    case ACTION_HEADER.TARGET_REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.TARGET_SUCCESS_GET:
      return {
        ...state,
        loading: false,
        campaigns: state.campaigns.map((campaign) => {
          if (campaign._id === action.id) {
            campaign.targets = action.targets;
          }
          return campaign;
        }),
        fetched: true,
      };
    case ACTION_HEADER.UPGRADE_TO_PRO:
      return {
        ...state,
        loading: true,
      };
    case ACTION_HEADER.UPGRADE_TO_PRO_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default: {
      return state;
    }
  }
}

export default campaignReducer;
