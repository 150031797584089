import { types } from "../actions";

const { PROSPECT: ACTION_HEADER } = types;

const initialState = {
  blackLinks: [],
  loading: false,
  error: "",
  fetched: false,
  successMessage: "",
};

function prospectReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.BLACK_LINK_REQUEST_ADD: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.BLACK_LINK_SUCCESS_ADD: {
      const blackLinks = state.blackLinks;
      blackLinks.unshift(action.blackLinks);

      return {
        ...state,
        loading: false,
        error: "",
        blackLinks: blackLinks,
      };
    }

    case ACTION_HEADER.BLACK_LINK_REQUEST_DELETE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.BLACK_LINK_SUCCESS_DELETE: {
      const blackLinks = state.blackLinks.slice(0);

      const { _id } = action;
      const index = blackLinks.findIndex((seq) => seq._id === _id);
      if (index !== -1) {
        blackLinks.splice(index, 1);
      }

      return { ...state, loading: false, blackLinks };
    }

    case ACTION_HEADER.BLACK_LINK_REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.BLACK_LINK_SUCCESS_GET:
      return {
        ...state,
        loading: false,
        blackLinks: action.blackLinks,
        fetched: true,
      };
    case ACTION_HEADER.BLACK_COMPANY_REQUEST_ADD: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.BLACK_COMPANY_SUCCESS_ADD: {
      const blackLinks = state.blackCompanies;
      blackLinks.unshift(action.blackCompanies);

      return {
        ...state,
        loading: false,
        error: "",
        blackCompanies: blackLinks,
      };
    }

    case ACTION_HEADER.BLACK_COMPANY_REQUEST_DELETE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.BLACK_COMPANY_SUCCESS_DELETE: {
      const blackCompanies = state.blackCompanies.slice(0);

      const { _id } = action;
      const index = blackCompanies.findIndex((seq) => seq._id === _id);
      if (index !== -1) {
        blackCompanies.splice(index, 1);
      }

      return { ...state, loading: false, blackCompanies };
    }

    case ACTION_HEADER.BLACK_COMPANY_REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.BLACK_COMPANY_SUCCESS_GET:
      return {
        ...state,
        loading: false,
        blackCompanies: action.blackCompanies,
        fetched: true,
      };
    case ACTION_HEADER.FAIL:
      return { ...state, loading: false, error: action.error };
    default: {
      return state;
    }
  }
}

export default prospectReducer;
