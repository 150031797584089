/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

import React, { useState } from "react";
import {
  Elements,
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from "react-stripe-elements";
import { Button, Alert, InputGroup, Input, FormFeedback } from "reactstrap";

import { validator } from "../utils";

const _CardForm = ({
  addPaymentMethod,
  stripe
}) => {
  const [err, setErr] = useState("");

  const doCheckout = (e) => {
    e.preventDefault();
    /**
     * Temporarily hide the stripe elements
     */
    stripe.createToken().then(({ error, token }) => {
      if (!error && token) {
        setErr("");
        addPaymentMethod(token);
      } else {
        setErr(error.message);
      }
    });
  };
  return (
    <form onSubmit={doCheckout} style={{ textAlign: 'left' }}>
      {/**
       * Temporarily hide the stripe elements
       */}
      <InputGroup>
        <Input hidden invalid={!!err} />
        <FormFeedback>{err}</FormFeedback>
      </InputGroup>
      <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Card Number</p>
      <CardNumberElement className="cardNumber" />
      <div className='cards-wrap'>
        <div className='cards-item'>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Expiry date</p>
          <CardExpiryElement className="expiry" /></div>
        <div className='cards-item'>
          <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>CVC</p>
          <CardCVCElement className="cvc" /></div>
      </div>
      <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Card Holder Name</p>

      <Input
        placeholder="Card Holder Name as shown on card"
        type="text"
      />
      <Button block className="btn-round" color="success" style={{ backgroundColor: '#1764d9' }}     >
        Save & Continue
      </Button>
    </form>
  );
};

const CardForm = injectStripe(_CardForm);

function Checkout({
  addPaymentMethod,
}) {
  return (
    <div className="Checkout">
      <Elements>
        <CardForm
          {...{
            addPaymentMethod,
          }}
        />
      </Elements>
    </div>
  );
}

export default Checkout;
