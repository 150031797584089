import React from "react";
import { useLocation } from "react-router-dom";
import "./style.css";

const ErrorPage = (props) => {
  const location = useLocation();
  const errorCode = location.pathname.split("-")[1];
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>{errorCode}</h1>
          <h2>{errorCode === "404" ? "Page not found" : "Server Error"}</h2>
        </div>
        <a href="/">Homepage</a>
      </div>
    </div>
  );
};

export default ErrorPage;
