import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, adminActions, userActions, notifyActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";

const { ADMIN } = types;
const {
  successFileList,
  fail,
  successUsersList,
  successProxyList,
  successProxyUpdate,
  successProxySave,
  successUserInformation,
} = adminActions;
const { removeToken } = userActions;
const { apiError } = notifyActions;

const baseURL = backendUrl + "/admin";
const requestFileListURL = "/filelist";
const requestUsersURL = "/getallusers";
const requestProxyURL = "/getallproxies";
const requestProxyUpdateURL = "/updateproxy";
const requestProxySaveURL = "/saveproxy";
const requestUserInfoURL = "/userinfo";

function* requestFileList() {
  try {
    const resp = yield axiosInstance().get(baseURL + requestFileListURL);
    yield put(successFileList(resp.data));
  } catch (e) {
    // let error = "Network Error";
    // if (e.response) {
    //   if (!e.response.data.errors) {
    //     error = e.response.data;
    //     if (error === "Unauthorized") {
    //       yield put(removeToken());
    //     }
    //   } else if (typeof e.response.data.errors.msg === "object") {
    //     error = e.response.data.errors.msg[0].msg;
    //   } else {
    //     error = e.response.data.errors.msg;
    //   }
    // }
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestUserList() {
  try {
    const resp = yield axiosInstance().get(baseURL + requestUsersURL);
    yield put(successUsersList(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestProxyList() {
  console.log("****** requestProxyList *******");
  try {
    const resp = yield axiosInstance().get(baseURL + requestProxyURL);

    yield put(successProxyList(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestUserInformation(action) {
  const { userId, email } = action;
  try {
    const proxy = yield axiosInstance().post(baseURL + requestUserInfoURL, {
      userId,
      email,
    });

    console.log("****** requestUserInformation RESULT *******");
    const data = proxy.data.data;
    yield put(
      successUserInformation({
        userId,
        data,
      })
    );
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestProxyUpdate(action) {
  const { id, pserver, puser, ppassword } = action;
  console.log("**** requestProxyUpdate ****", id, pserver, puser, ppassword);
  try {
    const proxy = yield axiosInstance().put(baseURL + requestProxyUpdateURL, {
      id,
      pserver,
      puser,
      ppassword,
    });

    console.log("****** requestProxyUpdate RESULT *******");
    const data = proxy.data.data;
    yield put(
      successProxyUpdate({
        linkedins: [],
        server: data.proxyServer,
        user: data.username,
        usage: 0,
        createdAt: data.createdAt,
        id: data._id,
      })
    );
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestProxySave(action) {
  const { pserver, puser, ppassword } = action;
  console.log("**** requestProxySave ****");
  try {
    const proxy = yield axiosInstance().post(baseURL + requestProxySaveURL, {
      pserver,
      puser,
      ppassword,
    });
    console.log("****** requestProxy RESULT *******");
    const data = proxy.data.data;
    yield put(
      successProxySave({
        linkedins: [],
        server: data.proxyServer,
        user: data.username,
        usage: 0,
        createdAt: data.createdAt,
        id: data._id,
      })
    );
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* adminSaga() {
  yield all([
    takeLatest(ADMIN.REQUEST_FILELIST, requestFileList),
    takeLatest(ADMIN.REQUEST_USERLIST, requestUserList),
    takeLatest(ADMIN.REQUEST_PROXYLIST, requestProxyList),
    takeLatest(ADMIN.REQUEST_PROXYUPDATE, requestProxyUpdate),
    takeLatest(ADMIN.REQUEST_PROXYSAVE, requestProxySave),
    takeLatest(ADMIN.REQUEST_USER_INFO, requestUserInformation),
  ]);
}

export default adminSaga;
