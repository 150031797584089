// Camp Dash Page

/* eslint-disable */
// this was added just to clear up the dev console. Pls fix warnings if its possible

import React, { useCallback, useEffect, useState } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { gapi } from "gapi-script";

import logo from "assets/img/li-icon.png";

import "react-circular-progressbar/dist/styles.css";

import LoadingOverlay from "react-loading-overlay";
import Switch from "react-bootstrap-switch";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import ReactTable from "react-table";

import CampaignActionBadge from "../../components/campaigns/CampaignActionBadge";
import { actionToActivity } from "../../../utils";
import {
  excludeTarget,
  requestGetContactInfo,
} from "../../../actions/campaignActions";
import moment from "moment";
import CampaignProspectsMessagingModal from "../../components/campaigns/ProspectMessagingModal/CampaignProspectsMessagingModal";
import CampaignSyncGooglesheetModal from "../../components/campaigns/CampaignSyncGooglesheetModal";

let csvLink = null;
let exportCSV = false;
let exportGoogle = false;

//this component is just a reference. The SingleProspectManagementTable component was extracted from here and should be used in future.
const CampaignDetailsPage = (props) => {
  const {
    match: {
      params: { identifier: detailID, account },
    },
    campaigns,
    loading,
    seqLoading,
    sequences,
    excludeTarget,
    requestGetContactInfo,
  } = props;
  const identifier = detailID.split("prospects-")[1];

  const campaign = campaigns.filter(
    (camp) => camp.identifier === identifier
  )[0];
  let sequence = null;
  let prospectsData = [];

  const [pageIndex, setPageIndex] = useState(0);
  const [alert, setAlert] = useState(null);
  const [isMessagingModalOpened, setIsMessagingModalOpened] = useState(false);
  const [
    isSyncGooglesheetModalOpened,
    setIsSyncGooglesheetModalOpened,
  ] = useState(false);
  const [planType, setPlanType] = setState("");
  useEffect(() => {
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);
    setPlanType(privacy.PLAN);
    window.gapi.load("client:auth2", async () => {
      try {
        await window.gapi.client.init({
          apiKey: "AIzaSyDPEZiiOPeVlsj753eR1dDTRvwIRYw3O80",
          clientId:
            "16671440298-2oaigmlbebj4l0dd9lpapgk35qf2b8bv.apps.googleusercontent.com",
          discoveryDocs: [
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
          ],
          scope: "https://www.googleapis.com/auth/spreadsheets",
        });
      } catch (e) {
        console.error("ooo", e);
      }
    });
  }, []);

  useEffect(() => {
    const getContactInfo = (pageIndex) => {
      if (!campaign) {
        return;
      }
      const { targets } = campaign;
      requestGetContactInfo(
        targets.slice(pageIndex * 10, pageIndex * 10 + 10).map((target, i) => ({
          targetLink: target.targetLink,
          salesLink: target.salesLink,
          campIdentifier: identifier,
          targetIndex: pageIndex * 10 + i,
        }))
      );
    };

    getContactInfo(pageIndex);
  }, [campaign, pageIndex, identifier, requestGetContactInfo]);

  useEffect(() => {
    if (exportCSV && !loading) {
      csvLink.link.click();
      exportCSV = false;
    }
    if (exportGoogle && !loading) {
      syncGoogle();
      exportGoogle = false;
    }
  }, [loading]);

  const toggleMessagingModal = useCallback(() => {
    setIsMessagingModalOpened((prev) => !prev);
  }, []);

  const toggleSyncGooglesheetModal = useCallback(() => {
    if (
      !planType ||
      planType === "FREE_PLAN" ||
      planType === "PROFESSIONAL_PLAN"
    ) {
      return;
    }
    setIsSyncGooglesheetModalOpened((prev) => !prev);
  }, []);

  const getAction = (name) => {
    const campData = campaign.data.filter(
      (campData) => campData.name === name
    )[0];
    const actions = [];

    if (
      campaign.repliedData.findIndex((dat) => {
        return dat.targetName === name;
      }) !== -1
    ) {
      actions.push("Replied");
    }

    if (
      campaign.connectionData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Accepted");
    }

    if (campData) {
      actions.push(
        ...campData.actions
          .filter(Boolean)
          .map(({ name }) => actionToActivity(name))
      );

      // if (actions.indexOf("Replied") !== -1) {
      //   if (actions.indexOf("Requested") !== -1) {
      //     actions.splice(actions.indexOf("Requested"), 1);
      //   }
      //   if (actions.indexOf("Messaged") !== -1) {
      //     actions.splice(actions.indexOf("Messaged"), 1);
      //   }
      // } else if (actions.indexOf("Messaged") !== -1) {
      //   if (actions.indexOf("Requested") !== -1) {
      //     actions.splice(actions.indexOf("Requested"), 1);
      //   }
      // } else if (actions.indexOf("Accepted") !== -1) {
      //   if (actions.indexOf("Requested") !== -1) {
      //     actions.splice(actions.indexOf("Requested"), 1);
      //   }
      // }
    }

    return actions.length ? actions : ["None"];
  };
  const handleSwitch = (
    key,
    targetLink,
    salesLink,
    identifier,
    targetIndex,
    e,
    status
  ) => {
    excludeTarget(key, targetLink, salesLink, identifier, targetIndex, status);
  };
  if (campaign) {
    const { targets } = campaign;
    prospectsData = targets.map(
      (
        {
          imageurl,
          name,
          headline,
          targetLink,
          salesLink,
          notExcluded,
          contactInfo,
        },
        targetIndex
      ) => ({
        replyBtn: {
          /*<Button
            onClick={() => toggleMessagingModal()}
            style={{ margin: 0, padding: "11px 16px", borderRadius: "50%" }}
            hidden={
              campaign.repliedData.findIndex(
                (dat) => dat.targetName === name
              ) === -1
            }
          >
           <FontAwesomeIcon icon={faCommentDots} />
          </Button>*/
        },
        photo: (
          <div className="img-row">
            <div className="img-wrapper">
              <a
                rel="noreferrer noopener"
                href={targetLink || salesLink}
                target="_blank"
              >
                <img
                  style={{ height: 40 }}
                  alt={name}
                  src={
                    imageurl && imageurl.startsWith("https://media-")
                      ? imageurl
                      : require("assets/img/image_placeholder.jpg")
                  }
                />
              </a>
            </div>
          </div>
        ),
        contactInfo,
        name,
        headline,
        targetLink,
        activity: (
          <div className="activity-badges">
            {getAction(name).map((action, index) => (
              <div key={index} className="el-inline">
                {<CampaignActionBadge {...{ action }} />}
              </div>
            ))}
          </div>
        ),
        actions: (
          <div className="actions-center">
            <Switch
              offColor="success"
              offText={<i className="nc-icon nc-simple-remove" />}
              onColor="success"
              onText={<i className="nc-icon nc-check-2" />}
              value={notExcluded}
              onChange={(e, state) =>
                handleSwitch(
                  campaign.browserKey,
                  targetLink,
                  salesLink,
                  identifier,
                  targetIndex,
                  e,
                  state
                )
              }
            />
          </div>
        ),
      })
    );

    if (sequences.length) {
      sequence = sequences.filter((seq) => seq._id === campaign.sequenceId)[0];
    }
  }

  const exportData = (type) => {
    const { targets, connectionData } = campaign;
    requestGetContactInfo(
      connectionData.map((connectedTarget) => {
        const targetIndex = targets.findIndex((target) => {
          return target.name === connectedTarget.targetName;
        });
        const { targetLink, salesLink } = targets[targetIndex];
        return {
          targetLink,
          salesLink,
          campIdentifier: identifier,
          targetIndex,
        };
      })
    );
    switch (type) {
      case "csv":
        exportCSV = true;
        break;
      case "google":
        exportGoogle = true;
      default:
        break;
    }
  };

  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    if (row[id]) {
      const search = [];
      if (row[id] instanceof Object) {
        switch (id) {
          case "contactInfo":
            if (row[id].emailAddress) {
              search.push(row[id].emailAddress);
            }
            if (row[id].phoneNumbers) {
              row[id].phoneNumbers.forEach(({ type, number }) =>
                search.push(type + " " + number)
              );
            }
            break;
          default:
            break;
        }
      } else {
        search.push(row[id]);
      }
      return new RegExp(filter.value, "i").test(search.join(" "));
    }
    return false;
  };

  const ActivityColumnFilter = ({ filter, onChange }) => {
    return (
      <>
        <CampaignActionBadge
          {...{ action: "Replied", onChange, isFilter: true, filter }}
        />
        <CampaignActionBadge
          {...{ action: "All", onChange, isFilter: true, filter }}
        />
      </>
    );
  };

  const ActivityColumnFilterMethod = (filter, row) => {
    return filter.value === "Replied"
      ? campaign.repliedData.findIndex((dat) => dat.targetName === row.name) !==
      -1
      : true;
  };

  const connectionsToExportsData = (connectionData, targets) =>
    connectionData.map((connectedTarget) => {
      const targetIndex = targets.findIndex(
        (target) => target.name === connectedTarget.targetName
      );
      const { name, headline, contactInfo, targetLink } = targets[targetIndex];
      return {
        "Email Address":
          contactInfo && contactInfo.emailAddress
            ? contactInfo.emailAddress
            : "",
        "First Name": name.split(" ")[0],
        "Last Name": name.split(" ").slice(1).join(" "),
        "Job Title": headline.split(" at ")[0],
        Company: headline.split(" at ")[1],
        "Phone Numbers":
          contactInfo && contactInfo.phoneNumbers
            ? contactInfo.phoneNumbers.map(({ number }) => number).join(", ")
            : "",
        Profile: targetLink,
        Websites:
          contactInfo && contactInfo.websites && contactInfo.websites.length > 0
            ? contactInfo.websites
              .map((x) => `${x.url} (${x.type.category})`)
              .join("\n")
            : "",
        Twitter:
          contactInfo &&
            contactInfo.twitterHandles &&
            contactInfo.twitterHandles.length > 0
            ? contactInfo.twitterHandles.map((x) => x.name).join("\n")
            : "",
        Skype:
          contactInfo && contactInfo.ims && contactInfo.ims.length > 0
            ? contactInfo.ims.map((x) => x.id).join(", ")
            : "",
        WeChat:
          contactInfo && contactInfo.weChatContactInfo
            ? contactInfo.weChatContactInfo.name
            : "",
        Birthday:
          contactInfo && contactInfo.birthDateOn
            ? moment(
              `${contactInfo.birthDateOn.month}-${contactInfo.birthDateOn.day}`,
              "MM-DD"
            ).format("MMMM DD")
            : "",
        Address: contactInfo && contactInfo.address ? contactInfo.address : "",
        Connected:
          contactInfo && contactInfo.connectedAt
            ? moment(contactInfo.connectedAt).format("MMMM DD, YYYY")
            : "",
        Interests:
          contactInfo &&
            contactInfo.interests &&
            contactInfo.interests.length > 0
            ? contactInfo.interests.map((x) => x.interest.type).join("\n")
            : "",
      };
    });

  const syncGoogle = () => {
    if (!window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          updateGoogleSheet();
        });
    } else {
      updateGoogleSheet();
    }
  };

  const showConfirmDlg = (spreadsheetId) => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block" }}
        title="Success"
        onConfirm={() => {
          setAlert(null);
        }}
      >
        <span>
          <strong>{campaign && campaign.name.split(" -> ")[1]}</strong> data has
          been synced to your Google sheet.
        </span>
        <br />
        <a
          href={`https://docs.google.com/spreadsheets/d/${spreadsheetId}`}
          style={{ fontSize: "0.9rem" }}
          target="_blank"
        >
          View Google Sheet
        </a>
      </ReactBSAlert>
    );
  };

  const updateGoogleSheet = () => {
    window.gapi.client.sheets.spreadsheets
      .create({
        properties: {
          title: identifier.replace("camp-", ""),
        },
      })
      .then((response) => {
        const {
          result: { spreadsheetId, spreadsheetUrl },
        } = response;
        const headers = [
          "Email Address",
          "First Name",
          "Last Name",
          "Job Title",
          "Phone Numbers",
          "Profile",
          "Websites",
          "Twitter",
          "Skype",
          "WeChat",
          "Birthday",
          "Address",
          "Connected",
          "Interests",
        ];
        const dataToBeAdded = connectionsToExportsData(
          campaign.connectionData,
          campaign.targets
        );
        window.gapi.client.sheets.spreadsheets.values
          .update({
            spreadsheetId,
            range: "Sheet1!A:N",
            valueInputOption: "RAW",
            resource: {
              values: headers.map((header) =>
                [header].concat(dataToBeAdded.map((target) => target[header]))
              ),
              majorDimension: "COLUMNS",
            },
          })
          .then((response) => {
            var result = response.result;
            showConfirmDlg(spreadsheetId);
          });
      });
  };

  return (
    <>
      {alert}
      {/* <CampaignProspectsMessagingModal
        isOpened={isMessagingModalOpened}
        toggleModal={toggleMessagingModal}
      /> */}
      <CampaignSyncGooglesheetModal
        isOpened={isSyncGooglesheetModalOpened}
        toggleModal={toggleSyncGooglesheetModal}
        callback={exportData}
      />
      <LoadingOverlay
        active={loading || seqLoading}
        spinner
        text="Loading Prospects"
      ></LoadingOverlay>
      <div className="content camp-prospect">
        <CSVLink
          data={
            campaign
              ? connectionsToExportsData(
                campaign.connectionData,
                campaign.targets
              )
              : []
          }
          filename={`${identifier + moment().format("_YYYY_MM_DD_HH_mm")}.csv`}
          className="hidden"
          ref={(ref) => (csvLink = ref)}
          target="_blank"
        />
        <CardTitle tag="h3" className="el-inline">
          Prospects Manager &nbsp;
        </CardTitle>
        {/* hide until it is working
        <i
          className="nc-icon nc-alert-circle-i el-inline"
          id="tooltip-ex"
          style={{ position: "relative" }}
        />
        
        <UncontrolledTooltip delay={0} target="tooltip-ex">
          Exclude certain prospects in this campaign by toggling active column.
        </UncontrolledTooltip>
        */}

        <Card className="card-tasks">
          <CardHeader>
            <div className="el-inline" style={{ marginLeft: 15 }}>
              <h3
                className="el-inline"
                style={{ marginBottom: 0, marginTop: 10 }}
              >
                {campaign && campaign.name.split(" -> ")[1]}{" "}
              </h3>
              <span className="text-muted el-inline">
                &nbsp;({campaign && campaign.targets.length})&nbsp;
              </span>
              <span className="el-inline">
                {sequence && (
                  <a
                    href={`/admin/${account}/sequences/edit-${sequence.identifier}`}
                    style={{ position: "relative" }}
                  >
                    <span>{sequence.name}</span>
                  </a>
                )}
              </span>
            </div>

            <UncontrolledDropdown
              className="el-inline pull-right"
              nav
              style={{ position: "absolute", right: "20px" }}
            >
              <DropdownToggle color="default" data-toggle="dropdown" nav>
                <button type="button" className="btn">
                  {/*<i className="nc-icon round nc-cloud-download-93" ></i>*/}
                  Export data
                </button>
              </DropdownToggle>
              <DropdownMenu aria-labelledby="" className="fadeIn">
                <DropdownItem onClick={() => toggleSyncGooglesheetModal()}>
                  Sync w/ Googlesheet
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    exportData("csv");
                  }}
                >
                  Export to CSV
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </CardHeader>
          <CardBody>
            <div className="table-full-width table-responsive prospects-table">
              <ReactTable
                data={prospectsData}
                columns={[
                  {
                    Header: "",
                    accessor: "replyBtn",
                    sortable: false,
                    filterable: false,
                    width: 55,
                  },
                  {
                    Header: "",
                    accessor: "photo",
                    sortable: false,
                    filterable: false,
                    width: 70,
                  },
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: (row) => (
                      <div className="name-row">
                        <div className="">
                          <a
                            rel="noreferrer noopener"
                            href={
                              row.original.targetLink || row.original.salesLink
                            }
                            target="_blank"
                          >
                            <img
                              alt={row.value}
                              src={logo}
                              style={{ width: 15 }}
                            />
                          </a>{" "}
                          &nbsp; {row.value}
                        </div>
                      </div>
                    ),
                    sortable: true,
                    filterable: true,
                  },
                  {
                    Header: "Headline",
                    accessor: "headline",
                    sortable: false,
                    filterable: true,
                    className: "headline",
                    width: 100,
                  },
                  {
                    Header: "Contact Info",
                    accessor: "contactInfo",
                    Cell: (row) => (
                      <div className="name-row">
                        <div className="">
                          {row.original.contactInfo && (
                            <>
                              <strong>{`${row.original.contactInfo.emailAddress || ""
                                }`}</strong>
                              <br />
                              {row.original.contactInfo.phoneNumbers &&
                                row.original.contactInfo.phoneNumbers.map(
                                  ({ type, number }, i) => (
                                    <div
                                      className="text-muted"
                                      key={i}
                                    >{`${type}: ${number}`}</div>
                                  )
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    ),
                    sortable: false,
                    filterable: true,
                  },
                  {
                    Header: "Activity",
                    accessor: "activity",
                    sortable: true,
                    filterable: true,
                    Filter: ActivityColumnFilter,
                    filterMethod: ActivityColumnFilterMethod,
                  },
                  {
                    Header: "Active",
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    width: 70,
                  },
                ]}
                defaultFilterMethod={filterMethod}
                defaultPageSize={10}
                defaultSorted={[
                  {
                    id: "activity",
                  },
                ]}
                showPaginationTop={false}
                showPaginationBottom={true}
                onPageChange={(pageIndex) => setPageIndex(pageIndex)}
                /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                className=" -highlight"
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.campaigns,
  loading: state.campaigns.loading,
  sequences: state.sequences.sequences,
  seqLoading: state.sequences.loading,
  error: state.campaigns.error,
});

const mapDispatchToProps = (dispatch) => ({
  excludeTarget: (
    browserKey,
    targetLink,
    salesLink,
    campIdentifier,
    targetIndex,
    status
  ) =>
    dispatch(
      excludeTarget(
        browserKey,
        targetLink,
        salesLink,
        campIdentifier,
        targetIndex,
        status
      )
    ),
  requestGetContactInfo: (targets) => dispatch(requestGetContactInfo(targets)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(CampaignDetailsPage)
);
