import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, replayTemplatesActions, notifyActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";

const { REPLAYS_TEMPLATES } = types;
const {
  getSuccessReplayTemplates,
  getErrorReplayTemplates,
  createSuccessReplayTemplates,
  createErrorReplayTemplates,
  deleteSuccessReplayTemplates,
  updateSuccessReplayTemplates,
  updateErrorReplayTemplates,
} = replayTemplatesActions;
const { apiError } = notifyActions;

const replayTemplateUrl = backendUrl + "/replytemplate";


function* requestReplayTemplateGet(action) {
  try {

    const replayTemplates = yield axiosInstance().get(replayTemplateUrl);
    yield put(getSuccessReplayTemplates(replayTemplates.data));

  } catch (e) {

    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(getErrorReplayTemplates(error.msg));
  }
}

function* requestReplayTemplateUpdate(actions) {
  try {

    yield axiosInstance().patch(replayTemplateUrl, {
      ...actions.replayTemplate,
      id: actions.replayTemplate._id,
    });
    yield put(updateSuccessReplayTemplates(actions.replayTemplate));

  } catch (e) {

    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(updateErrorReplayTemplates(error.msg));
  }
}

function* requestReplayTemplateCreate(actions) {
  try {

    const replayTemplateResp = yield axiosInstance().post(
      replayTemplateUrl,
      actions.replayTemplate
    );
    yield put(createSuccessReplayTemplates(replayTemplateResp.data));

  } catch (e) {

    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(createErrorReplayTemplates(error.msg));
  }
}

function* requestReplayTemplateDelete(actions) {
  try {

    yield axiosInstance().delete(replayTemplateUrl + "?id=" + actions.replayTemplateId);
    yield put(deleteSuccessReplayTemplates(actions.replayTemplateId));

  } catch (e) {

    const error = getError(e);
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(apiError(error));
    yield put(createErrorReplayTemplates(error.msg));
  }
}

function* replayTemplateSaga() {
  yield all([
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_GET_REQUEST,
      requestReplayTemplateGet
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_UPDATE_REQUEST,
      requestReplayTemplateUpdate
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_CREATE_REQUEST,
      requestReplayTemplateCreate
    ),
    takeLatest(
      REPLAYS_TEMPLATES.REPLAYS_TEMPLATES_DELETE_REQUEST,
      requestReplayTemplateDelete
    ),
  ]);
}

export default replayTemplateSaga;
