import React from "react";
import CampaignPage from "./Campaign/CampaignPage";
import SequencePage from "./Sequence/SequencePage";
import IntegrationPage from "./Integration/IntegrationPage";
import ProspectPage from "./Prospect";
import BlackListPage from "./BlackList";
import SettingsPage from "./SettingsPage";
import ProfileManager from "./ProfileManager";

import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const ManagePage = (props) => {
  const {
    match: {
      params: { account, page },
    },
  } = props;

  if (account === "user-profile") {
    return <ProfileManager {...props} />;
  }
  let privacy = {
    // -1 unlimited  limit ;  0 >= limit ;
    PLAN: "",
    INMAIL_ALLOW_SEQ: true,
    ALLOW_CONNECT_SEQ: true,
    IMPORT_A_CSV_LIST: true,
    EXPORT_A_CSV_LIST: true,
    GOOGLESHEETEXPORT: true,
    BLACKLIST: true,
    INTEGRATION: true,
    CAMPAIGN_MAX: -1,
    PROFILE_VIEW: -1,
    INVITATIONS: -1,
    MESSAGES: -1,
    INMAILS: -1,
    MAX_PROSPECT: -1,
    REPLAY: true,
  };
  if (props.liaccount.planType) {
    if (props.liaccount.planType === "FREE_PLAN") {
      privacy.PLAN = "FREE_PLAN";
      privacy.ALLOW_CONNECT_SEQ = false;
      privacy.INMAIL_ALLOW_SEQ = false;
      privacy.CAMPAIGN_MAX = 1;
      privacy.PROFILE_VIEW = 300;
      privacy.INVITATIONS = 5;
      privacy.MESSAGES = 5;
      privacy.INMAILS = 5;
      privacy.MAX_PROSPECT = 150;
      privacy.REPLAY = false;
      privacy.GOOGLESHEETEXPORT = false;
      privacy.BLACKLIST = false;
      privacy.INTEGRATION = false;
    }
    if (props.liaccount.planType === "PROFESSIONAL_PLAN") {
      privacy.PLAN = "PROFESSIONAL_PLAN";
      privacy.ALLOW_CONNECT_SEQ = true;
      privacy.INMAIL_ALLOW_SEQ = true;
      privacy.CAMPAIGN_MAX = 2;
      privacy.PROFILE_VIEW = 300;
      privacy.INVITATIONS = 30;
      privacy.MESSAGES = 30;
      privacy.INMAILS = 100;
      privacy.MAX_PROSPECT = 900;
      privacy.REPLAY = true;
      privacy.GOOGLESHEETEXPORT = false;
      privacy.BLACKLIST = true;
      privacy.INTEGRATION = true;
    }
    if (props.liaccount.planType === "MAXGROWTH_PLAN") {
      privacy.PLAN = "MAXGROWTH_PLAN";
      privacy.ALLOW_CONNECT_SEQ = true;
      privacy.INMAIL_ALLOW_SEQ = true;
      privacy.CAMPAIGN_MAX = 10;
      privacy.PROFILE_VIEW = 300;
      privacy.INVITATIONS = 100;
      privacy.MESSAGES = 200;
      privacy.INMAILS = 100;
      privacy.MAX_PROSPECT = 3000;
      privacy.REPLAY = true;
      privacy.GOOGLESHEETEXPORT = true;
      privacy.BLACKLIST = true;
      privacy.INTEGRATION = true;
    }
    localStorage.setItem("privacy", JSON.stringify(privacy));
  }

  switch (page) {
    case "campaigns":
      return <CampaignPage {...props} />;
    case "sequences":
      return <SequencePage {...props} />;
    case "settings":
      return <SettingsPage {...props} />;
    case "prospects":
      return <ProspectPage {...props} />;
    case "blacklist":
      return <BlackListPage {...props} />;
    case "user-profile":
      return <ProfileManager {...props} />;
    case "integration":
      return <IntegrationPage {...props} />;
    default:
      props.history.push(`/admin/${account}/campaigns`);
      return null;
  }
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  liaccounts: state.liaccounts.liaccounts,
  campaigns: state.campaigns.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  // requestHideSuccess: () => dispatch(hideSuccessMessage()),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(ManagePage))
);
// export default (React.memo(ManagePage));
