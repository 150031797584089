/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Nav, UncontrolledTooltip } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import UpgradeModal from "../../views/components/UpgradeModal";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (identifier) => {
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);

    const routes = [
      {
        key: "campaigns",
        icon: "nc-icon nc-user-run",
        allow: true,
      },
      {
        key: "sequences",
        icon: "nc-icon nc-sound-wave",
        allow: true,
      },
      {
        key: "prospects",
        icon: "nc-icon nc-sun-fog-29",
        allow: true,
      },
      {
        key: "integration",
        icon: "nc-icon nc-vector",
        allow: true,
      },
      {
        key: "blacklist",
        icon: "nc-icon nc-paper",
        allow: privacy && privacy.BLACKLIST != null ? privacy.BLACKLIST : true,
      },
    ];
    return routes.map(({ allow, key, icon, tooltip, disabled }, index) => {
      return (
        <li
          className={`${this.activeRoute(`/admin/${identifier}/${key}`)}${
            disabled ? " pros-btn-disabled" : ""
          }`}
          key={index}
        >
          {allow === true ? (
            <NavLink
              to={`/admin/${identifier}/${key}`}
              activeClassName=""
              id={`tooltip-${key}`}
            >
              <>
                <i className={icon} />
                <p>{key}</p>
              </>
            </NavLink>
          ) : (
            <Link
              onClick={() => {
                if (this.state.showupgrademodal != null) {
                  this.setState({
                    showupgrademodal: !this.state.showupgrademodal,
                  });
                } else {
                  this.setState({
                    showupgrademodal: true,
                  });
                }
              }}
              activeClassName=""
              id={`tooltip-${key}`}
            >
              <>
                <i className={icon} />
                <p>{key}</p>
              </>
            </Link>
          )}
          {tooltip && (
            <UncontrolledTooltip delay={700} target={`tooltip-${key}`}>
              {tooltip}
            </UncontrolledTooltip>
          )}
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      history: {
        location: { pathname },
      },
    } = this.props;

    const identifier = pathname.split("/")[2];

    return (
      <div
        className="sidebar"
        data-color="brown"
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <div className="logo-img">
            {/* <NavLink to="/admin">
                            <img src={require("assets/img/pp-logo.png")} alt="react-logo"/>
                        </NavLink> */}
            <a href="/admin">
              <img src={require("assets/img/pp-logo.png")} alt="react-logo" />
            </a>
          </div>
        </div>
        {
          <UpgradeModal
            show={this.state.showupgrademodal}
            change={() => {
              if (this.state.showupgrademodal != null) {
                this.setState({
                  showupgrademodal: !this.state.showupgrademodal,
                });
              } else {
                this.setState({
                  showupgrademodal: true,
                });
              }
            }}
          />
        }
        <div className="sidebar-wrapper" ref="sidebar">
          <Nav>
            {this.createLinks(identifier)}
            <li
              className={`${this.activeRoute(`/admin/${identifier}/settings`)}`}
            >
              <NavLink to={`/admin/${identifier}/settings`} activeClassName="">
                <>
                  <i className="nc-icon nc-settings" />
                  <p>Settings</p>
                </>
              </NavLink>
            </li>
          </Nav>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.authentication.user,
});

export default compose(connect(mapStateToProps, null)(Sidebar));
