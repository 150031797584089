import { createActionTypes } from "../utils";

export const USER = createActionTypes("USER", [
  // sign in
  "REQUEST_LOGIN",
  "SUCCESS_LOGIN",
  "FAIL_LOGIN",
  //sign out
  "LOGOUT",
  // register
  "REQUEST_REGISTER",
  "SUCCESS_REGISTER",
  "FAIL_REGISTER",

  "REQUEST_PAYMENT",
  "SUCCESS_PAYMENT",
  "FAIL_PAYMENT",

  // get user
  "REQUEST_GETUSER",
  "SUCCESS_GETUSER",
  "FAIL_GETUSER",
  // verify email
  "REQUEST_VERIFY",
  // reset password
  "REQUEST_RESET_PASSWORD",
  "SUCCESS_RESET_PASSWORD",
  "FAIL_RESET_PASSWORD",
  "REQUEST_FORGOT_PASSWORD",
  "SUCCESS_FORGOT_PASSWORD",
  "FAIL_FORGOT_PASSWORD",
  // remove token
  "REMOVE_TOKEN",
  // show success message
  "SHOW_SUCCESS_MESSAGE",
  "HIDE_SUCCESS_MESSAGE",
  "SHOW_ERROR_MESSAGE",
  "HIDE_ERROR_MESSAGE",

  "REQUEST_CHECK_COUPON",
  "SUCCESS_CHECK_COUPON",
  "FAIL_CHECK_COUPON",

]);

export const PLAN = createActionTypes("PLAN", [
  // get plans
  "REQUEST_PLANS",
  "SUCCESS_PLANS",
  // activate plan
  "REQUEST_ACTIVATE",
  "SUCCESS_ACTIVATE",
  // upgrade plan
  "REQUEST_UPGRADE",
  "SUCCESS_UPGRADE",
  // unsubscribe
  "REQUEST_UNSUBSCRIBE",
  "SUCCESS_UNSUBSCRIBE",
  // cancel subscription
  "REQUEST_CANCEL_SUBSCRIPTION",
  "SUCCESS_CANCEL_SUBSCRIPTION",
  // reactive plan
  "REQUEST_REACTIVATE",
  "SUCCESS_REACTIVATE",
  // fail
  "FAIL",
]);

export const LIACCOUNT = createActionTypes("LIACCOUNT", [
  // add linkedin account
  "REQUEST_ADD",
  "SUCCESS_ADD",
  // get linkedin accounts
  "REQUEST_GET",
  "SUCCESS_GET",
  // get linkedin account by id
  "REQUEST_GET_BY_ID",
  "SUCCESS_GET_BY_ID",

  "REQUEST_GET_MESSAGES_BY_ID",
  "SUCCESS_GET_MESSAGES_BY_ID",
  // delete linkedin accounts
  "REQUEST_DELETE",
  "SUCCESS_DELETE",
  // edit linkedin accounts
  "REQUEST_HEAD_EDIT",
  "SUCCESS_HEAD_EDIT",
  "FAIL_HEAD_EDIT",
  "REQUEST_ABOUT_EDIT",
  "SUCCESS_ABOUT_EDIT",
  "FAIL_ABOUT_EDIT",
  // change settings
  "REQUEST_SETTINGS",
  "SUCCESS_SETTINGS",
  "REQUEST_EMAIL_CRED_SAVE",
  "SUCCESS_EMAIL_CRED_SAVE",
  // send pin
  "REQUEST_SENDPIN",
  "REQUEST_RELOGIN",
  "SUCCESS_RELOGIN",
  // set fetch timer
  "SET_FETCH_TIMER",
  // fail request
  "FAIL",
  //set replies
  "SET_REPLIES",
  //update profile
  "REQUEST_UPDATE_PROFILE",
  //reset error
  "RESET_ERROR",
  //update account with msg callback
  "UPDATE_ACCOUNT",
]);

export const SEQUENCE = createActionTypes("SEQUENCE", [
  // add/update sequence
  "REQUEST_UPDATE",
  "SUCCESS_UPDATE",
  "REQUEST_TESTEMAIL",
  "SUCCESS_TESTEMAIL",
  // get sequences
  "REQUEST_GET",
  "SUCCESS_GET",
  // delete sequence
  "REQUEST_DELETE",
  "SUCCESS_DELETE",
  // fail request
  "FAIL",
  // set fetch to false
  "NEED_FETCH",
  "SHOW_SUCCESS_MESSAGE",
  "HIDE_SUCCESS_MESSAGE",
  //reset sequence
  "RESET_SEQUENCE",
]);

export const INTEGRATION = createActionTypes("INTEGRATION", [
  // add/update sequence
  "REQUEST_UPDATE",
  "SUCCESS_UPDATE",
  "REQUEST_UPDATE_ACCOUNT",
  "SUCCESS_UPDATE_ACCOUNT",
  // get sequences
  "REQUEST_GET",
  "REQUEST_GET_ACCOUNT",
  "SUCCESS_GET",
  "SUCCESS_GET_ACCOUNT",
  // delete sequence
  "REQUEST_DELETE",
  "SUCCESS_DELETE",
  "REQUEST_DELETE_ACCOUNT",
  "SUCCESS_DELETE_ACCOUNT",
  // fail request
  "FAIL",
  // set fetch to false
  "NEED_FETCH",
  "SHOW_SUCCESS_MESSAGE",
  "HIDE_SUCCESS_MESSAGE",
  // reset integrations
  "RESET_INTEGRATION",
]);

export const PROSPECT = createActionTypes("PROSPECT", [
  // add black link
  "BLACK_LINK_REQUEST_ADD",
  "BLACK_LINK_SUCCESS_ADD",
  // get black link
  "BLACK_LINK_REQUEST_GET",
  "BLACK_LINK_SUCCESS_GET",
  // delete black link
  "BLACK_LINK_REQUEST_DELETE",
  "BLACK_LINK_SUCCESS_DELETE",
  // add black link
  "BLACK_COMPANY_REQUEST_ADD",
  "BLACK_COMPANY_SUCCESS_ADD",
  // get black link
  "BLACK_COMPANY_REQUEST_GET",
  "BLACK_COMPANY_SUCCESS_GET",
  // delete black link
  "BLACK_COMPANY_REQUEST_DELETE",
  "BLACK_COMPANY_SUCCESS_DELETE",
  // fail request
  "FAIL",
]);

export const CAMPAIGN = createActionTypes("CAMPAIGN", [
  // add/update sequence
  "REQUEST_CREATE",
  "SUCCESS_CREATE",
  // get campaigns
  "REQUEST_GET",
  "SUCCESS_GET",
  "REQUEST_GET_FULL",
  "SUCCESS_GET_FULL",
  // get campaign by id
  "REQUEST_GET_BY_ID",
  "SUCCESS_GET_BY_ID",
  // delete campaign
  "REQUEST_DELETE",
  "SUCCESS_DELETE",
  // start campaign
  "REQUEST_START",
  "SUCCESS_START",
  // pause campaign
  "REQUEST_PAUSE",
  "SUCCESS_PAUSE",
  // get contact info
  "REQUEST_CONTACT",
  "SUCCESS_CONTACT",
  // exclude target
  "EXCLUDE_TARGET",
  "SUCCESS_EXCLUDE",
  // request update note
  "UPDATE_NOTE",
  "SUCCESS_NOTE",
  // request assign tags
  "REQUEST_ASSIGN_TAGS",
  "SUCCESS_ASSIGN_TAGS",
  // request export google sheet
  "REQUEST_EXPORT",
  "SUCCESS_EXPORT",
  "FAIL_EXPORT",
  // finish loading
  "FINISH_LOADING",
  // fail request
  "FAIL",
  // set fetch to false
  "NEED_FETCH",
  // reset campaign
  "RESET_CAMPAIGN",
  // Get Targets
  "TARGET_REQUEST_GET",
  "TARGET_SUCCESS_GET",
  "UPGRADE_TO_PRO",
  "UPGRADE_TO_PRO_SUCCESS",
]);

export const ADMIN = createActionTypes("ADMIN", [
  "REQUEST_FILELIST",
  "SUCCESS_FILELIST",
  "REQUEST_USERLIST",
  "SUCCESS_USERLIST",
  "REQUEST_PROXYLIST",
  "SUCCESS_PROXYLIST",
  "REQUEST_PROXYUPDATE",
  "SUCCESS_PROXYUPDATE",
  "REQUEST_PROXYSAVE",
  "SUCCESS_PROXYSAVE",
  "REQUEST_USER_INFO",
  "SUCCESS_USER_INFO",
  "FAIL",
]);

export const MESSAGE = createActionTypes("MESSAGE", [
  "REQUEST_MESSAGE_SEND",
  "SUCCESS_MESSAGE_SEND",
  "FAIL_MESSAGE_SEND",
  "CLEAR_MESSAGE",
  "READ_MESSAGE",
  "SUCCESS_READ_MESSAGE",
  "UNREAD_MESSAGE",
  "SUCCESS_UNREAD_MESSAGE",
]);

export const NOTES = createActionTypes("NOTES", [
  "REQUEST_NOTES",
  "GET_NOTES_SUCCESS",
  "GET_NOTES_ERROR",
  "REQUEST_NOTES_UPDATE",
  "NOTES_UPDATE_SUCCESS",
  "NOTES_UPDATE_ERROR",
]);

export const REPLAYS_TEMPLATES = createActionTypes("REPLAYS_TEMPLATES", [
  "REPLAYS_TEMPLATES_GET_REQUEST",
  "REPLAYS_TEMPLATES_GET_SUCCESS",
  "REPLAYS_TEMPLATES_GET_ERROR",
  "REPLAYS_TEMPLATES_CREATE_REQUEST",
  "REPLAYS_TEMPLATES_CREATE_SUCCESS",
  "REPLAYS_TEMPLATES_CREATE_ERROR",
  "REPLAYS_TEMPLATES_UPDATE_REQUEST",
  "REPLAYS_TEMPLATES_UPDATE_SUCCESS",
  "REPLAYS_TEMPLATES_UPDATE_ERROR",
  "REPLAYS_TEMPLATES_DELETE_REQUEST",
  "REPLAYS_TEMPLATES_DELETE_SUCCESS",
  "REPLAYS_TEMPLATES_DELETE_ERROR",
]);

export const TAG = createActionTypes("TAG", [
  "REQUEST_GET_TAG",
  "SUCCESS_GET_TAG",
  "REQUEST_CREATE_TAG",
  "SUCCESS_CREATE_TAG",
  "REQUEST_DELETE_TAG",
  "SUCCESS_DELETE_TAG",
  "FAIL",
  //reset tags
  "RESET_TAG",
]);

export const ERROR = createActionTypes("ERROR", ["SLEEP_MODE", "RESET_ERROR"]);

export const NOTIFY = createActionTypes("NOTIFY", ["API_ERROR", "HIDE_NOTIFY"]);
