import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Button,
  UncontrolledTooltip,
  Modal,
} from "reactstrap";
import ReactTable from "react-table";

import CampaignActionBadge from "./CampaignActionBadge";
import CampaignCustomTagBadge from "./CampaignCustomTagBadge";
import CampaignProspectsMessagingModal from "./ProspectMessagingModal/CampaignProspectsMessagingModal";
import { actionToActivity } from "../../../utils";
import { readMessage } from "../../../actions/messageActions";
import ProspectContactInfoModal from "./ProspectContactInfoModal/prospectContactInfoModal";

const AvatarComponent = (props) => {
  const { targetLink, salesLink, name, imageurl } = props;
  const [imageError, setImageError] = useState(false);
  const imageErrorHandler = (e) => {
    setImageError(true);
  };
  return (
    <div className="img-wrapper">
      <a
        rel="noreferrer noopener"
        href={targetLink || salesLink}
        target="_blank"
      >
        <img
          style={{ height: 40 }}
          alt={name}
          src={
            imageError
              ? require("assets/img/placeholder.jpg")
              : imageurl && imageurl.startsWith("https://media")
                ? imageurl
                : require("assets/img/placeholder.jpg")
          }
          onError={(e) => imageErrorHandler(e)}
        />
      </a>
    </div>
  );
};

const CampaignProspectsCard = (props) => {
  const { campaign, manageProspects, liaccount } = props;
  const [targetToModal, setTargetToModal] = useState();
  const [openMessageModal, setOpenMessageModal] = useState(false);

  const [prospectInfoModel, setProspectInfoModel] = useState(false);
  const [openProspectInfo, setOpenProspectInfo] = useState({});

  const dispatch = useDispatch();

  let prospectsData = [];

  const getAction = (name) => {
    const campData = campaign.data.filter(
      (campData) => campData.name === name
    )[0];

    const actions = [];

    if (
      campaign.repliedData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Replied");
    }
    if (
      campaign.connectionData.findIndex((dat) => dat.targetName === name) !== -1
    ) {
      actions.push("Accepted");
    }

    if (campData) {
      actions.push(
        ...campData.actions
          .filter(Boolean)
          .map(({ name }) => actionToActivity(name))
      );
    }

    return actions.length ? actions : ["None"];
  };
  const setProspectInfoToggle = (data) => {
    let contactInfo = {};
    try {
      contactInfo = JSON.parse(data.contactInfo);
    } catch (error) { }
    setOpenProspectInfo({
      title: data.name,
      ...data,
      ...contactInfo,
    });
    setProspectInfoModel(!prospectInfoModel);
  };

  const getLongestMessagedData = (filtered) => {
    if (
      filtered.length === 2 &&
      ((filtered[0].isSales && !filtered[1].isSales) ||
        (!filtered[0].isSales && filtered[1].isSales))
    )
      return {
        ...filtered[1],
        messages: [...filtered[0].messages, ...filtered[1].messages],
      };
    return filtered.sort((a, b) => b.messages.length - a.messages.length)[0];
  };

  const checkMessageDataAvailable = (name) => {
    const { allMessageData } = liaccount;
    const filteredTargetMessages = allMessageData.filter(
      (item) => `${item.firstName} ${item.lastName}` === name
    );
    return {
      messages:
        filteredTargetMessages.length > 0
          ? [getLongestMessagedData(filteredTargetMessages)]
          : [],
      isUnRead:
        filteredTargetMessages.length > 0 &&
          getLongestMessagedData(filteredTargetMessages)
          ? getLongestMessagedData(filteredTargetMessages).isUnRead
          : false,
    };
  };
  const getContactInfo = (name) => {
    const { connectionData } = liaccount;
    let filtered = connectionData.filter(
      (connect) => `${connect.firstName} ${connect.lastName}` === name
    );
    if (filtered.length > 0) return filtered[0].contactInfo;
    else return null;
  };

  const toggleMessagingModal = (data) => {
    const { targets } = campaign;
    const { headline, name } = data.original;
    let prospect = targets.filter(
      (target) => target.name === name && target.headline === headline
    )[0];
    prospect = {
      ...prospect,
      actions: getAction(name),
      messaged: checkMessageDataAvailable(name).messages,
      contactInfo: getContactInfo(name),
      isUnRead: checkMessageDataAvailable(name).isUnRead,
      browserKey: campaign.browserKey,
      campaignId: campaign.identifier,
    };
    setTargetToModal(prospect);
    setOpenMessageModal(true);
    if (prospect.isUnRead) updateReadStatus(prospect.messaged[0]._id);
  };

  const updateReadStatus = (msgId) => {
    dispatch(readMessage(msgId));
  };

  if (campaign) {
    const { targets } = campaign;
    targets
      .filter((target) => target.name.trim() !== "")
      .map(({ _id, imageurl, name, headline, targetLink, salesLink, tags }) => {
        if (prospectsData.filter((pr) => pr.name === name).length < 1) {
          let tmp = {
            photo: (
              <div className="img-row">
                <AvatarComponent
                  key={name + targetLink}
                  name={name}
                  targetLink={targetLink}
                  salesLink={salesLink}
                  imageurl={imageurl}
                />
              </div>
            ),
            description: (
              <div className="camp-prospects-description">
                <p>
                  <strong>{`${name}`}</strong>
                </p>
                <p>
                  <span className="text-muted">{`${headline}`}</span>
                </p>
              </div>
            ),
            targetLink,
            activity: getAction(name),
            name: name,
            contactInfo: getContactInfo(name),
            headline: headline,
            customTags: tags,
            _id: _id,
          };
          prospectsData.push(tmp);
        }
        return true;
      });
  }

  const ActivityColumnSortMethod = (a, b) => {
    if (a.length < b.length) return 1;
    else if (a.length === b.length) return 0;
    else return -1;
  };

  const toggleMessageModal = () => {
    setOpenMessageModal((prev) => !prev);
  };

  return (
    <React.Fragment>
      {targetToModal && openMessageModal && (
        <Modal
          className="msging-modal"
          isOpen={openMessageModal}
          toggle={toggleMessageModal}
        >
          <CampaignProspectsMessagingModal
            key={targetToModal.name}
            target={targetToModal}
            liaccount={liaccount}
            onContactInfoModelIconClick={setProspectInfoToggle}
            showContactIcon={false}
          />
        </Modal>
      )}
      <ProspectContactInfoModal
        setProspectInfoToggle={setProspectInfoToggle}
        prospectInfoModel={prospectInfoModel}
        openProspectInfo={openProspectInfo}
      />
      <Card className="card-tasks camp-prospect">
        <CardHeader>
          <div className="el-inline">
            <CardTitle tag="h4">Prospects</CardTitle>
            <h5 className="card-category el-inline">
              Prospects from this campaign
            </h5>
            <i
              className="nc-icon nc-bulb-63 text-muted"
              id="tooltip-manage"
            ></i>
            <UncontrolledTooltip delay={0} target="tooltip-manage">
              Click 'Manage' button to review and manage all imported prospects.
            </UncontrolledTooltip>
          </div>
          <Button
            className="pull-right el-inline btn"
            onClick={manageProspects}
          >
            Manage
          </Button>
        </CardHeader>
        <CardBody style={{ paddingTop: 0 }}>
          <div className="table-full-width table-responsive prospects-table">
            <ReactTable
              data={prospectsData}
              columns={[
                {
                  Header: "",
                  accessor: "photo",
                  sortable: false,
                  filterable: false,
                  width: 70,
                },
                {
                  Header: "",
                  accessor: "description",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Activity",
                  accessor: "activity",
                  sortable: true,
                  filterable: false,
                  sortMethod: ActivityColumnSortMethod,
                  width: 200,
                  Cell: (row) => (
                    <div className="activity-badges">
                      {row.value.map((action, index) => (
                        <div key={index} className="el-inline">
                          {<CampaignActionBadge {...{ action }} />}
                        </div>
                      ))}
                      {row.original.customTags.map((tag, index) => (
                        <div key={`custom-tag-${index}`} className="el-inline">
                          <CampaignCustomTagBadge {...{ tag }} />
                        </div>
                      ))}
                    </div>
                  ),
                },
                {
                  Header: "",
                  accessor: "action",
                  sortable: false,
                  filterable: false,
                  width: 100,
                  Cell: (row, a, b) => {
                    return (
                      <div
                        className="chat"
                        style={{
                          fontSize: "1.4rem",
                          color: `${row.row.activity.indexOf("Replied") > -1
                            ? "#6bd098"
                            : "#66615B"
                            }`,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {(row.row.activity.indexOf("Messaged") > -1 ||
                          row.row.activity.indexOf("Replied") > -1) && (
                            <i
                              className="nc-icon nc-chat-33"
                              onClick={() => {
                                toggleMessagingModal(row, a, b);
                              }}
                            />
                          )}
                        <div>
                          <UncontrolledTooltip
                            placement="bottom"
                            delay={0}
                            target={
                              `tooltip-contact-info` + row.row._original._id
                            }
                          >
                            Contact Info
                          </UncontrolledTooltip>
                          <i
                            className="nc-icon nc-globe"
                            id={
                              "tooltip-contact-info" + row.row._original._id
                            }
                            style={{
                              fontSize: 20,
                              marginTop: "5px",
                              color: "black",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setProspectInfoToggle(row.row._original);
                            }}
                          />
                        </div>
                      </div>
                    );
                  },
                },
              ]}
              defaultPageSize={10}
              defaultSorted={[
                {
                  id: "activity",
                },
              ]}
              sortable
              showPaginationTop={false}
              showPaginationBottom={true}
              className=" -highlight hidden"
            />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CampaignProspectsCard;
