import { takeLatest, all, put } from "redux-saga/effects";
import { types, integrationActions, notifyActions } from "../actions";
import { push } from "connected-react-router";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";


const { INTEGRATION } = types;
const {
  successGetintegrations,
  successGetintegrationsAccount,
  successDeleteIntegration,
  successDeleteIntegrationAccount,
  requestGetintegrations,
  requestGetintegrationsAccount,
  fail,
} = integrationActions;
const { apiError } = notifyActions;
const baseURL = backendUrl + "/integrations/global";

// add/update sequence request saga
function* requestUpdateIntegration(action) {
  const { integration, account } = action;
  try {
    const caller = axiosInstance().post;
    yield caller(baseURL, {
      liEmail: integration.liEmail,
      type: integration.type,
      campaigns: integration.campaigns,
      actions: integration.actions,
      webhook_action_post_body: integration.postBody,
      webhook_url: integration.callbackUrl,
      userId: integration.userId ? integration.userId : "",
      accounts: integration.accounts ? integration.accounts : [],
    });
    if (integration.type === "campaign") {
      yield put(requestGetintegrations(integration.liEmail));
      yield put(
        push({
          pathname: `/admin/${account}/integration`,
          state: { state: "simple" },
        })
      );
    } else {
      yield put(requestGetintegrationsAccount());
      yield put(
        push({
          pathname: `/admin/${account}/integration`,
          state: { state: "global" },
        })
      );
    }
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// delete sequence request saga
function* requestDeleteIntegration(action) {
  const { _id, account } = action;
  // console.log("delete sequence", _id);
  try {
    yield axiosInstance().delete(`${backendUrl}/integrations/${_id}`);

    // console.log("success delete sequence", resp.data);
    if (account) {
      yield put(successDeleteIntegration(_id));
    } else {
      yield put(successDeleteIntegrationAccount(_id));
    }
    // yield put(push(`/admin/${account}/sequences`));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// get sequences request saga
function* requestGetIntegration(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(`${baseURL}?liEmail=${liEmail}&type=campaign`);
    // console.log("success get sequences", resp.data.docs);
    const integrations = resp.data;
    yield put(successGetintegrations(integrations));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
function* requestGetIntegrationAccount(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(`${baseURL}?liEmail=${liEmail}&type=account`);
    const integrations = resp.data;
    yield put(successGetintegrationsAccount(integrations));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* integrationSaga() {
  yield all([
    takeLatest(INTEGRATION.REQUEST_UPDATE, requestUpdateIntegration),
    takeLatest(INTEGRATION.REQUEST_GET, requestGetIntegration),
    takeLatest(INTEGRATION.REQUEST_GET_ACCOUNT, requestGetIntegrationAccount),
    takeLatest(INTEGRATION.REQUEST_DELETE, requestDeleteIntegration),
  ]);
}

export default integrationSaga;
