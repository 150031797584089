import React, { useMemo, Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Row, Col, Modal, ModalBody, ModalHeader, Button } from "reactstrap";

const StyledAccountSubscription = styled.div`
  display: flex;

  .account-subscription {
    &__photo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__btn {
        cursor: pointer;
      }
    }
    &__change-plan {
      &__btn {
      }
    }
  }
`;

const AccountSubscription = (props) => {
  const {
    liaccount,
    showSubscriptionModal,
    collapsed,
    selectSeat,
    submitSubscribe,
  } = props;

  const [showPlans, setShowPlans] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(collapsed);
  const [currentPlan, setCurrentPlan] = useState(liaccount.planType);
  const [confirmUpgrade, setConfirmUpgrade] = useState(false);
  const [upgradePlan, setUpgradePlan] = useState("");

  useEffect(() => {
    setSelectedSeat(collapsed);
  });

  const billDate = liaccount.billDate
    ? moment(liaccount.billDate * 1000)
    : moment();
  const currentPlanTxt =
    liaccount.planType === "FREE_PLAN"
      ? "Free Plan"
      : liaccount.planType === "PROFESSIONAL_PLAN"
      ? "$9/mth until canceled ~"
      : "$49/mth until canceled ~";
  const isExpired = useMemo(() => {
    const currentDate = moment();
    if (billDate > currentDate) {
      return false;
    } else return true;
  }, [billDate]);
  // console.log(liaccount.isSubscribed)

  const changePlan = () => {
    setShowPlans(!showPlans);
    selectSeat();
  };

  const subscribePlan = (planName) => {
    setConfirmUpgrade(true);
    setUpgradePlan(planName);
  };

  return (
    <Fragment>
      <Modal isOpen={confirmUpgrade}>
        <ModalHeader>Are you sure?</ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="btn btn-primary"
              onClick={() => {
                setConfirmUpgrade(false);
                submitSubscribe(upgradePlan, liaccount._id);
              }}
            >
              Upgrade
            </Button>
            <Button
              className="btn btn-outline-primary"
              variant="primary"
              onClick={() => {
                setConfirmUpgrade(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
      {liaccount && (
        <StyledAccountSubscription className="mb-3">
          {liaccount.profileImg !== "UNKNOWN" ? (
            <img
              src={liaccount.profileImg}
              className="account-subscription__photo"
              alt="profile"
            />
          ) : (
            <img
              className="account-subscription__photo"
              alt="missing"
              src="https://perfectprospect.io/assets/img/placeholder.jpg"
            />
          )}
          <div className="account-subscription__details ml-4">
            <p className="m-0">{liaccount.name}</p>
            {!liaccount.isSubscribed ? (
              <span className="text-danger"> Subscription is canceled</span>
            ) : (
              ""
            )}
            {isExpired ? (
              <span className="text-danger">
                Expired at {billDate.format("ll")}
              </span>
            ) : liaccount.isSubscribed ? (
              currentPlanTxt
            ) : (
              ""
            )}{" "}
            {!isExpired ? "Active until: " + billDate.format("ll") : ""}
            <div className="d-flex justify-content-between align-items-center">
              {!liaccount.isSubscribed ? (
                <div
                  onClick={() =>
                    showSubscriptionModal(liaccount._id, true, billDate)
                  }
                  className="account-subscription__details__btn text-primary m-0"
                >
                  {" "}
                  Reactivate Subscription{" "}
                </div>
              ) : (
                <div
                  onClick={() =>
                    showSubscriptionModal(liaccount._id, isExpired, billDate)
                  }
                  className="account-subscription__details__btn text-danger m-0"
                >
                  {" "}
                  Cancel Subscription
                </div>
              )}
              {liaccount.isSubscribed && (
                <button
                  onClick={() => changePlan(liaccount._id, isExpired, billDate)}
                  className="btn btn-primary btn-sm ml-5"
                >
                  {" "}
                  Change Plan
                </button>
              )}
            </div>
          </div>
        </StyledAccountSubscription>
      )}
      {showPlans && selectedSeat === liaccount._id && (
        <Fragment>
          <Row className="ml-3 mr-3 plans-page">
            {currentPlan === "FREE_PLAN" && (
              <Col md="6">
                <div
                  className="card-pricing card"
                  style={{
                    backgroundColor:
                      currentPlan === "FREE_PLAN" ? "antiquewhite" : "",
                  }}
                >
                  <div className="card-header">
                    <h6 className="card-category">Free</h6>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title">$0</h3>
                    <ul>
                      <li>5 Invites & Messages per day</li>
                      <li>Smart Inbox Preview</li>
                      <li>Drip Campaigns</li>
                      <li style={{ textDecoration: "line-through" }}>
                        Automatic Followups
                      </li>
                      <li style={{ textDecoration: "line-through" }}>
                        Export Leads
                      </li>
                      <li style={{ textDecoration: "line-through" }}>
                        Integrate with CRM/Zapier
                      </li>
                      <li style={{ textDecoration: "line-through" }}>
                        Blacklist
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer">
                    {currentPlan === "FREE_PLAN" ? (
                      <button
                        className="btn-round btn btn-primary text-white"
                        disabled
                      >
                        Subscribed
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            )}
            {/* <Col md="6">
              <div
                className="card-pricing card"
                style={{
                  backgroundColor:
                    currentPlan === "PROFESSIONAL_PLAN" ? "antiquewhite" : "",
                }}
              >
                <div className="card-header">
                  <h6 className="card-category">Personal</h6>
                </div>
                <div className="card-body">
                  <h3 className="card-title">$9/mth</h3>
                  <p>
                    <small>per seat</small>
                  </p>
                  <ul>
                    <li>30 Connection Requests per day</li>
                    <li>Smart Inbox & Reply Detection</li>
                    <li>Automatic Followup</li>
                    <li style={{ textDecoration: "line-through" }}>
                      Drip Campaigns
                    </li>
                    <li>Export Leads CSV</li>
                    <li style={{ textDecoration: "line-through" }}>
                      Integrate with CRM/Zapier
                    </li>
                    <li>
                      See{" "}
                      <a target="_blank" target="_blank" href="https://perfectprospect.io/pricing.html">
                        full plan details.
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  {currentPlan === "PROFESSIONAL_PLAN" ? (
                    <button
                      className="btn-round btn btn-primary text-white"
                      disabled
                    >
                      Subscribed
                    </button>
                  ) : (
                    <button
                      className="btn-round btn btn-primary text-white"
                      onClick={() => {
                        subscribePlan("PROFESSIONAL_PLAN");
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
              </div> */}
            {/* </Col> */}
            <Col md="6">
              <div
                className="card-pricing card"
                style={{
                  backgroundColor:
                    currentPlan === "MAXGROWTH_PLAN" ? "antiquewhite" : "",
                }}
              >
                <div className="card-header">
                  <h6 className="card-category">Maximum Growth</h6>
                </div>
                <div className="card-body">
                  <h3 className="card-title">$49/mth</h3>
                  <p>
                    <small>per seat</small>
                  </p>
                  <ul>
                    <li>100 Connection Requests per day</li>
                    <li>100 Messages per day</li>
                    <li>All Pro Features +</li>
                    <li>Download/Export Leads Data (email/phone, etc)</li>
                    <li>Integrate your CRM/Zapier</li>
                    <li>
                      See{" "}
                      <a
                        target="_blank"
                        href="https://perfectprospect.io/pricing.html"
                      >
                        full plan details.
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-footer">
                  {currentPlan === "MAXGROWTH_PLAN" ? (
                    <button
                      className="btn-round btn btn-primary text-white"
                      disabled
                    >
                      Subscribed
                    </button>
                  ) : (
                    <button
                      className="btn-round btn btn-primary text-white"
                      onClick={() => {
                        subscribePlan("MAXGROWTH_PLAN");
                      }}
                    >
                      Subscribe
                    </button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AccountSubscription;
