import { takeLatest, all, put } from "redux-saga/effects";
import { types, prospectActions, notifyActions } from "../actions";
import { push } from "connected-react-router";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";


const { PROSPECT } = types;
const {
  successGetBlackLinks,
  successDeleteBlackLink,
  successAddBlackLinks,
  successGetBlackCompanies,
  successDeleteBlackCompany,
  successAddBlackCompanies,
  fail,
} = prospectActions;
const { apiError } = notifyActions;
const baseURL = backendUrl + "/blacklist";
const baseCompanyURL = backendUrl + "/blackCompanylist";


function* requestAddBlackLinks(action) {
  const { blackLinks, account } = action;

  try {
    const data = {
      liEmail: blackLinks.liEmail,
      blockLink: blackLinks.links,
    };

    const resp = yield axiosInstance().post(baseURL, data);

    yield put(
      successAddBlackLinks({
        ...resp.data,
      })
    );

    yield put(push(`/admin/${account}/blacklist`));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// delete blackLinks request saga
function* requestDeleteBlackLink(action) {
  const { _id, account } = action;

  try {
    yield axiosInstance().delete(`${baseURL}/${_id}`);

    yield put(successDeleteBlackLink(_id));
    yield put(push(`/admin/${account}/blacklist`));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// get blackLinks request saga
function* requestGetBlackLinks(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(`${baseURL}?liEmail=${liEmail}`);
    const blackLinks = resp.data.docs.map((blackLink) => ({
      ...blackLink,
      identifier: "black-link-" + blackLink._id,
    }));

    yield put(successGetBlackLinks(blackLinks));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestAddBlackCompanies(action) {

  console.log('saga requestAddBlackCompanies', action)

  const { blackCompanies, account } = action;

  try {
    const data = {
      liEmail: blackCompanies
        .liEmail,
      blockComapny: blackCompanies
        .company,
    };
    const resp = yield axiosInstance().post(baseCompanyURL, data);

    yield put(
      successAddBlackCompanies({
        ...resp.data,
      })
    );

    // yield put(push(`/admin/${account}/blacklist`));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// delete blackLinks request saga
function* requestDeleteBlackCompany(action) {
  const { _id, account } = action;

  try {
    yield axiosInstance().delete(`${baseCompanyURL}/${_id}`);
    yield put(successDeleteBlackCompany(_id));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// get blackLinks request saga
function* requestGetBlackCompanies(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(`${baseCompanyURL}?liEmail=${liEmail}`);
    const blackLinks = resp.data.docs.map((blackLink) => ({
      ...blackLink,
      identifier: "black-link-" + blackLink._id,
    }));

    yield put(successGetBlackCompanies(blackLinks));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}


function* prospectSaga() {
  yield all([
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_ADD, requestAddBlackLinks),
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_GET, requestGetBlackLinks),
    takeLatest(PROSPECT.BLACK_LINK_REQUEST_DELETE, requestDeleteBlackLink),
    takeLatest(PROSPECT.BLACK_COMPANY_REQUEST_ADD, requestAddBlackCompanies),
    takeLatest(PROSPECT.BLACK_COMPANY_REQUEST_GET, requestGetBlackCompanies),
    takeLatest(PROSPECT.BLACK_COMPANY_REQUEST_DELETE, requestDeleteBlackCompany),
  ]);
}

export default prospectSaga;
