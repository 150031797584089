/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, Button
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { validator } from "../../utils";
import { userActions, planActions } from "../../actions";
const { requestRegister } = userActions;
const { requestGetPlans } = planActions;
class Register extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: -1,
      password: -1,
      agree: true,
      error: "",
      isShowPromoInput: false,
      promoCode: "",
      plan: '',
      name: ''
    };
  }
  componentDidMount() {
    if (!this.props.plans.length) {
      this.props.requestGetPlans();
    }
    if (this.props.match.params.planId === "professional-plan") {
      this.setState({
        plan: "professional_plan"
      });
    } else if (this.props.match.params.planId === "PROFESSIONAL_PLAN") {
      this.setState({
        plan: "professional_plan"
      });
    } else if (this.props.match.params.planId === "personal-plan") {
      this.setState({
        plan: "personal_plan"
      });
    } else if (this.props.match.params.planId === "free-plan") {
      this.setState({
        plan: "free_plan"
      });
    } else if (this.props.match.params.planId === "basic-plan") {
      this.setState({
        plan: "basic_plan"
      });
    }
    else {
      this.setState({
        plan: this.props.match.params.planId
      });
    }
    localStorage.setItem('last-register-plan', this.props.match.params.planId);

    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }
  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }
  createSubscription = (token) => {
    const { email, password, name } = this.state;
    this.props.requestRegister(
      name,
      email,
      password,
    );
  };
  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  setName = (e) => {
    this.setState({ name: e.target.value });
  };
  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };
  toggleAgree = () => {
    this.setState({ agree: !this.state.agree });
  };
  render() {
    const {
      plans,
      loading,
    } = this.props;
    const { email, password, error, agree, name } = this.state;

    return (
      <LoadingOverlay active={loading} spinner text="Registering Account ...">
        <div className="register-page">
          <Container>
            <Row>
              <Col
                className="mr-auto ml-auto"
                lg="5"
                md="5"
                style={{ zIndex: 100 }}
              >
                <div action="" className="form" method="">
                  <Card className="card-signup text-center">
                    <CardHeader>
                      <CardTitle tag="h4" style={{ fontWeight: "bold" }}>Sign Up</CardTitle>
                      <p style={{ textAlign: "center", fontWeight: 400, fontFamily: 'Lato', fontSize: '16px' }}>
                        The multifunctional Linkedin automation tool designed to help your sales team improve Linkedin prospecting and close more deals
                      </p>
                    </CardHeader>
                    <CardBody>
                      {error && (
                        <InputGroup>
                          <Input hidden invalid />
                          <FormFeedback>{error}</FormFeedback>
                        </InputGroup>
                      )}
                      <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Enter your full name</p>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="iconwrap">
                            <i className="nc-icon nc-badge" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="fullname"
                          placeholder="Your Full Name"
                          type="text"
                          invalid={validator.checkRequiredValidation(name)}
                          onChange={this.setName}
                          onFocus={this.setName}
                        />
                        <FormFeedback>
                          {name === "" ? `Name required` : `Full Name is required`}
                        </FormFeedback>
                      </InputGroup>
                      <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Email Address*</p>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="iconwrap">
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="fullname"

                          placeholder="Email..."
                          type="email"
                          invalid={validator.checkEmailValidation(email)}
                          onChange={this.setEmail}
                          onFocus={this.setEmail}
                        />
                        <FormFeedback>
                          {email === "" ? `Email required` : `Invalid Email`}
                        </FormFeedback>
                      </InputGroup>
                      <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Create password*</p>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="iconwrap">
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="fullname"
                          placeholder="Password"
                          type="password"
                          autoComplete="off"
                          onChange={this.setPassword}
                          onFocus={this.setPassword}
                          invalid={
                            validator.checkRequiredValidation(password) ||
                            validator.checkPasswordValidation(password)
                          }
                        />
                        <FormFeedback>
                          {password === ""
                            ? `Password required`
                            : `Needs to be at least 5 letters`}
                        </FormFeedback>
                      </InputGroup>
                      <FormGroup check className="text-left">
                        <Label check>
                          <Input
                            checked={agree}
                            type="checkbox"
                            onChange={this.toggleAgree}
                          />
                          <span className="form-check-sign" />I agree to the{" "}
                          <a
                            href="https://perfectprospect.io/terms.html"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            terms and conditions
                          </a>
                          .
                        </Label>
                      </FormGroup>
                      <InputGroup>
                        <Input hidden invalid={!agree} />
                        <FormFeedback>Please check the box</FormFeedback>
                      </InputGroup>
                      <Button block className="btn-round" color="success" onClick={this.createSubscription} disabled={validator.checkRequiredValidation(name) || validator.checkRequiredValidation(password) ||
                        validator.checkPasswordValidation(password) || validator.checkEmailValidation(email)}
                      >
                        Sign Up
                      </Button>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${require("assets/img/bg/david-marcu.jpg")})`,
            }}
          />
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
  error: state.authentication.error,
  loading: state.authentication.loading,
});

const mapDispatchToProps = (dispatch) => ({
  requestRegister: (name, email, password,) =>
    dispatch(requestRegister(name, email, password)),
  requestGetPlans: () => dispatch(requestGetPlans()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps)(Register));
