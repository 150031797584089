/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Container,
  Row,
  InputGroup,
  FormFeedback,
  Col,
  Input
} from "reactstrap";
import { userActions } from "../../actions";
import { validator } from "../../utils";
const { requestVerify } = userActions;

class Verify extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      verifyCode: '',
      error: props.error,
      errorMessage: props.errorMessage
    };
  }
  componentDidMount() {
    if (!this.props.verifyToken) {
      this.props.history.push("/auth/login");
    }
    document.body.classList.toggle("register-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("register-page");
  }
  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { verifyCode } = this.state;

    this.props.requestVerify(this.props.verifyToken, verifyCode);
    // window.alert("Email was resent");
  };
  setCode = (e) => {
    this.setState({ verifyCode: e.target.value });
  };
  render() {
    const { verifyCode } = this.state;
    return (
      <div className="register-page">
        <Container>
          <Row>
            <Col lg="2" md="0" />
            <Col lg="7" md="10">
              <Form
                action=""
                className="form"
                method=""
                onSubmit={this.handleSubmit}
              >
                <Card className="card-signup text-left" style={{ padding: "0 30px" }}>
                  <CardHeader>
                    <CardTitle tag="h2">Validate your account</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <p style={{ textAlign: 'center', fontWeight: 400, fontFamily: 'Lato' }}>
                      In order to validate your account, please enter
                      <br />
                      the code sent to your email.
                    </p>
                    {this.props.errorMessage && (
                      <InputGroup>
                        <Input hidden invalid />
                        <FormFeedback>{this.props.errorMessage}</FormFeedback>
                      </InputGroup>
                    )}
                    <p style={{ textAlign: "left", fontWeight: 400, marginBottom: '2px' }}>Confirmation code</p>
                    <Input
                      placeholder="6 digits code"
                      type="text"
                      invalid={validator.checkVerifyCodeValidation(verifyCode)}
                      onChange={this.setCode}
                      onFocus={this.setCode}
                    />
                  </CardBody>
                  <CardFooter>
                    <Button block className="btn-round" color="success" onClick={this.createSubscription} disabled={validator.checkVerifyCodeValidation(verifyCode)}
                    >
                      Save & Continue
                    </Button>

                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/joshua-earles.jpg")})`,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  verifyToken: state.authentication.verifyToken,
  error: state.authentication.error,
  errorMessage: state.authentication.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestVerify: (verifyToken, verifyCode) => dispatch(requestVerify(verifyToken, verifyCode)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(Verify))
);
