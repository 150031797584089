import React from "react";
import { Badge } from "reactstrap";

const color = (action, filter, isFilter = false) => {
  const actionToColor = {
    Replied: "success",
    Accepted: "info",
    Requested: "dull-orange",
    Messaged: "warning",
    Followed: "magenta",
    Visited: "danger",
    Emailed: "primary",
    None: "grey",
    Skipped: "info",
    All: "-custom",
    INMAIL: "primary",
  };

  return isFilter
    ? filter && filter.value && filter.value === action
      ? actionToColor[action]
      : (!filter || !filter.value) && action === "All"
      ? actionToColor[action]
      : "grey"
    : actionToColor[action]
    ? actionToColor[action]
    : "-custom";
};

const CampaignActionBadge = ({
  action,
  onChange,
  filter,
  isFilter = false,
}) => {
  return (
    <Badge
      style={isFilter ? { cursor: "pointer" } : {}}
      color={color(action, filter, isFilter)}
      pill
      onClick={(e) => isFilter && onChange(action)}
    >
      {action}
    </Badge>
  );
};

export default CampaignActionBadge;
