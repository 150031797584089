import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AuthLayout from "./layouts/Auth";
import AdminLayout from "./layouts/Admin";
import PAdminLayout from "./layouts/PPadmin";
import Maintain from "views/pages/MaintainPage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const maintainMode = process.env.REACT_APP_MAINTAIN_MODE === "true";

const App = (props) => {
  useEffect(() => {
    const checkSignIn = () => {
      const { token, history, location } = props;
      const mainRouter = location.pathname.split("/")[1];
      if (token) {
        if (!props.user) {
          props.requestGetUser();
        }
        if (mainRouter === "auth") {
          if (props.user && props.user.role === "admin")
            history.push("/ppadmin");
          else history.push("/admin");
        }
      } else if (mainRouter === "admin" || mainRouter === "ppadmin") {
        history.push("/auth/login");
      }
    };
    if (!maintainMode) checkSignIn();
  });
  return (
    <Elements stripe={stripePromise}>
      {/* <Toast /> */}
      <Switch>
        {maintainMode && (
          <Route path="/maintain" render={(props) => <Maintain {...props} />} />
        )}
        {!maintainMode && (
          <Route
            path="/ppadmin"
            render={(props) => <PAdminLayout {...props} />}
          />
        )}
        {!maintainMode && (
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        )}
        {!maintainMode && (
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        )}
        <Redirect to={maintainMode ? "/maintain" : "/admin"} />
      </Switch>
    </Elements>
  );
};

export default App;
