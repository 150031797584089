import React from "react";
import { Col, Card, CardBody, Button } from "reactstrap";
import ReactTable from "react-table";
import { useState } from "react";
import { useEffect } from "react";
import AddGlobalintegration from "./AddGlobalintegration";
import { compose } from "recompose";
import { connect } from "react-redux";
import { integrationActions } from "../../../actions";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";

const {
  requestAddIntegration,
  hideSuccessMessage,
  requestGetintegrationsAccount,
  requestDeleteIntegration,
} = integrationActions;

const GlobalIntegrationPage = (props) => {
  const [addForm, setAddForm] = useState(false);
  const [alert, setAlert] = useState(null);
  const [title, setTitle] = useState("Global integrations");
  const { account } = props.match.params;

  const [planType, setPlanType] = useState("");
  useEffect(() => {
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);
    setPlanType(privacy.PLAN);
    if (props.add) {
      setAddForm(true);
      setTitle("Add Global Webhooks");
    }
    props.requestGetintegrationsFunc(props.liaccount.email);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const renderData = (integrationsAccount) => {
    const rows = integrationsAccount
      .filter((item) => item.liEmail === props.liaccount.email)
      .map((item) => {
        return {
          ...item,
          accountName: props.liaccount.name,
          more: (
            <Button
              color="danger"
              size="md"
              className="btn-icon btn-default btn-link remove"
              onClick={(e) => warningWithConfirmMessage(e, item._id)}
            >
              <i className="fa fa-times" />
            </Button>
          ),
          createdAt: moment(item.createdAt).format("lll"),
        };
      });
    return rows.filter((row) => row !== null);
  };

  const createSIntegration = () => {
    if (planType === "FREE_PLAN" || planType === "PROFESSIONAL_PLAN") {
      return props.setAllowIntegration();
    }
    props.history.push(`/admin/${account}/integration/add/global`);
  };

  const deleteIntegration = (identifier) => {
    const { integrations } = props;
    const integrationObj = integrations.integrationsAccount.filter(
      (item) => item._id === identifier
    )[0];
    props.requestDeleteIntegration(integrationObj._id, null);
    hideAlert();
  };

  const warningWithConfirmMessage = (e, identifier) => {
    e.preventDefault();
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => deleteIntegration(identifier)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        You will not be able to recover this sequence!
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div className="content campaignlist" key={1}>
      {addForm && (
        <AddGlobalintegration history={props.history} account={account} />
      )}
      {!addForm && (
        <Col md="12" className="ml-auto mr-auto">
          <h3 className="el-inline">
            {title} with
            <img
              width="110"
              alt="logo"
              style={{ paddingBottom: "6px" }}
              src="https://perfectprospect.io/assets/img/zapier-logo.png"
            />
            {!addForm && (
              <Button
                className="add-btn el-inline btn-primary btn-icon btn-round"
                onClick={createSIntegration}
              >
                <i className="nc-icon nc-simple-add" style={{ width: 22 }} />
              </Button>
            )}
          </h3>
          <p>
            Export your data to your CRM and 1500+ other apps via Zapier. Using
            Global you can export at the account level.
            <br />* Any new campaigns created under one account will be zapped.
          </p>
          <p>
            <a
              href="https://www.youtube.com/watch?v=BO2La5Qura4"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="nc-icon nc-bulb-63" /> Watch the step-by-step
              tutorial here.
            </a>
          </p>
          {alert}

          <Card className="campaign-builder-card">
            <CardBody>
              <ReactTable
                style={{
                  width: "100%",
                }}
                data={renderData(
                  props.integrations.integrationsAccount
                    ? props.integrations.integrationsAccount
                    : []
                )}
                resizable={true}
                columns={[
                  {
                    Header: "Account Name",
                    accessor: "accountName",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Webhook Action",
                    accessor: "webhook_action_name",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Webhook url",
                    accessor: "webhook_url",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "Date",
                    accessor: "createdAt",
                    sortable: false,
                    filterable: false,
                  },
                  {
                    Header: "",
                    accessor: "more",
                    sortable: false,
                    filterable: false,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom
                className=" -highlight sequence-manager-table"
              />
            </CardBody>
          </Card>
        </Col>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  liaccounts: state.liaccounts.liaccounts,
  sequences: state.sequences.sequences,
  campaigns: state.campaigns.campaigns,
  loading: state.integrations.loading,
  integrations: state.integrations,
  successMessage: state.sequences.successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestHideSuccess: () => dispatch(hideSuccessMessage()),
  requestAddIntegrationDesp: (data, liEmail) =>
    dispatch(requestAddIntegration(data, liEmail)),
  requestGetintegrationsFunc: (liEmail) => {
    return dispatch(requestGetintegrationsAccount(liEmail));
  },
  requestDeleteIntegration: (_id, account) =>
    dispatch(requestDeleteIntegration(_id, account)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(GlobalIntegrationPage)
);
