import { types } from "../actions";
import {
  setLocalStorageData,
  getLocalStorageData,
} from "../utils/localStorageService";

const { LIACCOUNT: ACTION_HEADER } = types;

const initialState = {
  liaccounts: [],
  liaccount: getLocalStorageData("liaccount"),
  loading: false,
  error: "",
  liFetched: false,
  fetchTimer: null,
  reLoginUrl: "",
  replies: [],
  headEdit: {
    loading: false,
    error: "",
  },
  aboutEdit: {
    loading: false,
    error: "",
  },
};

function liaccountReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_ADD: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case ACTION_HEADER.SUCCESS_ADD: {
      const liaccounts = state.liaccounts.slice(0);
      const { imageurl: profileImg, profileLink, _id } = action.account;
      const index = liaccounts.findIndex((acc) => acc._id === _id);
      if (index > -1) {
        liaccounts[index].reLoginRequired = false;
        liaccounts[index].isBanned = false;
      } else
        liaccounts.push({
          ...action.account,
          profileImg,
          identifier: profileLink.split("/").reverse()[1],
        });
      return { ...state, loading: false, liaccounts };
    }

    case ACTION_HEADER.REQUEST_HEAD_EDIT: {
      return {
        ...state,
        headEdit: {
          ...state.headEdit,
          loading: true,
          error: "",
        },
      };
    }
    case ACTION_HEADER.SUCCESS_HEAD_EDIT: {
      return {
        ...state,
        headEdit: {
          ...state.headEdit,
          loading: false,
        },
      };
    }
    case ACTION_HEADER.FAIL_HEAD_EDIT: {
      return {
        ...state,
        headEdit: {
          ...state.headEdit,
          loading: false,
          error: action.error,
        },
      };
    }

    case ACTION_HEADER.REQUEST_ABOUT_EDIT: {
      return {
        ...state,
        aboutEdit: {
          ...state.aboutEdit,
          loading: true,
          error: "",
        },
      };
    }
    case ACTION_HEADER.SUCCESS_ABOUT_EDIT: {
      return {
        ...state,
        aboutEdit: {
          ...state.aboutEdit,
          loading: false,
        },
      };
    }
    case ACTION_HEADER.FAIL_ABOUT_EDIT: {
      return {
        ...state,
        aboutEdit: {
          ...state.aboutEdit,
          loading: false,
          error: action.error,
        },
      };
    }

    case ACTION_HEADER.SET_REPLIES:
      const replies = Array.from(action.replies || []);
      return {
        ...state,
        replies,
      };
    case ACTION_HEADER.REQUEST_GET:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.SUCCESS_GET:
      return {
        ...state,
        loading: false,
        liaccounts: action.accounts,
        liFetched: true,
      };
    case ACTION_HEADER.REQUEST_GET_BY_ID: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case ACTION_HEADER.SUCCESS_GET_BY_ID:
      setLocalStorageData("liaccount", action.account);
      return {
        ...state,
        loading: false,
        liaccount: action.account,
        liFetched: true,
      };
    case ACTION_HEADER.REQUEST_GET_MESSAGES_BY_ID: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }

    case ACTION_HEADER.SUCCESS_GET_MESSAGES_BY_ID:
      return {
        ...state,
        loading: false,
        liaccount: {
          ...state.liaccount,
          allMessageData: action.data.allMessageData,
        },
        liFetched: true,
      };

    case ACTION_HEADER.REQUEST_DELETE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_DELETE: {
      const liaccounts = state.liaccounts.slice(0);

      const { _id } = action;
      const index = liaccounts.findIndex((acc) => acc._id === _id);
      if (index !== -1) {
        liaccounts.splice(index, 1);
      }

      return { ...state, loading: false, liaccounts };
    }

    case ACTION_HEADER.REQUEST_SETTINGS:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_SETTINGS: {
      const { settings } = action;
      return {
        ...state,
        loading: false,
        liaccount: { ...state.liaccount, settings: settings },
      };
    }

    case ACTION_HEADER.REQUEST_EMAIL_CRED_SAVE:
      return { ...state, loading: true, error: "" };

    case ACTION_HEADER.SUCCESS_EMAIL_CRED_SAVE: {
      const { emailData } = action;
      return {
        ...state,
        loading: false,
        liaccount: { ...state.liaccount, emailData: emailData },
      };
    }
    case ACTION_HEADER.REQUEST_SENDPIN:
      return { ...state, loading: true };

    case ACTION_HEADER.REQUEST_RELOGIN: {
      return { ...state, loading: true, error: "" };
    }

    case ACTION_HEADER.SUCCESS_RELOGIN: {
      const liaccounts = state.liaccounts.slice(0);
      const { _id } = action.account;
      const index = liaccounts.findIndex((acc) => acc._id === _id);
      if (index > -1) {
        liaccounts[index].reLoginRequired = action.account.reLoginRequired;
        liaccounts[index].isBanned = action.account.isBanned;
      }
      return { ...state, loading: false, liaccounts };
    }

    case ACTION_HEADER.SET_FETCH_TIMER:
      return { ...state, fetchTimer: action.timer };

    case ACTION_HEADER.FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
        reLoginUrl: action.args ? action.args.reLoginUrl : undefined,
      };
    case ACTION_HEADER.REQUEST_UPDATE_PROFILE:
      return { ...state };
    case ACTION_HEADER.RESET_ERROR:
      return { ...state, error: "" };

    case ACTION_HEADER.UPDATE_ACCOUNT:
      // const { allMessageData } = state.liaccount;
      // const updated = allMessageData.filter(
      //   (msgData) => msgData._id !== action.data._id
      // );
      const liaccount = {
        ...state.liaccount,
        // allMessageData: [...updated, action.data],
      };
      liaccount.allMessageData = null;
      setLocalStorageData("liaccount", liaccount);
      return { ...state };

    default: {
      return state;
    }
  }
}

export default liaccountReducer;
