import React, { useState } from "react";
import { Row, Col, Modal, ModalBody, Input, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../actions";

const { requestProxyUpdate } = adminActions;

const ProxyUpdateModal = ({
  setProspectInfoToggle,
  prospectInfoModel,
  openProspectInfo,
}) => {
  const [server, setServer] = useState(openProspectInfo.server);
  const [user, setUser] = useState(openProspectInfo.user);
  const [password, setPassword] = useState(openProspectInfo.password);
  const dispatch = useDispatch();

  const updateProxy = (id, server, user, password) => {
    console.log("update -- ", id, server, user, password);
    dispatch(
      requestProxyUpdate({
        id,
        pserver: server,
        puser: user,
        ppassword: password,
      })
    );
  };
  return (
    <Modal
      toggle={() => {
        setProspectInfoToggle({});
      }}
      isOpen={prospectInfoModel}
    >
      <div className=" modal-header">
        <h5 className=" modal-title" id="exampleModalLabel">
          Update the Proxy
          <span
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              setProspectInfoToggle({});
            }}
          >
            <span aria-hidden={true}>×</span>
          </span>
        </h5>
      </div>
      <ModalBody style={{ maxWidth: 800 }}>
        <Row>
          <Col xs={4}>
            <b>Server</b>
          </Col>
          <Col xs={8} className="my-auto">
            <Input
              type="text"
              name="success"
              id="success"
              value={server}
              onChange={(e) => {
                setServer(e.target.value);
              }}
              placeholder="Add Server"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>User</b>
          </Col>
          <Col xs={8}>
            <Input
              type="text"
              name="success"
              id="success"
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
              }}
              placeholder="Add Username"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <b>Password</b>
          </Col>
          <Col xs={8}>
            <Input
              type="text"
              name="success"
              id="success"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Add password"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Button
              className="btn-success"
              onClick={() => {
                updateProxy(openProspectInfo.id, server, user, password);
              }}
              style={{
                width: 250,
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              Update
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default ProxyUpdateModal;
