// Campaigns Page

import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Progress,
} from "reactstrap";

import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  liaccountActions,
  campaignActions,
  errorActions,
  sequenceActions,
} from "../../../actions";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { upgradeToProRequest } from "actions/campaignActions";
import NotificationAlert from "react-notification-alert";
import UpgradeModal from "./../../components/UpgradeModal";
const {
  requestDeleteCampaign,
  requestStartCampaign,
  requestPauseCampaign,
  requestGetCampaigns,
} = campaignActions;

const { requestGetAccountById, requestGetMessageByLiId } = liaccountActions;

const { requestGetSequences } = sequenceActions;

const { resetError } = errorActions;

const LoadingSpinner = (props) => {
  return (
    <StyledLoadingSpinner>
      <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </StyledLoadingSpinner>
  );
};

// TODO: extarct custom spinner and overlay
const StyledLoadingSpinner = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledTempOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c2c2c;
  opacity: 0.8;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  z-index: 100;

  .centered-text {
    position: absolute;
    top: 54%;
    left: 50%;
    z-index: 101;
    color: white;
    font-size: 20px;
    transform: translateX(-50%);
  }
`;

class CampaignPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      alert: null,
      subscriptionModel: false,
      upgradeModalText: "",
    };
    this.notificationAlert = React.createRef();
    this.weeklyAlert = React.createRef();
  }
  componentDidMount() {
    const { liaccount } = this.props;
    if (this.props.campaigns.length === 0 && !this.props.loading) {
      this.props.requestGetCampaigns(liaccount.email);
    }
    if (this.props.sequences.length === 0 && !this.props.seqLoading) {
      this.props.requestGetSequences(liaccount.email);
    }
    // background loading
    if (!liaccount.connectionData && !this.props.liaLoading) {
      this.props.requestGetAccountById(liaccount._id);
      this.props.requestGetMessageByLiId(liaccount._id);
    }
    if (
      this.props.liaccount.reLoginRequired === true &&
      !this.props.liaLoading
    ) {
      let options = {
        place: "tc",
        message: (
          <div>
            <div>
              Re-login required. Please go{" "}
              <Link to="/" className="text-decoration-none">
                here
              </Link>{" "}
              to re-login your account
            </div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: false,
      };
      this.notificationAlert.current.notificationAlert(options);
    }
    if (
      this.props.liaccount.requestWeeklyLimited === true &&
      !this.props.liaLoading
    ) {
      let options = {
        place: "tc",
        message: (
          <div>
            <div
              style={{
                display: "inline",
              }}
            >
              <td
                onClick={() =>
                  window.open(
                    "https://help.perfectprospect.io/portal/en/kb/articles/connection-requests-limited-by-linkedin",
                    "_blank"
                  )
                }
              >
                We've can't send any more connection requests this week due to a
                limitation on the account set by Linkedin. Please see our guide
                here for more information on how to deal with this situation.
              </td>
            </div>
          </div>
        ),
        type: "info",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: false,
      };
      this.weeklyAlert.current.notificationAlert(options);
    }
  }

  noSequenceAlert = () => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-150px" }}
          title="Sequence missing."
          onConfirm={() => this.gotoSeqBuilder()}
          onCancel={() => this.gotoSeqBuilder()}
          confirmBtnBsStyle="info"
        >
          Please add a sequence before creating your campaign.
        </ReactBSAlert>
      ),
    });
  };

  gotoSeqBuilder = () => {
    const {
      match: {
        params: { account },
      },
    } = this.props;
    this.props.history.push(`/admin/${account}/sequences/new`);
  };

  checkIsSubscriptionExpired = () => {
    const accountBillDate = this.props.liaccount.billDate
      ? moment(this.props.liaccount.billDate * 1000)
      : moment();
    const currentDate = moment();
    // console.log(accountBillDate.format("YYYY/MM/DD"));

    if (accountBillDate > currentDate) {
      return false;
    } else return true;
  };

  createCampaign = () => {
    const {
      match: {
        params: { account },
      },
      sequences,
    } = this.props;
    if (!sequences || !sequences.length) {
      this.noSequenceAlert();
    } else {
      let privacyStr = localStorage.getItem("privacy");
      let privacy = JSON.parse(privacyStr);
      let runningCam = this.props.campaigns.filter((camp) => {
        return camp.isRunning === true;
      });
      if (
        privacy.CAMPAIGN_MAX > 0 &&
        privacy.CAMPAIGN_MAX <= runningCam.length
      ) {
        console.log(privacy.CAMPAIGN_MAX);
        console.log(runningCam.length);
        console.log("runningCam.length");
        this.setState({
          upgradeModalText: "Please upgrade the plan to create campaign",
        });
        this.setState({
          subscriptionModel: true,
        });
      } else {
        this.props.history.push(`/admin/${account}/campaigns/new`);
      }
    }
    // }
  };

  campaignAction = (id, action) => {
    const { account } = this.props.match.params;
    const { campaigns } = this.props;
    const campaign = campaigns.filter(
      (camp) => camp.id === id || camp._id === id
    )[0];

    switch (action) {
      case "remove":
        this.props.requestDeleteCampaign(campaign.id, account);
        break;
      case "start":
        let privacyStr = localStorage.getItem("privacy");
        let privacy = JSON.parse(privacyStr);
        let runningCam = this.props.campaigns.filter((camp) => {
          return camp.isRunning === true;
        });
        if (
          privacy.CAMPAIGN_MAX > 0 &&
          privacy.CAMPAIGN_MAX <= runningCam.length
        ) {
          console.log(privacy.CAMPAIGN_MAX);
          console.log(runningCam.length);
          console.log("runningCam.length");
          this.setState({
            upgradeModalText: "Please upgrade the plan to start campaign",
          });
          this.setState({
            subscriptionModel: true,
          });
        } else {
          this.props.requestStartCampaign(
            campaign.id,
            campaign.isStarted ? "resume" : "start"
          );
        }
        break;
      case "pause":
        this.setState({
          subscriptionModel: false,
        });
        this.props.requestPauseCampaign(campaign.id);
        break;
      default:
        this.props.requestPauseCampaign(campaign.id);
        break;
    }

    this.hideAlert();
  };
  warningWithConfirmMessage = (e, id, action) => {
    e.preventDefault();
    if (action === "pause" || action === "start") {
      if (action === "start") {
        // let runningCamp = this.props.campaigns.filter((camp) => {
        //   return camp.isRunning === true;
        // });
        // if (currentLiAccount.isProPlan === false && runningCamp.length >= 2) {
        //   return this.setState({
        //     subscriptionModel: false
        //   })
        // }
      }

      this.campaignAction(id, action);
      return;
    }
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.campaignAction(id, action)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={`Yes, ${action} it!`}
          cancelBtnText="Cancel"
          showCancel
        >
          {action === "remove" &&
            "You will not be able to recover the campaign data."}
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({ alert: null });
  };
  navigateToCampaign = (e, identifier) => {
    e.preventDefault();
    const { account } = this.props.match.params;
    this.props.history.push(
      `/admin/${account}/campaigns/details-${identifier}`
    );
  };
  getCampaignType = (sequenceId) => {
    const sequence = this.props.sequences.filter(
      (seq) => seq._id === sequenceId
    )[0];
    return sequence ? sequence.sequences[0].name : "";
  };

  editSequesnceGoto = (mode, identifier) => {
    const seq = this.props.sequences.filter((seq) => seq._id === identifier);
    const { account } = this.props.match.params;
    if (seq) {
      return this.props.history.push(
        `/admin/${account}/sequences/${mode}-${seq[0].identifier}`
      );
    }
  };

  viewProspects(campaignId) {
    const { account } = this.props.match.params;
    return this.props.history.push(`/admin/${account}/prospects`, {
      campaignId: campaignId,
    });
  }

  render() {
    const { campaigns, loading, sequences, liaccount } = this.props;
    let campaignData = campaigns.map((camp) => ({
      ...camp,
    }));

    const getSequence = (sequenceId) => {
      try {
        const { account } = this.props.match.params;
        const data = sequences.filter(
          (sequence) => sequence._id === sequenceId
        );
        return (
          <>
            &nbsp;&nbsp;&nbsp;|
            <Link
              to={`/admin/${account}/sequences/edit-${data[0].identifier}`}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="ml-2 camp-seq-link"
            >
              {data[0].name}
            </Link>
          </>
        );
      } catch (e) { }
      return null;
    };

    if (Array.isArray(campaignData)) {
      campaignData.sort((a, b) => {
        const keyA = new Date(a.createdAt),
          keyB = new Date(b.createdAt);
        // Compare the 2 dates
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
    }

    return (
      <>
        {loading && (
          <StyledTempOverlay>
            <LoadingSpinner asOverlay={true} />
            <div className="centered-text">Loading...</div>
          </StyledTempOverlay>
        )}
        {liaccount && <NotificationAlert ref={this.weeklyAlert} />}

        {liaccount && (
          <div className="content accountlist" key={liaccount.name}>
            <NotificationAlert ref={this.notificationAlert} />
            {this.state.alert}
            <h3 className="el-inline">Campaign Manager</h3>
            <Button
              className="add-btn el-inline btn btn-primary btn-icon btn-round"
              onClick={() => {
                this.createCampaign();
              }}
            // disabled={this.checkIsSubscriptionExpired()}
            >
              <i className="nc-icon nc-simple-add" />
            </Button>
            {
              <UpgradeModal
                show={this.state.subscriptionModel}
                message={this.state.upgradeModalText}
                change={() => {
                  this.setState({
                    subscriptionModel: !this.state.subscriptionModel,
                  });
                }}
              />
            }
            {campaignData &&
              campaignData.map((camp, index) => {
                return (
                  <Card key={index}>
                    <CardBody style={{ paddingLeft: "20px" }}>
                      <Row
                        className="row-eq-height row-eq-width camp-page-item"
                        style={{ flexWrap: "none" }}
                      >
                        <Col
                          xs={4}
                          style={{
                            lineHeight: ".7rem",
                            margin: "3px 0",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          <span
                            style={{
                              display: "block",
                              fontSize: "1rem",
                              lineHeight: "1.1rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {
                              <a
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                href="/"
                                onClick={(e) =>
                                  this.navigateToCampaign(e, camp.id)
                                }
                              >
                                {camp.name.split(" -> ")[1]}
                              </a>
                            }
                          </span>
                          <span
                            className="camp-seq-link"
                            style={{
                              color: "gray",
                              fontSize: ".6rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {moment(camp.createdAt).format("lll")}
                            {/* ad sequence name and link here*/}
                            {getSequence(camp.sequenceId)}
                          </span>
                          <span
                            style={{
                              display: "block",
                              color: "#39B5B8",
                              marginTop: "6px",
                              whiteSpace: "nowrap",
                              lineHeight: "normal",
                            }}
                          >
                            {camp.lastError ? (
                              <>Invalid</>
                            ) : !camp.isStarted && !camp.searchDone ? (
                              <>
                                <span className="">
                                  <strong>Compiling</strong>
                                </span>
                              </>
                            ) : camp.isRunning ? (
                              <>
                                <span className="text-success">
                                  <strong>Running</strong>
                                </span>
                              </>
                            ) : !camp.isFinished ? (
                              <>
                                <span className="text-warning">
                                  <strong>Idle</strong>
                                </span>
                              </>
                            ) : (
                              <>Completed</>
                            )}
                          </span>
                        </Col>
                        <Col xs={3}>
                          <Row className="row-eq-height">
                            <Col style={{ marginTop: ".6rem" }}>
                              <div
                                style={{
                                  textAlign: "center",
                                  lineHeight: ".5rem",
                                  fontSize: ".7rem",
                                }}
                              >
                                Prospects
                              </div>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                {camp.totalProspects}
                              </div>
                            </Col>
                            <Col style={{ marginTop: ".6rem" }}>
                              <div
                                style={{
                                  textAlign: "center",
                                  lineHeight: ".5rem",
                                  fontSize: ".7rem",
                                }}
                              >
                                Accepted
                              </div>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                {camp.acceptRate
                                  ? camp.acceptRate.toFixed(2)
                                  : "0.0"}
                                %
                              </div>
                            </Col>
                            <Col style={{ marginTop: ".6rem" }}>
                              <div
                                style={{
                                  textAlign: "center",
                                  lineHeight: ".5rem",
                                  fontSize: ".7rem",
                                }}
                              >
                                Replied
                              </div>
                              <div
                                style={{
                                  fontSize: "1rem",
                                  textAlign: "center",
                                }}
                              >
                                {camp.replyRate
                                  ? camp.replyRate.toFixed(2)
                                  : "0.0"}
                                %
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={3}
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Row>
                            <Col
                              xs={8}
                              className="progress-container"
                              style={{
                                marginTop: "center",
                                paddingRight: "0",
                                paddingLeft: "40px",
                              }}
                            >
                              <Progress
                                max="100"
                                value={
                                  !camp.actionLengh ||
                                    !camp.totalProspects ||
                                    camp.totalProspects === 0
                                    ? 0
                                    : (
                                      (camp.actionLengh * 100) /
                                      camp.totalProspects
                                    ).toFixed(2)
                                }
                                style={{
                                  height: "25px",
                                  marginTop: ".7rem",
                                  minWidth: "6rem",
                                }}
                              >
                                <span
                                  className="progress-value"
                                  style={{
                                    paddingLeft: 10,
                                    fontSize: "large",
                                    opacity: ".8",
                                  }}
                                >
                                  {!camp.totalProspects ||
                                    camp.totalProspects === 0
                                    ? 0
                                    : (
                                      (camp.actionLengh * 100) /
                                      camp.totalProspects
                                    ).toFixed(2)}
                                  %
                                </span>
                              </Progress>
                            </Col>
                            <Col xs={2}>
                              <Button
                                style={{ margin: "0", marginTop: ".2rem" }}
                                size="md"
                                id={`tooltip-camp-pause-${camp.identifier}`}
                                className="btn-icon btn-link remove"
                                onClick={(e) =>
                                  this.warningWithConfirmMessage(
                                    e,
                                    camp.id ? camp.id : camp._id,
                                    camp.isRunning ? "pause" : "start"
                                  )
                                }
                                disabled={!camp.isStarted && !camp.isFinished}
                              >
                                <i
                                  className={
                                    camp.isRunning
                                      ? "fa fa-pause"
                                      : "fa fa-play"
                                  }
                                />
                              </Button>
                            </Col>
                            <Col xs={1}>
                              {camp.lastError ? (
                                <></>
                              ) : !camp.isStarted && !camp.searchDone ? (
                                <>
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-circle-o-notch fa-spin"
                                      style={{
                                        fontSize: "16px",
                                        lineHeight: "47px",
                                        color: "#51CBCE",
                                      }}
                                    />
                                  </span>
                                </>
                              ) : camp.isRunning ? (
                                <>
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-circle-o-notch fa-spin text-success"
                                      style={{
                                        fontSize: "16px",
                                        lineHeight: "47px",
                                      }}
                                    />
                                  </span>
                                </>
                              ) : !camp.isRunning ? (
                                <>
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <i
                                      className="fa fa-circle text-warning"
                                      style={{
                                        fontSize: "16px",
                                        lineHeight: "47px",
                                      }}
                                    />
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={2}>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              nav
                              style={{
                                color: "black",
                                textAlign: "center",
                                fontSize: "1.3rem",
                                padding: "0",
                                marginTop: ".5rem",
                              }}
                            >
                              &bull;&bull;&bull;
                            </DropdownToggle>
                            <DropdownMenu nav right>
                              <DropdownItem
                                onClick={() => {
                                  // console.log(camp);
                                  this.viewProspects(camp.identifier);
                                }}
                              >
                                View Prospects
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  this.editSequesnceGoto(
                                    "edit",
                                    camp.sequenceId
                                  );
                                }}
                              >
                                Edit Sequence
                              </DropdownItem>
                              <DropdownItem
                                style={{ color: "red" }}
                                className="btn-icon btn-link remove"
                                onClick={(e) =>
                                  this.warningWithConfirmMessage(
                                    e,
                                    camp.id,
                                    "remove"
                                  )
                                }
                              >
                                Delete Campaign
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                );
              })}
            {this.props.sleepMode && (
              <Modal isOpen={true}>
                <ModalHeader>Your server is in sleep mode</ModalHeader>
                <ModalBody>
                  <p>
                    If you need to create new campaign/sequence or send reply
                    messages, please change your working hours on the settings
                    page and wait a minute for the system to update.
                  </p>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="primary"
                      onClick={() => this.props.resetError()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="success"
                      onClick={() => {
                        this.props.resetError();
                        this.props.history.push(
                          `/admin/${this.props.match.params.account}/settings`
                        );
                      }}
                    >
                      Update Settings
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            )}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.campaigns,
  loading: state.campaigns.loading,
  sequences: state.sequences.sequences,
  seqLoading: state.sequences.loading,
  error: state.campaigns.error,
  liaccount: state.liaccounts.liaccount,
  liaccounts: state.liaccounts.liaccounts,
  liaLoading: state.liaccounts.loading,
  sleepMode: state.exception.sleepMode,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetAccountById: (id) => dispatch(requestGetAccountById(id)),
  requestGetMessageByLiId: (id) => dispatch(requestGetMessageByLiId(id)),
  requestGetSequences: (email) => dispatch(requestGetSequences(email)),
  requestGetCampaigns: (email) => dispatch(requestGetCampaigns(email, [], [])),
  requestDeleteCampaign: (_id, account) =>
    dispatch(requestDeleteCampaign(_id, account)),
  requestStartCampaign: (_id, mode) =>
    dispatch(requestStartCampaign(_id, mode)),
  requestPauseCampaign: (_id) => dispatch(requestPauseCampaign(_id)),
  resetError: () => dispatch(resetError()),
  upgradeToProRequestFunc: (planId, cb) =>
    dispatch(upgradeToProRequest(planId, cb)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(CampaignPage)
);
