const getError = (e) => {
  // let error = "Network Error";
  // console.log(e, e.response);
  // if (e.response) {
  //   if (!e.response.data.errors) {
  //     error = e.response.data;
  //   } else if (typeof e.response.data.errors.msg === "object") {
  //     error = e.response.data.errors.msg[0].msg;
  //   } else {
  //     error = e.response.data.errors.msg;
  //   }
  // }
  // return error;

  let _error = {
    code: 400,
    type: "",
    msg: "",
  };

  if (e.response) {
    let messsage = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        messsage = e.response.data;
      } else if (typeof e.response.data.errors.msg === "object") {
        messsage = e.response.data.errors.msg[0].msg;
      } else {
        messsage = e.response.data.errors.msg;
      }
    }

    if (e.response?.status === 400) {
      _error.code = 400;
      _error.type = "Bad request";
      _error.msg = messsage;
    }
    if (e.response?.status === 401) {
      _error.code = 401;
      _error.type = "Unauthorized";
      _error.msg = messsage;
    }
    if (e.response?.status === 403) {
      _error.code = 403;
      _error.type = "Forbidden";
      _error.msg = messsage;
    }
    if (e.response?.status === 404) {
      _error.code = 404;
      _error.type = "Not Found";
      _error.msg = messsage;
    }
    if (e.response?.status === 405) {
      _error.code = 405;
      _error.type = "Method Not Allowed";
      _error.msg = messsage;
    }
    if (e.response?.status === 500) {
      _error.code = 500;
      _error.type = "Server error";
      _error.msg = messsage;
    }
  }
  console.log(_error);
  return _error;
};

export default getError;
