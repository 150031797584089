import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import ReactTable from "react-table";

// reactstrap components
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import ReactBSAlert from "react-bootstrap-sweetalert";

import { integrationActions } from "../../../actions";
import moment from "moment";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import GlobalIntegrationPage from "../GlobalIntegration/GlobalIntegrationPage";
import "./style.scss";
import UpgradeModal from "views/components/UpgradeModal";

const { requestDeleteIntegration, requestGetintegrations } = integrationActions;
class IntegrationManager extends React.Component {
  state = {
    alert: null,
    activeTab: "global",
    allowIntegration: false,
    planType: "",
    upgradeModalText: "",
  };

  componentDidMount() {
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);
    this.setState({ planType: privacy.PLAN });
    const { liaccount } = this.props;
    if (this.props.integrations.length === 0 && !this.props.loading) {
      this.props.requestGetintegrations(liaccount.email);
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab)
      this.setState({
        activeTab: tab,
      });
  }

  createSIntegration = () => {
    if (
      this.state.planType === "FREE_PLAN" ||
      this.state.planType === "PROFESSIONAL_PLAN"
    ) {
      this.setState({
        allowIntegration: true,
        upgradeModalText:
          "Please upgrade the plan to create new local integration",
      });
      return;
    }
    const { account } = this.props.match.params;
    this.props.history.push(`/admin/${account}/integration/new`);
  };
  navigateTo = (e, identifier, mode) => {
    e.preventDefault();
    const { account } = this.props.match.params;
    this.props.history.push(
      `/admin/${account}/integration/${mode}-${identifier}`
    );
  };
  deleteIntegration = (identifier) => {
    const { account } = this.props.match.params;
    const { integrations } = this.props;
    const integrationObj = integrations.filter(
      (item) => item._id === identifier
    )[0];
    this.props.requestDeleteIntegration(integrationObj._id, account);
    this.hideAlert();
  };

  warningWithConfirmMessage = (e, identifier) => {
    e.preventDefault();
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => this.deleteIntegration(identifier)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to recover this sequence!
        </ReactBSAlert>
      ),
    });
  };
  hideAlert = () => {
    this.setState({ alert: null });
  };
  render() {
    const { integrations, loading } = this.props;
    const integrationData =
      integrations &&
      integrations.map((item) => {
        return {
          ...item,
          campaign_name: item.campaign_name?.substring(
            item.campaign_name.lastIndexOf(">") + 2
          ),
          more: (
            <Button
              color="danger"
              size="md"
              className="btn-icon btn-default btn-link remove"
              onClick={(e) => this.warningWithConfirmMessage(e, item._id)}
            >
              <i className="fa fa-times" />
            </Button>
          ),
          createdAt: moment(item.createdAt).format("lll"),
        };
      });
    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text="Loading Integration"
        ></LoadingOverlay>
        {
          <UpgradeModal
            show={this.state.allowIntegration}
            account={this.props.match.params.account}
            message={this.state.upgradeModalText}
            change={() => {
              this.setState({ allowIntegration: false });
            }}
          />
        }
        <div className="content sequencelist">
          {this.state.alert}
          <Row className="int-tabs">
            <Col md="12" className="mt-5 mr-2">
              <Nav>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: this.state.activeTab === "global",
                    })}
                    onClick={() => {
                      this.toggle("global");
                    }}
                  >
                    Global Integration
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: this.state.activeTab === "simple",
                    })}
                    onClick={() => {
                      this.toggle("simple");
                    }}
                  >
                    Local Integration
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="global">
                  <Row>
                    <Col sm="12">
                      <GlobalIntegrationPage
                        add={false}
                        {...this.props}
                        setAllowIntegration={() => {
                          this.setState({
                            allowIntegration: true,
                            upgradeModalText:
                              "Please upgrade the plan to create new global integration",
                          });
                          this.setState({ allowIntegration: true });
                        }}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="simple">
                  <Row>
                    <Col md="12" sm="12">
                      <div className="content campaignlist" key={1}>
                        <Col md="12" className="ml-auto mr-auto">
                          <h3 className="el-inline">
                            Campaign-level integration with
                            <img
                              width="110"
                              style={{ paddingBottom: "6px" }}
                              src="https://perfectprospect.io/assets/img/zapier-logo.png"
                              alt="logo"
                            />
                          </h3>
                          <Button
                            className="add-btn el-inline btn-primary btn-icon btn-round"
                            onClick={this.createSIntegration}
                          >
                            <i
                              className="nc-icon nc-simple-add"
                              style={{ width: 22 }}
                            />
                          </Button>

                          <p>
                            Export your data to your CRM and 1500+ other apps
                            via Zapier.
                          </p>
                          <Card>
                            <CardBody>
                              <ReactTable
                                data={integrationData}
                                resizable={true}
                                columns={[
                                  {
                                    Header: "Campaign Name",
                                    accessor: "campaign_name",
                                    sortable: false,
                                    filterable: false,
                                  },
                                  {
                                    Header: "Webhook Action",
                                    accessor: "webhook_action_name",
                                    sortable: false,
                                    filterable: false,
                                  },
                                  {
                                    Header: "Webhook URL",
                                    accessor: "webhook_url",
                                    sortable: false,
                                    filterable: false,
                                  },
                                  {
                                    Header: "Date",
                                    accessor: "createdAt",
                                    sortable: false,
                                    filterable: false,
                                  },
                                  {
                                    Header: "More",
                                    accessor: "more",
                                    sortable: false,
                                    filterable: false,
                                  },
                                ]}
                                defaultPageSize={10}
                                showPaginationTop={false}
                                showPaginationBottom
                                /*
                              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                            */
                                className=" -highlight sequence-manager-table"
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  campaigns: state.campaigns.campaigns,
  integrations: state.integrations.integrations,
  loading: state.integrations.loading,
  error: state.integrations.error,
});

const mapDispatchToProps = (dispatch) => ({
  requestDeleteIntegration: (_id, account) =>
    dispatch(requestDeleteIntegration(_id, account)),
  requestGetintegrations: (email) => dispatch(requestGetintegrations(email)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(IntegrationManager)
);
