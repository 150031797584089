import { LIACCOUNT as ACTION_HEADER } from "./types";

export function requestAddAccount(email, password, plan) {
  return { type: ACTION_HEADER.REQUEST_ADD, email, password, plan };
}

export function successAddAccount(account) {
  return { type: ACTION_HEADER.SUCCESS_ADD, account };
}

export function requestGetAccounts() {
  return { type: ACTION_HEADER.REQUEST_GET };
}

export function successGetAccounts(accounts) {
  return { type: ACTION_HEADER.SUCCESS_GET, accounts };
}

export function requestGetAccountById(id) {
  return { type: ACTION_HEADER.REQUEST_GET_BY_ID, id };
}

export function successGetAccountById(account) {
  return { type: ACTION_HEADER.SUCCESS_GET_BY_ID, account };
}
export function requestGetMessageByLiId(id) {
  return { type: ACTION_HEADER.REQUEST_GET_MESSAGES_BY_ID, id };
}

export function successGetMessageByLiId(data) {
  return { type: ACTION_HEADER.SUCCESS_GET_MESSAGES_BY_ID, data };
}

export function requestHeadEditAccount(email, headLine) {
  return { type: ACTION_HEADER.REQUEST_HEAD_EDIT, email, headLine };
}

export function successHeadEditAccount(message) {
  return { type: ACTION_HEADER.SUCCESS_HEAD_EDIT, message };
}

export function failHeadEditAccount(error) {
  return { type: ACTION_HEADER.FAIL_HEAD_EDIT, error };
}

export function requestAboutEditAccount(email, aboutLine) {
  return { type: ACTION_HEADER.REQUEST_ABOUT_EDIT, email, aboutLine };
}

export function successAboutEditAccount(message) {
  return { type: ACTION_HEADER.SUCCESS_ABOUT_EDIT, message };
}

export function failAboutEditAccount(error) {
  return { type: ACTION_HEADER.FAIL_ABOUT_EDIT, error };
}

export function setReplies(replies) {
  return { type: ACTION_HEADER.SET_REPLIES, replies };
}

export function requestSendPIN(
  email,
  password,
  pin,
  reLoginUrl,
  relogin,
  planType
) {
  return {
    type: ACTION_HEADER.REQUEST_SENDPIN,
    email,
    password,
    pin,
    reLoginUrl,
    relogin,
    planType,
  };
}

export function successSendPIN(account) {
  return { type: ACTION_HEADER.SUCCESS_SENDPIN, account };
}

export function requestReLogin(email, password, cb) {
  return { type: ACTION_HEADER.REQUEST_RELOGIN, email, password, cb };
}

export function successReLogin(account) {
  return { type: ACTION_HEADER.SUCCESS_RELOGIN, account };
}

export function requestDeleteAccount(_id) {
  return { type: ACTION_HEADER.REQUEST_DELETE, _id };
}

export function successDeleteAccount(_id) {
  return { type: ACTION_HEADER.SUCCESS_DELETE, _id };
}

export function requestChangeSettings(_id, settings) {
  return { type: ACTION_HEADER.REQUEST_SETTINGS, _id, settings };
}
export function requestEmailCredSave(_id, emailData) {
  return {
    type: ACTION_HEADER.REQUEST_EMAIL_CRED_SAVE,
    _id,
    emailData,
  };
}
export function successEmailCredSave(_id, emailData) {
  return {
    type: ACTION_HEADER.SUCCESS_EMAIL_CRED_SAVE,
    _id,
    emailData,
  };
}
export function successChangeSettings(_id, settings) {
  return { type: ACTION_HEADER.SUCCESS_SETTINGS, _id, settings };
}

export function setFetchTimer(timer) {
  return { type: ACTION_HEADER.SET_FETCH_TIMER, timer };
}

export function fail(error, args) {
  return { type: ACTION_HEADER.FAIL, error, args };
}

export function requestUpdateProfile(email, profileLink) {
  return { type: ACTION_HEADER.REQUEST_UPDATE_PROFILE, email, profileLink };
}

export function requestResetError() {
  return { type: ACTION_HEADER.RESET_ERROR };
}

export function updateLiaccountInfo(data) {
  return { type: ACTION_HEADER.UPDATE_ACCOUNT, data };
}
