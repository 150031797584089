import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Row, Col, Input } from "reactstrap";
import ReactTable from "react-table";
import ProxyUpdateModal from "./ProxyUpdateModal"
import { adminActions } from "../../../actions";
import { useDispatch } from "react-redux";

const { requestProxySave } = adminActions;

const Proxy = (props) => {

  const dispatch = useDispatch();
  // console.log(props);
  const { proxyList } = props;
  console.log('******proxyList************', props)
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [users, setUsers] = useState([]);
  const [prospectInfoModel, setProspectInfoModel] = useState(false);
  const [openProspectInfo, setOpenProspectInfo] = useState({});
  const [alert, setAlert] = useState(null);
  const [server, setServer] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (proxyList.data) {
      setUsers(proxyList.data)
      setProspectInfoModel(false);
    }
  }, [proxyList])
  const setProspectInfoToggle = (e, data) => {
    // console.log("e", e)
    if (e && e.currentTarget)
      e.preventDefault()
    if (data) {
      setOpenProspectInfo({
        id: data.id, server: data.server, user: data.user, password: ""
      });
    }
    setProspectInfoModel(!prospectInfoModel);
  };

  const addProxy = (server, user, password) => {
    console.log("save -- ", server, user, password)
    setServer("")
    setUser("")
    setPassword("")
    dispatch(requestProxySave({ pserver: server, puser: user, ppassword: password }));
    // saveProxy(server, user, password)
  };
  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    // console.log(id)
    // console.log(row)
    // console.log(row[id])

    if (row[id]) {
      const orgStringArray = row[id].toLowerCase().split(" ");
      const filterString = filter.value.toLowerCase();
      for (let i in orgStringArray) {
        if (
          orgStringArray
            .slice(i)
            .join(" ")
            .startsWith(filterString)
        ) {
          return true;
        }
      }

      return false;
    }
    return true;
  };
  const userData = users && users.map((seq) => ({
    server:
      seq.server
    ,
    username: seq.user,
    usage: seq.usage,
    linkedins: seq.linkedins.map((step, index) => (
      <div className="el-inline" style={{ display: "flex" }}
        key={index}>
        <span className="el-inline" style={{ color: "blue" }}>
          {step}
        </span>
      </div>
    )),
    createdAt: (
      <span> {seq.createdAt.substring(0, 10)}</span>
    ),
    actions: (
      <div className="">
        <Button
          color="danger"
          size="md"
          className="btn-icon btn-default btn-link remove"
          onClick={(e) => setProspectInfoToggle(e, seq)}
        >
          <i className="fa fa-edit" />
        </Button>
      </div >
    ),
  }));
  return (
    <div>
      {alert}
      <ProxyUpdateModal
        setProspectInfoToggle={setProspectInfoToggle}
        prospectInfoModel={prospectInfoModel}
        openProspectInfo={openProspectInfo}
      />
      <div className="d-flex" >
        <Row>
          <Col md="12">
            <div>
              <h3 className="el-inline">Proxy List</h3>
              <Input
                type="text"
                name="success"
                id="success"
                className="el-inline"
                value={server}
                onChange={(e) => {
                  setServer(e.target.value);
                }}
                style={{ width: 200, margin: 10 }}
                placeholder="Add Server"
              />
              <Input
                type="text"
                name="success"
                id="success"
                value={user}
                className="el-inline"
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                placeholder="Add Username"
                style={{ width: 200, margin: 10 }}

              />
              <Input
                type="text"
                name="success"
                id="success"
                className="el-inline"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Add password"
                style={{ width: 200, margin: 10 }}

              />
              <Button className="btn-success" onClick={() => {
                addProxy(server, user, password);
              }} style={{
                width: 150,
                margin: 10,
                height: 30,
                padding: 5
              }}>
                Add Proxy
            </Button>
            </div>
            <Card>
              <CardBody>
                <ReactTable
                  data={userData}
                  resizable={true}
                  filterable
                  columns={[
                    {
                      Header: "Proxy Server",
                      accessor: "server",
                      sortable: false,
                      filterable: true,
                      width: 320,
                    },
                    {
                      Header: "Proxy Username",
                      accessor: "username",
                      sortable: false,
                      filterable: false,
                      width: 200
                    },
                    {
                      Header: "Usage",
                      accessor: "usage",
                      sortable: false,
                      filterable: false,
                      width: 100
                    },
                    {
                      Header: "Linkedins",
                      accessor: "linkedins",
                      sortable: false,
                      filterable: false,
                      width: 300
                    }, {
                      Header: "Joined At",
                      accessor: "createdAt",
                      sortable: true,
                      filterable: false,
                      width: 150
                    }, {
                      Header: "Edit",
                      accessor: "actions",
                      sortable: true,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom
                  defaultFilterMethod={filterMethod}
                  className=" -highlight sequence-manager-table"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

      </div>
    </div>
  )
};

export default Proxy;