import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  prospectActions,
  campaignActions,
  tagActions,
  liaccountActions,
} from "../../../actions";
import { Modal } from "reactstrap";
import styled from "styled-components";

import BlackListPage from "./BlackList/BlackListPage";
import CampaignProspectsManageTagsModal from "../../components/campaigns/CampaignProspectsManageTagsModal";
import CampaignProspectsAssignTagsModal from "../../components/campaigns/CampaignProspectsAssignTagsModal";
import NotificationAlert from "react-notification-alert";
import { Link } from "react-router-dom";

const { requestGetAccountById, requestGetMessageByLiId } = liaccountActions;
const { requestGetBlackLinks } = prospectActions;
const { requestGetFullCampainData } = campaignActions;
const { requestGetTag } = tagActions;

const LoadingSpinner = (props) => {
  return (
    <StyledLoadingSpinner>
      <div className={`${props.asOverlay && "loading-spinner__overlay"}`}>
        <div className="lds-dual-ring"></div>
      </div>
    </StyledLoadingSpinner>
  );
};

// TODO: extarct custom spinner and overlay
const StyledLoadingSpinner = styled.div`
  .lds-dual-ring {
    display: inline-block;
    width: 64px;
    height: 64px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 60px;
    height: 60px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #ffffff;
    border-color: #ffffff transparent #ffffff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  .loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledTempOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2c2c2c;
  opacity: 0.8;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
  z-index: 100;

  .centered-text {
    position: absolute;
    top: 54%;
    left: 50%;
    z-index: 101;
    color: white;
    font-size: 20px;
    transform: translateX(-50%);
  }
`;

const ProspectPage = (props) => {
  const {
    match: {
      params: { identifier: account },
    },
    liaccount,
    blankLinksFetched,
    requestGetBlackLinks,
    fetched,
    loading,
    requestGetAccountById,
    requestGetMessageByLiId,
    requestGetFullCampainData,
    requestGetTag,
    tagFetched,
  } = props;

  const [isManageTagsModalOpened, setIsManageTagsModalOpened] = useState(false);
  const [isAssignTagsModalOpened, setIsAssignTagsModalOpened] = useState(false);
  const [assignTagData, setAssignTagData] = useState(null);
  const locationState = props.location.state;
  const notificationAlert = React.createRef();

  // initial loading
  useEffect(() => {
    if (
      !liaccount.connectionData &&
      !props.liaccloading &&
      account !== liaccount._id
    ) {
      requestGetAccountById(liaccount._id);
      requestGetMessageByLiId(liaccount._id);
    }
    if (!liaccount.allMessageData) {
      requestGetMessageByLiId(liaccount._id);
    }
    if (!tagFetched) {
      requestGetTag();
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      liaccount.connectionData &&
      liaccount.repliedData &&
      account === liaccount.id
    ) {
      if (!fetched && !loading) {
        requestGetFullCampainData(
          liaccount.email,
          liaccount.connectionData,
          liaccount.repliedData
        );
      }
      if (!blankLinksFetched) {
        requestGetBlackLinks(liaccount.email);
      }
    }
    if (liaccount.reLoginRequired === true && !loading) {
      let options = {
        place: "tc",
        message: (
          <div>
            <div>
              Re-login required. Please go{" "}
              <Link to="/" className="text-decoration-none">
                here
              </Link>{" "}
              to re-login your account
            </div>
          </div>
        ),
        type: "danger",
        icon: "now-ui-icons ui-1_bell-53",
        autoDismiss: false,
      };
      notificationAlert.current.notificationAlert(options);
    }
  }, [liaccount]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderPage = () => {
    // TODO: We can add the items according to increased pages.
    return (
      <BlackListPage
        {...props}
        location={props.location}
        toggleTagsModal={toggleManageTagsModal}
        toggleAssignModal={toggleAssignTagsModal}
        setSelectedCampaignId={
          locationState && locationState.campaignId
            ? locationState.campaignId
            : ""
        }
      />
    );
  };

  const toggleManageTagsModal = () => {
    setIsManageTagsModalOpened((prev) => !prev);
  };

  const toggleAssignTagsModal = (id, tags, identifier, targetIndex) => {
    if (id && tags && identifier)
      setAssignTagData({ id, tags, identifier, targetIndex });
    setIsAssignTagsModalOpened((prev) => !prev);
  };

  return (
    <>
      {loading ? (
        <StyledTempOverlay>
          <LoadingSpinner asOverlay={true} />
          <div className="centered-text">Loading...</div>
        </StyledTempOverlay>
      ) : (
        <div className="page-prospect">
          <div>
            <h3 className="page-prospect__header-title my-auto">
              Prospects Manager
            </h3>
          </div>
          <NotificationAlert ref={notificationAlert} />
          {renderPage()}
        </div>
      )}
      <Modal
        modalClassName="prospectpage__modal"
        isOpen={isManageTagsModalOpened}
        toggle={toggleManageTagsModal}
      >
        <CampaignProspectsManageTagsModal></CampaignProspectsManageTagsModal>
      </Modal>
      <Modal
        modalClassName="prospectpage__modal"
        isOpen={isAssignTagsModalOpened}
        toggle={toggleAssignTagsModal}
      >
        <CampaignProspectsAssignTagsModal
          data={assignTagData}
        ></CampaignProspectsAssignTagsModal>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  blankLinksFetched: state.prospects.fetched,
  loading: state.campaigns.loading,
  fetched: state.campaigns.fetched,
  liaccloading: state.liaccounts.loading,
  seqLoading: state.sequences.loading,
  liaccount: state.liaccounts.liaccount,
  tagFetched: state.tag.fetched,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetAccountById: (id) => dispatch(requestGetAccountById(id)),
  requestGetMessageByLiId: (id) => dispatch(requestGetMessageByLiId(id)),
  requestGetTag: () => dispatch(requestGetTag()),
  requestGetBlackLinks: (liEmail) => dispatch(requestGetBlackLinks(liEmail)),
  requestGetFullCampainData: (liEmail, connectionData, repliedData) =>
    dispatch(requestGetFullCampainData(liEmail, connectionData, repliedData)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(ProspectPage))
);
