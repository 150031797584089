// Linkedin Account Page
// Home Page
import React, { Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledTooltip,
} from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Button } from "react-bootstrap";

import AddAccountForm from "../components/AddAccountForm";
import ReLoginForm from "../components/ReLoginForm";
import PinForm from "../components/PinForm";
import LIAccountCard from "../components/LIRelated/LIAccountCard";
import EditLIInfoForm from "../components/LIRelated/EditLIInfoForm";

import { setLocalStorageData } from "../../utils/localStorageService";

import {
  liaccountActions,
  campaignActions,
  sequenceActions,
  planActions,
  errorActions,
} from "../../actions";

import UpgradeModal from "views/components/UpgradeModal";

const {
  requestAddAccount,
  requestSendPIN,
  requestReLogin,
  requestDeleteAccount,
  requestUpdateProfile,
  requestResetError,
  successGetAccountById,
} = liaccountActions;

const { setCampFetch } = campaignActions;
const { setSeqFetch } = sequenceActions;
const { requestActivatePlan, requestUpgradePlan } = planActions;
const { resetError } = errorActions;

class LIAccountPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      upgradeModal: false,
      modal: false,
      priceModal: false,
      pinModal: false,
      reLoginModal: false,
      editLIAccountModal: false,
      sleepModal: false,
      editLIAccountEmail: "",
      editLIAccountHeadline: "",
      editLIAccountSummary: "",
      popIndex: -1,
      alert: null,
      reloginError: "",
      reloginEmail: "",
      loadingReLogin: false,
      selectedPlan: "",
      selectedAccountID: "",
    };
  }

  componentDidMount() {
    this.requestAddAccount = this.requestAddAccount.bind(this);
    this.requestSendPIN = this.requestSendPIN.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { error, loading, planError, planLoading, user } = this.props;
    if (!planError && !planLoading && prevProps.planLoading) {
      this.showModal(false, false, true, false, false, true);
      return;
    }
    if (error && error !== prevProps.error) {
      if (error.status && error.status === 521) {
        this.showModal(false, false, false);
        return;
      }

      if (error.includes("Manual check")) {
        this.showModal(true, false, true, false);
      } else if (error === "Network Error") {
        clearTimeout(this.timeId);
      }
    } else if (!error && !loading && prevProps.loading) {
      this.showModal(true, false, false);
      this.showModal(false, false, false);
    }
  }

  shouldShpow = () => {
    const check = ({ planType }) =>
      ["FREE_PLAN", "PROFESSIONAL_PLAN"].includes(planType);
    return this.props.liaccounts.some(check);
  };

  needUpgrade = () => {
    if (this.props.liaccounts.length === 0) {
      return false;
    }
    /*
    const { user } = this.props;
    if (user._doc && user._doc.planID === "PERSONAL_PLAN") {
      return true;
    }
    */
    return false;
  };

  toggleReLoginModal = (email) => {
    this.setState({
      reloginError: "",
      reloginEmail: email,
    });
    this.showModal(false, false, false, true);
  };

  toggleEditLIAccountModal = (email, headline, summary) => {
    this.setState({ editLIAccountEmail: email });
    this.setState({ editLIAccountHeadline: headline });
    this.setState({ editLIAccountSummary: summary });
    this.showModal(false, false, false, false, true);
  };

  showModal = (
    isPin,
    isPrice,
    modal,
    reLogin,
    editLIAccountModal,
    sleepMode
  ) => {
    this.props.requestResetError();
    if (sleepMode) {
      this.setState({ sleepModal: modal });
    } else {
      if (this.needUpgrade()) {
        this.setState({ upgradeModal: modal });
      } else if (isPin) {
        this.setState({ pinModal: modal });
      } else if (reLogin) {
        this.setState({ reLoginModal: reLogin });
      } else if (editLIAccountModal) {
        this.setState({ editLIAccountModal: editLIAccountModal });
      } else {
        this.setState({ priceModal: isPrice });

        this.setState({ modal });
      }
      if (!reLogin) this.setState({ reLoginModal: reLogin });
      if (!isPin) this.setState({ pinModal: isPin });
      //it's very strange logic, but it works
      if (!editLIAccountModal)
        this.setState({ editLIAccountModal: editLIAccountModal });
    }
  };
  requestAddAccount = (email, password) => {
    console.log(this.state.selectedPlan);
    this.email = email;
    this.password = password;
    this.relogin = false;
    const { liaccounts } = this.props;
    if (liaccounts.length === 1 && liaccounts[0].isTrial) {
      this.setState({
        alert: (
          <ReactBSAlert
            warning
            style={{ display: "block" }}
            title={`Billing Confirmation`}
            onConfirm={() => {
              this.props.requestAddAccount(
                email,
                password,
                this.state.selectedPlan
              );
              this.hideAlert();
            }}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Yes, add it!"
            cancelBtnText="Cancel"
            showCancel
          >
            $49/mth for an additional LI account.
            <br />
            <span style={{ fontSize: 15 }}>
              <em>
                Adding additional accounts ends the free trial ends immediately.{" "}
              </em>
            </span>
          </ReactBSAlert>
        ),
      });
    } else {
      this.props.requestAddAccount(email, password, this.state.selectedPlan);
    }
  };

  requestSendPIN = (pin) => {
    console.log(this.state.selectedPlan);
    this.props.requestSendPIN(
      this.email,
      this.password,
      pin,
      this.props.reLoginUrl,
      this.relogin,
      this.state.selectedPlan
    );
    // this.timeId = setTimeout(() => {
    //   this.showModal(true, false, false, false);
    // }, 1000);
  };

  requestReLogin = (email, password) => {
    this.email = email;
    this.password = password;
    this.relogin = true;
    this.setState({
      loadingReLogin: true,
    });
    this.props.requestReLogin(email, password, (action, message) => {
      if (action === "error") {
        this.setState({
          reloginError: message,
          loadingReLogin: false,
        });
      }
      if (action === "success") {
        this.setState({
          reloginError: "",
          loadingReLogin: false,
        });
      }
    });
  };

  hideAlert = () => {
    this.setState({ alert: null });
  };

  removeAccount = (_id) => {
    this.props.requestDeleteAccount(_id);
    this.hideAlert();
  };

  warningWithConfirmMessage = (_id) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={"Are you sure?"}
          onConfirm={() => {
            this.removeAccount(_id);
          }}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText={"Yes, delete it!"}
          cancelBtnText="Cancel"
          showCancel
        ></ReactBSAlert>
      ),
    });
  };

  gotoAdmin = (_id) => {
    const account = this.props.liaccounts.filter((acc) => acc._id === _id)[0];
    account.allMessageData = null;
    setLocalStorageData("liaccount", account);
    this.props.successGetAccountById(account);
    this.props.history.push(`/admin/${_id}`);
  };

  setAllowUpgrade = (allow, text) => {
    this.setState({ upgradeModal: allow, upgradeModalText: text });
  };

  render() {
    const {
      modal,
      pinModal,
      upgradeModal,
      upgradeModalText,
      reLoginModal,
      editLIAccountModal,
      sleepModal,
      selectedAccountID,
    } = this.state;
    const { liaccounts, loading, error, liFetched } = this.props;

    return (
      <>
        <LoadingOverlay
          active={!liFetched}
          spinner
          text="Loading Linkedin Accounts"
        ></LoadingOverlay>
        <div className="content accountlist">
          {this.props.sleepMode && (
            <Modal isOpen={true}>
              <ModalHeader>Your server is in sleep mode</ModalHeader>
              <ModalBody>
                <p>
                  If you need to create new campaign/sequence or send reply
                  messages, please change your working hours on the settings
                  page and wait a minute for the system to update.
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.props.resetError();
                      this.setState({ editLIAccountModal: false });
                    }}
                  >
                    Update Settings
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          )}

          {this.state.alert}
          <Row>
            <Col md="12">
              <h4 className="el-inline">LinkedIn Accounts &nbsp;</h4>
              <i
                className="nc-icon nc-alert-circle-i el-inline"
                id="tooltip-new-sub"
              ></i>
              <UncontrolledTooltip
                placement="right"
                delay={0}
                target="tooltip-new-sub"
              >
                A new subscription is created when you add a new LI account.
              </UncontrolledTooltip>

              <Row>
                {liaccounts.map((account, index) => (
                  <LIAccountCard
                    key={index}
                    {...account}
                    goToAccount={(_id) => this.gotoAdmin(_id)}
                    removeAccount={this.warningWithConfirmMessage}
                    toggleReLoginModal={() => {
                      this.toggleReLoginModal(account.email);
                    }}
                    toggleEditLIAccountModal={this.toggleEditLIAccountModal}
                    requestUpdateProfile={(email, profileLink) =>
                      this.props.requestUpdateProfile(email, profileLink)
                    }
                    setAllowUpgrade={(allow) => {
                      this.setState({ selectedAccountID: account._id });
                      this.setAllowUpgrade(
                        allow,
                        "You need to upgrade the plan to edit profile"
                      );
                    }}
                  />
                ))}
                {/* add new li account */}
                <Col className="col-sm-6 col-md-3">
                  <Card
                    className="liaccount-new"
                    onClick={() => {
                      // this.showModal(false, true, false);
                      this.setState({ selectedPlan: "MAXGROWTH_PLAN" });
                      this.showModal(false, false, true);
                    }}
                  >
                    <CardBody className="text-center">
                      <img
                        alt="missing"
                        className="round"
                        src={require("assets/img/placeholder.jpg")}
                        style={{ width: 60, borderRadius: 50, margin: 15 }}
                      />

                      <p className="text-muted">Connect Linkedin Account</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Modal isOpen={upgradeModal}>
            <UpgradeForm
              closeModal={() => this.showModal(false, false, false)}
              requestActivatePlan={this.props.requestActivatePlan}
              requestUpgradePlan={this.props.requestUpgradePlan}
              loading={planLoading}
              error={planError}
              subscriptionData={user}
            />
          </Modal> */}
          {
            <UpgradeModal
              show={upgradeModal}
              message={upgradeModalText}
              account={selectedAccountID}
              change={() => {
                this.setState({ upgradeModal: false });
              }}
            />
          }
          {
            <Modal
              isOpen={this.state.priceModal}
              className="price-modal-content"
            >
              <ModalHeader>Select your pricing plan</ModalHeader>
              <ModalBody>
                <Fragment>
                  {/* {this.shouldShpow() ? ( */}
                  <Row className="ml-3 mr-3 plans-page">
                    <Col md="4"></Col>
                    <Col
                      md="4"
                      onClick={() => {
                        this.setState({ selectedPlan: "MAXGROWTH_PLAN" });
                        this.showModal(false, false, true);
                      }}
                    >
                      <div className="card-pricing card">
                        <div className="card-header">
                          <h6 className="card-category">Maximum Growth</h6>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title">$49/mth</h3>
                          <p>
                            <small>per seat</small>
                          </p>
                          <ul>
                            <li>100 Connection Requets per day</li>
                            <li>200 Messages per day</li>
                            <li>All Pro Features +</li>
                            <li>
                              Download/Export Leads Data (email/phone, etc)
                            </li>
                            <li>Integrate your CRM/Zapier</li>
                            <li>
                              See{" "}
                              <a
                                target="_blank"
                                href="https://perfectprospect.io/pricing.html"
                              >
                                full plan details.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* ) : (
                  <Row className="ml-3 mr-3 plans-page">
                    <Col md="2"></Col>
                    <Col
                      md="4"
                      onClick={() => {
                        this.setState({ selectedPlan: "PROFESSIONAL_PLAN" });
                        this.showModal(false, false, true);
                      }}
                    >
                      <div className="card-pricing card">
                        <div className="card-header">
                          <h6 className="card-category">Personal</h6>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title">$9/mth</h3>
                          <p>
                            <small>per seat</small>
                          </p>
                          <ul>
                            <li>30 Invites & Messages per day</li>
                            <li>Smart Inbox & Reply Detection</li>
                            <li>Automatic Followup</li>
                            <li>Export Leads CSV</li>
                            <li>2 Active Campaigns</li>
                            <li>Limit 1 account per user</li>
                            <li>
                              See{" "}
                              <a
                                target="_blank"
                                href="https://perfectprospect.io/pricing.html"
                              >
                                full plan details.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    <Col
                      md="4"
                      onClick={() => {
                        this.setState({ selectedPlan: "MAXGROWTH_PLAN" });
                        this.showModal(false, false, true);
                      }}
                    >
                      <div className="card-pricing card">
                        <div className="card-header">
                          <h6 className="card-category">Maximum Growth</h6>
                        </div>
                        <div className="card-body">
                          <h3 className="card-title">$49/mth</h3>
                          <p>
                            <small>per seat</small>
                          </p>
                          <ul>
                            <li>100 Connection Requets per day</li>
                            <li>200 Messages per day</li>
                            <li>All Pro Features +</li>
                            <li>
                              Download/Export Leads Data (email/phone, etc)
                            </li>
                            <li>Integrate your CRM/Zapier</li>
                            <li>
                              See{" "}
                              <a
                                target="_blank"
                                href="https://perfectprospect.io/pricing.html"
                              >
                                full plan details.
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  )} */}
                </Fragment>
              </ModalBody>
            </Modal>
          }
          <Modal isOpen={modal}>
            <AddAccountForm
              closeModal={() => this.showModal(false, false, false)}
              requestAddAccount={this.requestAddAccount}
              loading={loading}
              error={error}
            />
          </Modal>
          {!error.message && (
            <Modal isOpen={pinModal}>
              <PinForm
                closeModal={() => this.setState({ pinModal: false })}
                requestSendPIN={this.requestSendPIN}
                error={error}
                loading={loading}
              />
            </Modal>
          )}
          {!error.message && (
            <Modal isOpen={reLoginModal}>
              <ReLoginForm
                reloginEmail={this.state.reloginEmail}
                closeModal={() => this.showModal(false, false, false)}
                requestReLogin={this.requestReLogin}
                error={this.state.reloginError}
                loading={this.state.loadingReLogin}
              />
            </Modal>
          )}
          <Modal
            isOpen={editLIAccountModal && !this.props.sleepMode}
            toggle={() => this.showModal(false, false, false, false)}
          >
            <EditLIInfoForm
              email={this.state.editLIAccountEmail}
              headline={this.state.editLIAccountHeadline}
              summary={this.state.editLIAccountSummary}
              closeModal={() => this.showModal(false, false, false, false)}
            />
          </Modal>
          <Modal
            isOpen={sleepModal}
            toggle={() =>
              this.showModal(false, false, false, false, false, true)
            }
          ></Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  liaccounts: state.liaccounts.liaccounts,
  loading: state.liaccounts.loading,
  liFetched: state.liaccounts.liFetched,
  error: state.liaccounts.error,
  reLoginUrl: state.liaccounts.reLoginUrl,
  user: state.authentication.user,
  planLoading: state.plans.loading,
  planError: state.plans.error,
  sleepMode: state.exception.sleepMode,
});

const mapDispatchToProps = (dispatch) => ({
  requestResetError: () => dispatch(requestResetError()),
  requestAddAccount: (email, password, plan) =>
    dispatch(requestAddAccount(email, password, plan)),
  requestSendPIN: (email, password, pin, reLoginUrl, relogin, plan) =>
    dispatch(requestSendPIN(email, password, pin, reLoginUrl, relogin, plan)),
  requestReLogin: (email, password, cb) =>
    dispatch(requestReLogin(email, password, cb)),
  requestActivatePlan: (planId) => dispatch(requestActivatePlan(planId)),
  requestUpgradePlan: (planId) => dispatch(requestUpgradePlan(planId)),
  requestDeleteAccount: (_id) => dispatch(requestDeleteAccount(_id)),
  setCampFetch: () => dispatch(setCampFetch()),
  setSeqFetch: () => dispatch(setSeqFetch()),
  requestUpdateProfile: (email, profileLink) =>
    dispatch(requestUpdateProfile(email, profileLink)),
  resetError: () => dispatch(resetError()),
  successGetAccountById: (account) => dispatch(successGetAccountById(account)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(LIAccountPage))
);
