// Campaigns Page
// this was added just to clear up the dev console. Pls fix warnings if its possible

import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setLocalStorageData } from "../../utils/localStorageService";
import Slider from "react-input-slider";
import { validator } from "utils";

// reactstrap components
import {
  Button,
  FormGroup,
  CardBody,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Input,
  UncontrolledTooltip,
  InputGroup,
  FormFeedback,
} from "reactstrap";

import InputRange from "react-input-range";
import LoadingOverlay from "react-loading-overlay";
import timezones from "timezones.json";

import "react-input-range/lib/css/index.css";

import { liaccountActions } from "../../actions";
import { originToutc, isSafari } from "../../utils";

import ReactSelect from "react-select";

import TimePicker from "react-bootstrap-time-picker";
import UpgradeModal from "./../components/UpgradeModal";

const { requestChangeSettings, requestEmailCredSave } = liaccountActions;

const MySelect = ({ options, value, ...props }) => {
  const labelValue = options.filter((opt) => {
    return opt.value === value;
  });
  return <ReactSelect options={options} value={labelValue} {...props} />;
};

class SettingsPage extends React.Component {
  freqKeys = [
    { title: "Profile views", key: "profileViews" },
    // { title: "Follows", key: "follows" },
    { title: "Invitations", key: "invitations" },
    { title: "Messages", key: "messages" },
    { title: "Inmails", key: "inmails" },
  ];
  emailProviders = [
    {
      label: "Google Workspace",
      value: "google_workplace",
    },
    {
      label: "Microsoft 365",
      value: "microsoft_365",
    },
  ];

  state = {
    freqRates: {
      profileViews: {
        min: 15,
        max: 30,
      },
      follows: {
        min: 15,
        max: 30,
      },
      invitations: {
        min: 15,
        max: 30,
      },
      messages: {
        min: 15,
        max: 30,
      },
      inmails: {
        min: 15,
        max: 30,
      },
    },
    timeSettings: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      workingHours: {
        min: "15:04",
        max: "02:00",
      },
    },
    fbuser: "",
    fbpass: "",
    invCleanerCounts: 1200,
    skipWeekends: true,
    autoSync: true,
    isScrolled: null,
    subscriptionModel: false,
    upgradeModalText: "",
    provider: {
      label: "Google Workspace",
      value: "google_workplace",
    },
    smtpEmail: "",
    smtpPassword: "",
    smtpFirstName: "",
    smtpLastName: "",
  };
  changeEmail = (e) => {
    this.setState({ smtpEmail: e.target.value });
  };
  changePassword = (e) => {
    this.setState({ smtpPassword: e.target.value });
  };
  changeSenderFirstName = (e) => {
    this.setState({ smtpFirstName: e.target.value });
  };
  changeSenderLastName = (e) => {
    this.setState({ smtpLastName: e.target.value });
  };
  fetchSettings = () => {
    const { liaccount } = this.props;

    if (liaccount) {
      const settings = liaccount.settings;
      const emailData = liaccount.emailData ? liaccount.emailData : {};
      const setKeys = ["VISIT", "CONNECT", "MESSAGE", "INMAIL"];
      const freqRates = {};

      setKeys.forEach((setKey, index) => {
        const freqKey = this.freqKeys[index].key;
        freqRates[freqKey] = {};
        freqRates[freqKey].min = settings[setKey] ? settings[setKey][0] : 15;
        freqRates[freqKey].max = settings[setKey] ? settings[setKey][1] : 30;
      });

      const workingHours = settings.workingHours;
      const localZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeSettings = {
        timezone: settings.timezone ? settings.timezone : localZone,
        workingHours: {
          min: settings.timezone
            ? `${workingHours[0]
                .toString()
                .padStart(2, "0")}:${workingHours[1]
                .toString()
                .padStart(2, "0")}`
            : originToutc("06:00", localZone),
          max: settings.timezone
            ? `${workingHours[2]
                .toString()
                .padStart(2, "0")}:${workingHours[3]
                .toString()
                .padStart(2, "0")}`
            : originToutc("20:00", localZone),
        },
      };

      const invCleanerCounts = settings.invCleanerCounts
        ? settings.invCleanerCounts
        : 1200;
      const fbuser = settings.fbuser ? settings.fbuser : "";
      const fbpass = settings.fbpassword ? settings.fbpass : "";

      this.setState({
        freqRates,
        timeSettings,
        skipWeekends: settings.excludeWeekend,
        autoSync: settings.autoSync === undefined ? false : settings.autoSync,
        invCleanerCounts,
        fbuser,
        fbpass,
      });
    }
  };

  saveSettings = () => {
    const {
      freqRates,
      timeSettings,
      skipWeekends,
      autoSync,
      fbuser,
      fbpass,
      invCleanerCounts,
    } = this.state;
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);

    // if (privacy.PROFILE_VIEW > -1 && freqRates.profileViews.max > privacy.PROFILE_VIEW) {
    //   return this.setState({
    //     subscriptionModel: true
    //   })
    // }
    if (
      privacy.INVITATIONS > -1 &&
      freqRates.invitations.max > privacy.INVITATIONS
    ) {
      this.setState({
        upgradeModalText:
          "Please upgrade the plan to exceed your invitation limits",
      });
      return this.setState({
        subscriptionModel: true,
      });
    }
    if (privacy.MESSAGES > -1 && freqRates.messages.max > privacy.MESSAGES) {
      this.setState({
        upgradeModalText:
          "Please upgrade the plan to exceed your message limits",
      });
      return this.setState({
        subscriptionModel: true,
      });
    }
    if (privacy.INMAILS > -1 && freqRates.inmails.max > privacy.INMAILS) {
      this.setState({
        upgradeModalText:
          "Please upgrade the plan to exceed your inmail limits",
      });
      return this.setState({
        subscriptionModel: true,
      });
    }

    const workingHours = timeSettings.workingHours.min
      .split(":")
      .map((str) => parseInt(str))
      .concat(
        timeSettings.workingHours.max.split(":").map((str) => parseInt(str))
      );

    const settings = {
      workingHours,
      timezone: timeSettings.timezone,
      excludeWeekend: skipWeekends,
      autoSync: autoSync,
      fbuser,
      fbpass,
      invCleanerCounts,
    };

    const setKeys = ["VISIT", "CONNECT", "MESSAGE", "INMAIL"];
    setKeys.forEach((setKey, index) => {
      const freqKey = this.freqKeys[index].key;
      settings[setKey] = [freqRates[freqKey].min, freqRates[freqKey].max];
    });

    const { liaccount } = this.props;

    const _id = liaccount._id;
    liaccount.allMessageData = null;
    setLocalStorageData("liaccount", {
      ...liaccount,
      settings,
    });
    this.props.requestChangeSettings(_id, settings);
  };
  saveEmailCred = () => {
    const { liaccount } = this.props;

    const _id = liaccount._id;

    const {
      provider,
      smtpEmail,
      smtpPassword,
      smtpFirstName,
      smtpLastName,
    } = this.state;
    const emailData = {
      provider: provider.value,
      smtpEmail,
      smtpPassword,
      smtpFirstName,
      smtpLastName,
    };
    this.props.requestEmailCredSave(_id, emailData);
  };
  componentDidMount() {
    this.fetchSettings();
  }

  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }

    if (!prevProps.liaccount) {
      this.fetchSettings();
    }
    if (
      this.props.liaccount &&
      this.props.liaccount.emailData &&
      !this.state.smtpEmail
    ) {
      const emailData = this.props.liaccount.emailData;
      if (emailData.smtpEmail || emailData.email)
        this.setState({
          provider:
            emailData.provider === "google_workplace"
              ? {
                  label: "Google Workspace",
                  value: "google_workplace",
                }
              : {
                  label: "Google Workspace",
                  value: "google_workplace",
                },
          smtpEmail: emailData.email || emailData.smtpEmail,
          smtpPassword: emailData.password || emailData.smtpPassword,
          smtpFirstName: emailData.sender_first || emailData.smtpFirstName,
          smtpLastName: emailData.sender_last || emailData.smtpLastName,
        });
    }
  }
  componentWillUnmount() {
    this.setState({
      isScrolled: null,
    });
  }

  changeFreqRates = (key) => (value) => {
    if (value.max - value.min < 5) {
      return;
    }
    const { freqRates } = this.state;
    freqRates[key] = value;
    this.setState({ ...this.state, freqRates });
  };

  changeTimeZone = (option) => {
    const { timeSettings } = this.state;
    timeSettings.timezone = option.value;
    this.setState({ ...this.state, timeSettings });
  };

  changeEmailProvider = (option) => {
    const selected = option;
    this.setState({ provider: selected });
  };

  changeWorkingHours = (type) => ({ target: { value: time } }) => {
    // console.log("changeWorkingHours -->", time);
    const { timeSettings } = this.state;
    timeSettings.workingHours[type] = originToutc(time, timeSettings.timezone);
    this.setState({ ...this.state, timeSettings });
  };

  changeWorkingHoursForSafari = (type) => (target) => {
    // console.log("changeWorkingHours --->", type, target);
    const { timeSettings } = this.state;
    const tminutes = target / 60;
    const hours = ("0" + Math.floor(tminutes / 60)).slice(-2);
    const minus = ("0" + (tminutes % 60)).slice(-2);
    // console.log(`${hours}:${minus}`);
    timeSettings.workingHours[type] = originToutc(
      `${hours}:${minus}`,
      timeSettings.timezone
    );
    this.setState({ ...this.state, timeSettings });
  };

  changeInvitationLimits = (value) => {
    this.setState({ invCleanerCounts: value.x });
  };

  changeFBUser = (e) => {
    this.setState({ fbuser: e.target.value });
  };

  changeFBPass = (e) => {
    this.setState({ fbpass: e.target.value });
  };

  toggleSkipWeekends = (e) => {
    this.setState({ ...this.state, skipWeekends: !this.state.skipWeekends });
  };

  toggleAutoSync = () => {
    this.setState({ ...this.state, autoSync: !this.state.autoSync });
  };

  render() {
    const {
      freqRates,
      timeSettings,
      skipWeekends,
      autoSync,
      error,
    } = this.state;
    const { timezone, workingHours } = timeSettings;
    const { loading } = this.props;
    return (
      <>
        <LoadingOverlay
          active={loading}
          spinner
          text="Loading/Saving settings"
        ></LoadingOverlay>
        <div className="content accountlist">
          {
            <UpgradeModal
              show={this.state.subscriptionModel}
              account={this.props.match.params.account}
              message={this.state.upgradeModalText}
              change={() => {
                this.setState({
                  subscriptionModel: !this.state.subscriptionModel,
                });
              }}
            />
          }
          <Row>
            <Col md="12">
              <h3>Account Settings</h3>
              <Card style={{ boxShadow: "none" }}>
                <CardHeader>
                  <h4>Daily rate of frequency</h4>
                  <span className="el-inline">
                    <a
                      href="https://help.perfectprospect.io/portal/en/kb/articles/account-settings-recommendations"
                      target="_blank"
                      className="text-muted"
                      rel="noopener noreferrer"
                    >
                      What settings should I use?{" "}
                    </a>
                    <i
                      className="nc-icon nc-bulb-63 el-inline"
                      id="tooltip-settings"
                    ></i>
                  </span>

                  <UncontrolledTooltip
                    placement="right"
                    delay={0}
                    target="tooltip-settings"
                  >
                    Send rates should only be increased gradually. Please read
                    our settings guide to learn best practices for rates and
                    limits.
                  </UncontrolledTooltip>
                </CardHeader>
                <CardBody style={{ padding: "0px 40px" }}>
                  {this.freqKeys.map(({ title, key }) => (
                    <Row key={key}>
                      <h6
                        style={{
                          width: 200,
                          marginTop: 40,
                        }}
                      >
                        {title}
                      </h6>
                      <div
                        className="col-md-8 col-sm-12 ml-auto mr-auto"
                        style={{ marginTop: 40 }}
                      >
                        <InputRange
                          maxValue={50}
                          minValue={0}
                          value={freqRates[key]}
                          onChange={this.changeFreqRates(key)}
                        />
                      </div>
                    </Row>
                  ))}
                </CardBody>
                <CardFooter></CardFooter>
                <CardHeader>
                  <h4>Time & auto-sync</h4>
                </CardHeader>
                <CardBody style={{ padding: "0px 40px" }}>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Time zone
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 20 }}
                    >
                      <MySelect
                        className="react-select primary"
                        classNamePrefix="react-select"
                        name="timezone"
                        value={timezone}
                        onChange={this.changeTimeZone}
                        options={timezones
                          .map((timezone) =>
                            timezone.utc.map((utc) => ({
                              label: `${timezone.text} - ${utc}`,
                              value: utc,
                            }))
                          )
                          .flat()}
                        placeholder="Select the timezone"
                      />
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Working hours
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!isSafari() && (
                        <input
                          value={workingHours.min}
                          type="time"
                          onChange={this.changeWorkingHours("min")}
                        ></input>
                      )}
                      {isSafari() && (
                        <TimePicker
                          value={workingHours.min}
                          onChange={this.changeWorkingHoursForSafari("min")}
                          step={1}
                        />
                      )}
                      <h6 className="el-inline" style={{ margin: "0 20px" }}>
                        to
                      </h6>
                      {isSafari() && (
                        <TimePicker
                          value={workingHours.max}
                          onChange={this.changeWorkingHoursForSafari("max")}
                          step={1}
                        />
                      )}
                      {!isSafari() && (
                        <input
                          value={workingHours.max}
                          type="time"
                          onChange={this.changeWorkingHours("max")}
                        ></input>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                      }}
                    >
                      &nbsp;
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 10 }}
                    >
                      <FormGroup check className="mt-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={skipWeekends}
                              type="checkbox"
                              onChange={this.toggleSkipWeekends}
                            />
                            Skip weekends <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row>
                    <h6
                      style={{
                        width: 200,
                      }}
                    >
                      &nbsp;
                    </h6>
                    <div className="col-md-8 col-sm-12 ml-auto mr-auto">
                      <p>
                        NOTE: Changes to the account settings will not affect
                        tasks that have already been scheduled for execution.
                      </p>
                    </div>
                  </Row>
                  {/* <Row>
                    <h6
                      className="d-flex"
                      style={{
                        width: 200,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      Auto-Sync Googlesheets
                      <i
                        className="nc-icon nc-alert-circle-i ml-2"
                        id={`auto-sync-info`}
                      />
                      <UncontrolledTooltip
                        placement="right"
                        delay={0}
                        target={`auto-sync-info`}
                      >
                        Automatically sync your Googlesheet export with the
                        latest data from our DB.
                      </UncontrolledTooltip>
                    </h6>
                    <div className="col-md-8 col-sm-12 ml-auto mr-auto">
                      <FormGroup check className="mt-3">
                        <FormGroup check>
                          <Label check>
                            <Input
                              checked={autoSync}
                              onChange={this.toggleAutoSync}
                              type="checkbox"
                            />
                            Enable Auto-Sync
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </div>
                  </Row> */}
                </CardBody>
                <CardFooter></CardFooter>
                <CardHeader>
                  <h4>Pending invitations</h4>
                </CardHeader>
                <CardBody>
                  <p className="text-muted">
                    By default our system will purge oldest pending invitations
                    once daily to ensure the total is{" "}
                    <strong>less than 1200</strong>. Adjust this value to the
                    number of pending invitations you want. We will remove the
                    earliest invitations according to this limit.
                  </p>
                  <Row key={"invitation"}>
                    <h6
                      style={{
                        width: 190,
                        marginTop: 40,
                        marginLeft: 30,
                      }}
                    >
                      {"Pending invite limit"}
                    </h6>
                    <div
                      className="col-md-8 col-sm-12 ml-auto mr-auto"
                      style={{ marginTop: 40 }}
                    >
                      <div>{this.state.invCleanerCounts}</div>

                      <Slider
                        xmax={1500}
                        xmin={300}
                        x={this.state.invCleanerCounts}
                        onChange={this.changeInvitationLimits}
                      />
                    </div>
                  </Row>
                </CardBody>
                <div
                  className="col-md-12 col-sm-12 ml-auto mr-auto"
                  style={{ marginTop: 10 }}
                >
                  <Button
                    style={{ width: 130 }}
                    color="primary"
                    onClick={() => this.saveSettings()}
                  >
                    Save
                  </Button>
                  <Button onClick={() => this.fetchSettings()}>Reset</Button>
                </div>
              </Card>
              {/* <h3>Email Settings</h3>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <div
                    className="col-sm-12 ml-auto mr-auto"
                    style={{ marginTop: 20 }}
                  >
                    <Row>
                      <Col md="6" xs="12">
                        <span>EMAIL PROVIDER </span>
                        <MySelect
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="emailProvider"
                          value={this.state.provider.value}
                          onChange={this.changeEmailProvider}
                          options={this.emailProviders}
                          placeholder="Select Email Provider"
                        />
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col md="6" xs="12">
                        {this.state.provider.value === "google_workplace" && (
                          <span className="el-inline">
                            <a
                              href="https://help.perfectprospect.io/portal/en/kb/articles/using-the-email-followup"
                              target="_blank"
                              className="text-muted"
                              rel="noopener noreferrer"
                            >
                              Learn how to configure Gmail{" "}
                            </a>
                            <i
                              className="nc-icon nc-bulb-63 el-inline"
                              id="tooltip-settings"
                            ></i>
                          </span>
                        )}
                        {this.state.provider.value === "microsoft_365" && (
                          <span className="el-inline">
                            <a
                              href="https://help.perfectprospect.io/portal/en/kb/articles/using-the-email-followup"
                              target="_blank"
                              className="text-muted"
                              rel="noopener noreferrer"
                            >
                              Learn how to configure Microsoft 365{" "}
                            </a>
                            <i
                              className="nc-icon nc-bulb-63 el-inline"
                              id="tooltip-settings"
                            ></i>
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Card style={{ boxShadow: "none", marginTop: 20 }}>
                      <Row>
                        <Col md="6" xs="12">
                          <span>Email</span>
                          <InputGroup>
                            <Input
                              value={this.state.smtpEmail}
                              id="email_address"
                              onChange={(e) => this.changeEmail(e)}
                              onClick={(e) => this.changeEmail(e)}
                              placeholder=""
                              invalid={validator.checkRequiredValidation(
                                this.state.smtpEmail
                              )}
                            />
                            <FormFeedback>
                              {validator.checkRequiredValidation(
                                this.state.email
                              )
                                ? "Email is Required"
                                : ""}
                            </FormFeedback>
                          </InputGroup>
                        </Col>
                        <Col md="6" xs="12"></Col>
                      </Row>

                      <Row>
                        <Col md="6" xs="12">
                          <span>Password</span>
                          <InputGroup>
                            <Input
                              value={this.state.smtpPassword}
                              id="emaPasswordil_address"
                              type="password"
                              onChange={(e) => this.changePassword(e)}
                              onClick={(e) => this.changePassword(e)}
                              placeholder=""
                              invalid={validator.checkRequiredValidation(
                                this.state.smtpPassword
                              )}
                            />
                            <FormFeedback>
                              {validator.checkRequiredValidation(
                                this.state.email
                              )
                                ? "Password is Required"
                                : ""}
                            </FormFeedback>
                          </InputGroup>
                        </Col>{" "}
                        <Col md="6" xs="12"></Col>{" "}
                      </Row>
                      <Row>
                        <Col md="6" xs="12">
                          <span>Sender First Name</span>
                          <InputGroup>
                            <Input
                              value={this.state.smtpFirstName}
                              id="firstName"
                              onChange={(e) => this.changeSenderFirstName(e)}
                              onClick={(e) => this.changeSenderFirstName(e)}
                              placeholder=""
                              invalid={validator.checkRequiredValidation(
                                this.state.smtpFirstName
                              )}
                            />
                            <FormFeedback>
                              {validator.checkRequiredValidation(
                                this.state.smtpFirstName
                              )
                                ? "Sender First Name is Required"
                                : ""}
                            </FormFeedback>
                          </InputGroup>
                        </Col>{" "}
                        <Col md="6" xs="12"></Col>
                      </Row>
                      <Row>
                        <Col md="6" xs="12">
                          <span>Sender Last Name</span>
                          <InputGroup>
                            <Input
                              value={this.state.smtpLastName}
                              id="lastname"
                              onChange={(e) => this.changeSenderLastName(e)}
                              onClick={(e) => this.changeSenderLastName(e)}
                              placeholder=""
                              invalid={validator.checkRequiredValidation(
                                this.state.smtpLastName
                              )}
                            />
                            <FormFeedback>
                              {validator.checkRequiredValidation(
                                this.state.smtpLastName
                              )
                                ? "Sender Last Name is Required"
                                : ""}
                            </FormFeedback>
                          </InputGroup>
                        </Col>{" "}
                        <Col md="6" xs="12"></Col>
                      </Row>
                      <Row>
                        <div
                          className="col-md-12 col-sm-12 ml-auto mr-auto"
                          style={{ marginTop: 10 }}
                        >
                          <Button
                            style={{ width: 130 }}
                            color="primary"
                            onClick={() => this.saveEmailCred()}
                          >
                            Save
                          </Button>
                          {error && (
                            <InputGroup>
                              <Input hidden invalid />
                              <FormFeedback>{error}</FormFeedback>
                            </InputGroup>
                          )}
                        </div>
                      </Row>
                    </Card>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  loading: state.liaccounts.loading,
  error: state.liaccounts.error,
});

const mapDispatchToProps = (dispatch) => ({
  requestChangeSettings: (_id, settings) =>
    dispatch(requestChangeSettings(_id, settings)),

  requestEmailCredSave: (_id, emailData) =>
    dispatch(requestEmailCredSave(_id, emailData)),
});

export default withRouter(
  compose(connect(mapStateToProps, mapDispatchToProps)(SettingsPage))
);
