import { types } from "../actions";

const { NOTIFY: ACTION_HEADER } = types;

const initialState = {
    code: 400,
    type: "",
    message: "",
    isShowing: false
};

function notifyReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_HEADER.API_ERROR: {
            return {
                ...state,
                code: action.error.code,
                type: action.error.type,
                message: action.error.msg,
                isShowing: true,
            };
        }
        case ACTION_HEADER.HIDE_NOTIFY: {
            return {
                ...state,
                isShowing: false,
            };
        }
        default: {
            return state;
        }
    }
}

export default notifyReducer;
