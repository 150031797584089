import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

// reactstrap components
import {
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Card,
  CardBody,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import CampaignCard from "../../components/campaigns/CampaignCard";

import { campaignTypes } from "../../../utils";
import { campaignActions, errorActions } from "../../../actions";
import { Button } from "react-bootstrap";
import UpgradeModal from "views/components/UpgradeModal";

const { requestCreateCampaign } = campaignActions;
const { resetError } = errorActions;

const SequenceAddEditForm = ({
  sequences,
  error,
  loading,
  requestCreateCampaign,
  liaccount,
  campaigns,
  sleepMode,
  resetError,
  ...restProps
}) => {
  const [type, setType] = useState("SALES_NAVIGATOR");
  const [planType, setPlanType] = useState("");
  const [needUpgrade, setNeedUpgrade] = useState(false);
  const saveCampaign = (values) => {
    const { account } = restProps.match.params;
    requestCreateCampaign(
      { ...values, liEmail: liaccount.email, type },
      account,
      liaccount.connectionData,
      liaccount.repliedData
    );
  };

  useEffect(() => {
    let privacyStr = localStorage.getItem("privacy");
    let privacy = JSON.parse(privacyStr);
    setPlanType(privacy.PLAN);
  });

  const goBack = (e) => {
    e.preventDefault();
    const { account } = restProps.match.params;
    restProps.history.push(`/admin/${account}/campaigns`);
  };

  return (
    <div className="content campaignlist">
      {sleepMode && (
        <Modal isOpen={true}>
          <ModalHeader>Your server is in sleep mode</ModalHeader>
          <ModalBody>
            <p>
              If you need to create new campaign/sequence or send reply
              messages, please change your working hours on the settings page
              and wait a minute for the system to update.
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="primary" onClick={() => resetError()}>
                Cancel
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  resetError();
                  restProps.history.push(
                    `/admin/${restProps.match.params.account}/settings`
                  );
                }}
              >
                Update Settings
              </Button>
            </div>
          </ModalBody>
        </Modal>
      )}
      <UpgradeModal
        show={needUpgrade}
        change={() => {
          setNeedUpgrade(false);
        }}
      />
      <Col xs="12" xl="9" className="ml-auto mr-auto">
        <h4 className="el-inline">Campaign Builder</h4>
        <Card className="campaign-builder-card">
          <CardBody>
            <Row>
              <Col lg="4" md="5" sm="4" xs="6">
                <div className="nav-tabs-navigation verical-navs">
                  <div className="nav-tabs-wrapper">
                    <Nav
                      className="flex-column nav-stacked"
                      role="tablist"
                      tabs
                    >
                      {campaignTypes.map((campaignType) => (
                        <NavItem key={campaignType.key}>
                          <NavLink
                            data-toggle="tab"
                            href="#"
                            role="tab"
                            disabled={campaignType.disabled}
                            className={
                              type === campaignType.key ? "active" : ""
                            }
                            onClick={() => {
                              if (
                                planType === "FREE_PLAN" &&
                                (campaignType.key === "CUSTOM" ||
                                  campaignType.key === "FOLLOWERS")
                              )
                                setNeedUpgrade(true);
                              else setType(campaignType.key);
                            }}
                          >
                            {campaignType.title}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col lg="8" md="7" sm="8" xs="6">
                <TabContent activeTab={type}>
                  {campaignTypes.map(({ key }) => (
                    <TabPane tabId={key} key={key}>
                      <CampaignCard
                        key={key}
                        type={key}
                        sequences={sequences}
                        campaigns={campaigns}
                        goBack={goBack}
                        saveCampaign={saveCampaign}
                      />
                    </TabPane>
                  ))}
                </TabContent>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </div>
  );
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  sequences: state.sequences.sequences,
  campaigns: state.campaigns.campaigns,
  sleepMode: state.exception.sleepMode,
});

const mapDispatchToProps = (dispatch) => ({
  requestCreateCampaign: (campaign, account, connectionData, repliedData) =>
    dispatch(
      requestCreateCampaign(campaign, account, connectionData, repliedData)
    ),
  resetError: () => dispatch(resetError()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(SequenceAddEditForm)
);
