import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Card, CardBody, Row, Col } from "reactstrap";
import ReactTable from "react-table";
import ProspectContactInfoModal from "./LinkedinInfoModal";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { adminActions } from "../../../actions";
import LoadingOverlay from "react-loading-overlay";
const { requestUserInformation } = adminActions;

const Users = (props) => {
  // console.log(props);
  const { userList, loading } = props;
  // const [selectedCategory, setSelectedCategory] = useState("");
  const [users, setUsers] = useState([]);
  const [prospectInfoModel, setProspectInfoModel] = useState(false);
  const [openProspectInfo, setOpenProspectInfo] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (userList.data) {
      setUsers(userList.data.userData);
    }
  }, [userList, users]);

  const setProspectInfoToggle = (e, data) => {
    // console.log("e", e)
    if (e && e.currentTarget) e.preventDefault();
    if (data) {
      setOpenProspectInfo({
        title: data.name,
        ...data,
      });
    }

    setProspectInfoModel(!prospectInfoModel);
  };
  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    // console.log(id)
    // console.log(row)
    // console.log(row[id])

    if (row[id]) {
      const orgStringArray = row[id].toLowerCase().split(" ");
      const filterString = filter.value.toLowerCase();
      for (let i in orgStringArray) {
        if (orgStringArray.slice(i).join(" ").startsWith(filterString)) {
          return true;
        }
      }

      return false;
    }
    return true;
  };
  const loginToUser = (a, b) => {
    Cookies.remove("BRJWT");
    Cookies.set("BRJWT", `Bearer ${b}`);
    window.location.reload();
    window.location.href = "/admin";
  };
  const checkUser = (a, b) => {
    console.log(a, b);
    dispatch(
      requestUserInformation({
        userId: a,
        email: b,
      })
    );
    // Cookies.remove("BRJWT");
    // Cookies.set("BRJWT", `Bearer ${b}`);
    // window.location.reload();
    // window.location.href = "/admin";
  };

  const userData =
    users &&
    users.map((seq) => ({
      email: seq.email,
      linkedins: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex", color: "#007bff" }}
          key={step.name + index}
        >
          <a
            className="el-inline"
            href="/"
            onClick={(e) => setProspectInfoToggle(e, step)}
            style={{ display: "flex", color: "#007bff" }}
          >
            {step.name}
          </a>
        </div>
      )),
      billing: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex" }}
          key={step.name + index}
        >
          <span className="el-inline" style={{ color: "green" }}>
            {step.billDate > Date.now() / 1000 ? "Active" : "Expired"}
          </span>
          <span className="el-inline">
            ({step.isSubscribed ? " subscribed " : " canceled "})
          </span>
        </div>
      )),
      status: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex" }}
          key={step.name + index}
        >
          <span className="el-inline" style={{ color: "blue" }}>
            {step.reLoginRequired ? "Disconnected" : "Normal"}
          </span>
        </div>
      )),
      plan: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex" }}
          key={step.name + index}
        >
          <span className="el-inline" style={{ color: "black" }}>
            {step.isTrial ? "Trial" : step.isBasicPlan ? "$49" : "$99"}
          </span>
          <span className="el-inline" style={{ color: "black" }}>
            {step.isTrial ? (step.isBasicPlan ? " ($49) " : "($99)") : ""}
          </span>
        </div>
      )),
      a_date: seq.linkedinData.map((step, index) => (
        <div
          className="el-inline"
          style={{ display: "flex" }}
          key={step.name + index}
        >
          <span className="el-inline" style={{ color: "black" }}>
            {moment(step.billDate * 1000).format("DD-MM-YYYY")}
          </span>
        </div>
      )),
      stripeId: <span> {seq.customerId}</span>,
      createdAt: <span> {seq.registered.substring(0, 10)}</span>,
      actions: (
        <div className="">
          <Button
            color="danger"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) => this.warningWithConfirmMessage(e, seq.identifier)}
          >
            <i className="fa fa-times" />
          </Button>
          <Button
            color="primary"
            size="md"
            id={"tooltip-clone-btn-" + seq._id}
            className="btn-icon btn-default btn-link"
            onClick={(e) => this.navigateToSequence(e, seq.identifier, "copy")}
          >
            <i className="fa fa-clipboard" />
          </Button>
        </div>
      ),
      login: seq.ids ? (
        <div className="">
          <Button
            color="danger"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) => loginToUser(e, seq.ids)}
          >
            <i className="fa fa-sign-in" />
          </Button>
        </div>
      ) : (
        <div></div>
      ),
      details: (
        <div className="">
          <Button
            color="success"
            size="md"
            className="btn-icon btn-default btn-link remove"
            onClick={(e) => checkUser(seq.userId, seq.email)}
          >
            {" "}
            <i className="fa fa-list-alt " />
          </Button>
        </div>
      ),
    }));

  return (
    <div>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading..."
      ></LoadingOverlay>
      <ProspectContactInfoModal
        setProspectInfoToggle={setProspectInfoToggle}
        prospectInfoModel={prospectInfoModel}
        openProspectInfo={openProspectInfo}
      />
      <div className="d-flex">
        <Row style={{ width: "100%" }}>
          <Col md="12">
            <h3 className="el-inline">User List</h3>
            <Card>
              <CardBody>
                <ReactTable
                  data={userData}
                  resizable={true}
                  filterable
                  columns={[
                    {
                      Header: "Email",
                      accessor: "email",
                      sortable: false,
                      filterable: true,
                      width: 320,
                    },
                    {
                      Header: "Access",
                      accessor: "login",
                      sortable: false,
                      filterable: false,
                      width: 80,
                    },
                    {
                      Header: "Customer ID",
                      accessor: "stripeId",
                      sortable: false,
                      filterable: false,
                      width: 150,
                    },
                    {
                      Header: "Joined",
                      accessor: "createdAt",
                      sortable: true,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Details",
                      accessor: "details",
                      sortable: false,
                      filterable: false,
                      width: 80,
                    },
                    {
                      Header: "Linkedins",
                      accessor: "linkedins",
                      sortable: false,
                      filterable: false,
                      width: 200,
                    },
                    {
                      Header: "Billing",
                      accessor: "billing",
                      sortable: false,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Status",
                      accessor: "status",
                      sortable: false,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Plan",
                      accessor: "plan",
                      sortable: false,
                      filterable: false,
                      width: 100,
                    },
                    {
                      Header: "Available Date",
                      accessor: "a_date",
                      sortable: false,
                      filterable: false,
                    },
                  ]}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom
                  defaultFilterMethod={filterMethod}
                  /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
                  className=" -highlight sequence-manager-table"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Users;
