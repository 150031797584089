import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";

import Message from "./Message";
import SendMessageBlock from "./SendMessageBlock";

import {
  requestMessageSend,
  unreadMessage,
} from "../../../../actions/messageActions";

const CampaignProspectsMessagingModal = (props) => {
  const { target, liaccount, toggleAssignModal, showContactIcon, onContactInfoModelIconClick } = props;
  const dispatch = useDispatch();
  const [firstOpen, setFirstOpen] = useState(true);
  const [imageError, setImageError] = useState(false);
  const messagesEndRef = useRef(null);

  const [messagesToDisplay, setMessagesToDisplay] = useState([]);
  const [useCred, setUseCred] = useState(false);
  const [subject, setSubject] = useState("");
  const [inputValue, setInputValue] = useState("");

  let isSales = true;
  let isAccepted = false;
  let isInMail = false;

  const { imageurl, name } = target;
  isInMail = target.actions.indexOf("INMAIL") > -1;
  const filteredTargetMessages = target.messaged;
  const msgSalesLink = filteredTargetMessages[0]?.salesLink !== "" ? filteredTargetMessages[0]?.salesLink : target.salesLink;

  if (filteredTargetMessages.length === 1) {
    isSales = filteredTargetMessages[0].isSales;
    isAccepted = filteredTargetMessages[0].isAccepted;
  } else if (filteredTargetMessages.length === 2) {
    const salesMessage = filteredTargetMessages.filter(
      (item) => item.isSales
    )[0];
    isSales = salesMessage
      ? salesMessage.isSales
      : filteredTargetMessages[0].isSales;
    isAccepted = salesMessage
      ? salesMessage.isAccepted
      : filteredTargetMessages[0].isAccepted;
  }

  const handleSendMessage = () => {
    setSubject("");
    dispatch(
      requestMessageSend(
        liaccount.email,
        inputValue,
        msgSalesLink,
        name,
        isSales,
        isAccepted ? true : useCred,
        isInMail,
        subject
      )
    );

    setMessagesToDisplay((prev) => [
      ...prev,
      {
        msg: inputValue,
        sender: "You",
        msgTime: moment().unix(),
      },
    ]);
    setInputValue("");
  };

  useEffect(() => {
    if (filteredTargetMessages.length === 1) {
      setMessagesToDisplay(filteredTargetMessages[0].messages);
    } else if (filteredTargetMessages.length === 2) {
      const salesMessage = filteredTargetMessages.filter(
        (item) => item.isSales
      )[0];
      setMessagesToDisplay(
        salesMessage
          ? salesMessage.messages
          : filteredTargetMessages[0].messages
      );
    }
  }, [filteredTargetMessages]);

  useEffect(() => {
    if (messagesEndRef.current) {
      // messagesEndRef.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end",
      // });
      messagesEndRef.current.parentNode.scrollTop = messagesEndRef.current.offsetTop;
    }
  }, [messagesToDisplay]);

  const handleSubjectChange = (e) => {
    e.preventDefault();
    setSubject(e.target.value);
  };

  const handleUseCredChange = () => {
    setUseCred((prev) => !prev);
  };

  const markUnRead = () => {
    dispatch(unreadMessage(target.messaged[0]._id));
  };

  const renderTags = (tags) => {
    const badgeColors = {
      Replied: "success",
      Accepted: "info",
      Visited: "danger",
      Requested: "dull-orange",
      Messaged: "warning",
    };
    return (
      <div className="activity-badges">
        {tags &&
          tags.map((tag, index) => (
            <div key={`badge-${index}`} className="el-inline">
              <span
                className={`badge badge-${badgeColors[tag] ? badgeColors[tag] : "primary"
                  } badge-pill`}
              >
                {tag}
              </span>
            </div>
          ))}
        {target.customTags &&
          target.customTags.map((tag) => (
            <div key={`badge-${tag._id}`} className="el-inline">
              <span
                className={`badge badge-${tag.color ? tag.color : "primary"
                  } badge-pill`}
              >
                {tag.title}
              </span>
            </div>
          ))}
        {toggleAssignModal && (
          <div
            className="el-inline"
            style={{ cursor: "pointer" }}
            onClick={() =>
              toggleAssignModal(
                target._id,
                target.tags,
                target.campaignId,
                target.targetIndex
              )
            }
          >
            <span className="badge badge-default badge-pill">
              <i className="fa fa-tag"></i>
            </span>
          </div>
        )}
      </div>
    );
  };
  const imageErrorHandler = (e) => {
    setImageError(true);
  };

  return (
    <React.Fragment>
      <Row style={{ padding: 15, paddingBottom: 0 }}>
        <Col lg={2}>
          <a
            rel="noreferrer noopener"
            href={target.targetLink || target.salesLink}
            target="_blank"
          >
            <img
              style={{ width: 60, borderRadius: 50 }}
              alt={target.name}
              src={
                imageError
                  ? require("assets/img/placeholder.jpg")
                  : target.imageurl &&
                    target.imageurl.startsWith("https://media")
                    ? target.imageurl
                    : require("assets/img/placeholder.jpg")
              }
              onError={(e) => imageErrorHandler(e)}
            />
          </a>
        </Col>
        <Col lg={5}>
          <Row>
            <h5>{target.name}</h5>
          </Row>
          <Row>
            <p>{target.headline}</p>
          </Row>
        </Col>
        {/* <Col>
          <h5>Notes</h5>
          <Input type="textarea" name="notes" />
        </Col> */}
        <Col className="d-flex justify-content-end" >
          <div>
            {!target.isUnRead ? (
              <>
                <Button
                  onClick={() => markUnRead()}
                  className="btn-sm"
                  color="primary"
                >
                  Mark as unread
                </Button>

                <br />
                <br /></>
            ) : (
              <div />
            )}
            {showContactIcon && <UncontrolledTooltip
              placement="bottom"
              delay={0}
              target={
                `tooltip-contact-info1`
              }
            >
              Contact Info
            </UncontrolledTooltip>
            }
            {showContactIcon && <i
              className="nc-icon nc-globe float-right"
              id={
                "tooltip-contact-info1"
              }
              style={{
                fontSize: 20,
                marginTop: "5px",
                color: "black",
                cursor: "pointer",
              }}
              onClick={() => {
                onContactInfoModelIconClick(target);
              }}
            />}
          </div>
        </Col>
      </Row>
      <div style={{ paddingLeft: 15 }}>{renderTags(target.actions)}</div>
      <hr />
      {messagesToDisplay && (
        <div className="messages card-chat">
          {messagesToDisplay.map((message, index) => (
            <Message
              key={index}
              sender={message.sender}
              userName={liaccount && liaccount.name}
              userImgUrl={liaccount && liaccount.imageurl}
              oppositeImgUrl={
                imageError ? require("assets/img/placeholder.jpg") : imageurl
              }
              msgTimeStamp={
                message.status === "sending"
                  ? "sending..."
                  : `${moment(message.msgTime * 1000)
                    .utc()
                    .format("YYYY-MM-DD")} ${moment(message.msgTime * 1000)
                      .utc()
                      .format("HH:mm")}`
              }
              msgText={message.msg}
              isAccepted={isAccepted}
            />
          ))}
          <div ref={messagesEndRef} id="messageEndRef" />
        </div>
      )}

      <SendMessageBlock
        messages={messagesToDisplay}
        liaccount={liaccount}
        userImgUrl={liaccount && liaccount.imageurl}
        onSend={handleSendMessage}
        firstOpen={firstOpen}
        toggleFirstOpen={() => setFirstOpen(false)}
        isAccepted={isAccepted}
        isInMail={isInMail}
        useCred={useCred}
        handleSubjectChange={handleSubjectChange}
        handleUseCredChange={handleUseCredChange}
        messageValue={inputValue}
        handleInputChange={setInputValue}
      />
    </React.Fragment>
  );
};

export default CampaignProspectsMessagingModal;
