import { NOTIFY as ACTION_HEADER } from "./types";

export function apiError(error) {
    return {
        type: ACTION_HEADER.API_ERROR,
        error
    };
}

export function hideNotify() {
    return {
        type: ACTION_HEADER.HIDE_NOTIFY
    };
}
