import React, { useEffect, useState } from "react";
import { Row, Modal, ModalBody, Button } from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";
import ReactTable from "react-table";
import AddReplayTemplates from './addReplayTemplates';
import { replayTemplatesActions } from "./../../../../actions";

const ReplayTemplatesModal = ({
  showReplayTemplateModal,
  replayTemplateModalToggel,
  getReplayTemplate,
  createReplayTemplates,
  updateReplayTemplates,
  deleteReplayTemplates,
  replayTemplates,
  liaccount
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editForm, setEditForm] = useState(null);

  const backtoList = () => {
    setShowForm(!showForm);
  }

  useEffect(function () {
    getReplayTemplate(liaccount.email)
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


  const createReplayTemplateHook = (data) => {
    createReplayTemplates({
      ...data,
      userId: liaccount._id,
      liEmail: liaccount.email,
    })
    setShowForm(false);
  }

  const updateReplayTemplatesHook = (data) => {
    updateReplayTemplates({
      ...data,
      userId: liaccount._id,
      liEmail: liaccount.email,
    });
    setShowForm(false);
  }

  const deleteReplayTemplatesHook = (data) => {
    deleteReplayTemplates(data);
  }

  const replayTemplatesData =
    replayTemplates &&
    replayTemplates.map((item) => {
      return {
        ...item,
        message: item.message && item.message.length > 20 ? item.message.substring(0, 17) + ' ...' : item.message,
        more: (
          <div>
            <Button
              color="primary"
              size="md"
              className="btn-icon btn-default btn-link remove"
              onClick={() => {
                setShowForm(true);
                setEditForm(item);
              }}
            >
              <i className="fa fa-edit" />
            </Button>
            <Button
              color="danger"
              size="md"
              className="btn-icon btn-default btn-link"
              onClick={(e) => deleteReplayTemplatesHook(item._id)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        ),
      };
    });

  return (
    <Modal
      toggle={replayTemplateModalToggel}
      isOpen={showReplayTemplateModal}
      className="modal-lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          {showForm && (editForm ? "Edit Replay Template" : "Add Replay Template")}
          {!showForm && "Manage Replay Templates"}
          <span
            aria-label="Close"
            className=" close"
            type="button"
            onClick={() => {
              replayTemplateModalToggel({});
            }}
          >
            <span aria-hidden={true}>×</span>
          </span>
          {!showForm && <Button
            style={{ marginLeft: "15px" }}
            className="add-btn el-inline btn btn-primary btn-icon btn-round btn btn-secondary"
            onClick={() => {
              setShowForm(true);
              setEditForm(null)
            }}
          >
            <i className="nc-icon nc-simple-add" />
          </Button>}
        </h5>
      </div>
      <ModalBody>
        {showForm && <AddReplayTemplates editForm={editForm} updateReplayTemplatesHook={updateReplayTemplatesHook} createReplayTemplateHook={createReplayTemplateHook} backtoList={backtoList} />}
        {!showForm && <div>

          <Row>
            <ReactTable
              style={{
                width: "100%",
              }}
              data={replayTemplatesData}
              resizable={true}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "Message",
                  accessor: "message",
                  sortable: false,
                  filterable: false,
                },
                {
                  Header: "",
                  accessor: "more",
                  sortable: false,
                  filterable: false,
                },
              ]}
              defaultPageSize={5}
              showPaginationTop={false}
              showPaginationBottom={false}
              className="sequence-manager-table"
            />
          </Row>
        </div>}
      </ModalBody>
    </Modal>
  );
};

// export default ReplayTemplatesModal;

const mapStateToProps = (state) => ({
  replayTemplates: state.replayTemplates.replayTemplates,
  loading: state.replayTemplates.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getReplayTemplate: (userId) => dispatch(replayTemplatesActions.getReplayTemplates(userId)),
  updateReplayTemplates: (replayTemplateId, replayTemplate) =>
    dispatch(replayTemplatesActions.updateRequestReplayTemplates(replayTemplateId, replayTemplate)),
  createReplayTemplates: (replayTemplate) =>
    dispatch(replayTemplatesActions.createRequestReplayTemplates(replayTemplate)),
  deleteReplayTemplates: (replayTemplateId) =>
    dispatch(replayTemplatesActions.deleteRequestReplayTemplates(replayTemplateId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(ReplayTemplatesModal)
);
