/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";
class Maintain extends React.Component {
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }

  render() {
    return (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto mt-5" lg="4" md="6">
              <h1>We&rsquo;ll be back soon!</h1>
              <div>
                <p>
                  We're doing some quick maintenance and updates. We'll be back
                  shortly
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/joshua-earles.jpg")})`,
          }}
        />
      </div>
    );
  }
}

export default Maintain;
