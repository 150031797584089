import React, { useState } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// reactstrap components
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  InputGroup,
  Alert,
} from "reactstrap";
import { validator } from "../../../../utils";

const BlackListAddForm = (props) => {
  const [blackLink, setBlackLink] = useState(-1);
  const [formError, setFormError] = useState("");

  const onChangeBlackLink = (e) => {
    setBlackLink(e.target.value);
    setFormError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (blackLink === -1) {
      setBlackLink("");
      setFormError("Please enter the black links.");

      return;
    }

    const regex = /^(https:\/\/www\.linkedin\.com\/(sales\/lead|in)\/)/;
    if (!blackLink.match(regex)) {
      setFormError("The link is invalid.");

      return;
    }

    props.addBlackLink(blackLink);
  };

  return (
    <Form className="form" method="" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Row>
            <Col>
              <InputGroup className="prospect-link-field">
                <Input
                  invalid={validator.checkRequiredValidation(blackLink)}
                  onChange={onChangeBlackLink}
                  placeholder="Enter a Sales Navigator profile URL"
                ></Input>
              </InputGroup>
            </Col>
            <Col xs="4" xl="4">
              <div>
                <Button className="add-btn el-inline btn-primary btn-icon btn-round page-prospect__add-btn">
                  <i className="nc-icon nc-simple-add" />
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="8" xl="8">
              {formError && (
                <InputGroup>
                  <Input hidden invalid />
                  <FormFeedback>
                    <Alert color="danger" fade={true}>
                      <span>{formError}</span>
                    </Alert>
                  </FormFeedback>
                </InputGroup>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default BlackListAddForm;
