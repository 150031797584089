import React from "react";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import ReactTable from "react-table";
import {
  dateRange,
  statusCount,
  connectedCount,
  repliedCount,
} from "../../../utils";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

const CampaignDailyLogCard = (props) => {
  const { campaign, campaignType, link, timezone } = props;
  let logData = [];
  if (campaign) {
    const { createdAt, data, connectionData, repliedData } = campaign;
    logData = dateRange(
      moment(createdAt).tz(timezone),
      moment().tz(timezone),
      timezone
    )
      .reverse()
      .map((date) => {
        if (campaignType === "INMAIL")
          return {
            date: date ? date : createdAt,
            inmails: statusCount(data, "INMAIL", 1, date, timezone),
            replies: repliedCount(repliedData, date, timezone),
          };
        else
          return {
            date: date,
            visits: statusCount(data, "VISIT", 1, date, timezone),
            requests: statusCount(data, "CONNECT", 1, date, timezone),
            accepted: connectedCount(connectionData, date, timezone),
            replies: repliedCount(repliedData, date, timezone),
            messages: statusCount(data, "MESSAGE", 1, date, timezone),
            emails: statusCount(data, "EMAIL", 1, date, timezone),
          };
      })
      .filter(
        (row) =>
          row.visits ||
          row.requests ||
          row.accepted ||
          row.inmails ||
          row.replies ||
          row.messages ||
          row.emails
      );
  }

  const getCampaignIdentifier = (campaign) => {
    const allIds = campaign.browserKey.split("**");
    return allIds[2].replace(/\W/g, "-");
  };

  const headers =
    campaignType === "INMAIL"
      ? ["Date", "Inmails", "Replies"]
      : [
          "Date",
          "Visits",
          // "Follows",
          "Requests",
          "Accepted",
          "Replies",
          "Messages",
          "Emails",
        ];
  return (
    <Card className="dash-daily-table">
      <CardHeader>
        <CardTitle tag="h4">Campaign Results</CardTitle>
        <h5 className="card-category el-inline">
          Daily log of campaign interactions
        </h5>
      </CardHeader>
      <CardBody style={{ paddingTop: 0 }}>
        <ReactTable
          data={logData}
          columns={headers.map((header) => {
            if (header === "Date")
              return {
                Header: header,
                accessor: header.toLowerCase(),
                Cell: (row) => row.value.format("DD/MM"),
                sortable: true,
                filterable: false,
              };
            else if (header === "Replies")
              return {
                Header: header,
                accessor: header.toLowerCase(),
                Cell: (row) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    to={{
                      pathname: link,
                      state: {
                        campIdentifier:
                          "camp-" + getCampaignIdentifier(campaign),
                        replied: true,
                      },
                    }}
                  >
                    {row.value}
                  </Link>
                ),
                sortable: true,
                filterable: false,
              };
            else
              return {
                Header: header,
                accessor: header.toLowerCase(),
                sortable: true,
                filterable: false,
              };
          })}
          defaultPageSize={5}
          showPaginationTop={false}
          showPaginationBottom={true}
          /*
                  You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                */
          className=" -highlight"
        />
      </CardBody>
    </Card>
  );
};

export default CampaignDailyLogCard;
