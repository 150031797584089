import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, notesActions, notifyActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";


const { NOTES } = types;
const { updateNotesSuccess, updateNotesError } = notesActions;
const { apiError } = notifyActions;
const noteUrl = backendUrl + "/target/";

function* requestNotesUpdate(action) {
  const { target_id, notes } = action;
  try {
    yield axiosInstance().post(noteUrl + target_id + "/notes", {
      note: notes,
    });
    yield put(updateNotesSuccess());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    yield put(updateNotesError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
  }
}

function* notesSaga() {
  yield all([takeLatest(NOTES.REQUEST_NOTES_UPDATE, requestNotesUpdate)]);
}

export default notesSaga;
