
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";

import { StripeProvider } from "react-stripe-elements";

import CheckoutForm from "../../stripe/CheckoutForm";
import { validator } from "../../utils";
import { userActions, planActions } from "../../actions";
const { requestPayment, checkCoupon } = userActions;
const { requestGetPlans } = planActions;

// const stripePublicKey = "pk_live_zrFt9a4lP6j80TyCjJea0vAU00PzLVh9MC";
// const stripePublicKey = "pk_test_spJkXGwiH4nEiC8AANbhMMWW00yCKdKlWV";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
class AddPayment extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      email: -1,
      password: -1,
      agree: true,
      isShowPromoInput: false,
      promoCode: "",
      plan: '',
      name: '',
    };
  }
  addPaymentMethod = (token) => {
    this.props.requestPayment(
      this.props.verifyToken,
      token,
      this.state.promoCode
    );
  };
  checkCoupon = (coupon) => {
    if (coupon) {
      this.props.checkCoupon(
        coupon
      );
    }
    else {

    }
  };
  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  setName = (e) => {
    this.setState({ name: e.target.value });
  };
  setPassword = (e) => {
    this.setState({ password: e.target.value });
  };
  toggleAgree = () => {
    this.setState({ agree: !this.state.agree });
  };
  handleKeyPress(target) {
    console.log(target.charCode);
    if (target.charCode === 13) {
      console.log('RFC_2822', this.state.promoCode);

      this.checkCoupon(this.state.promoCode);
    }
  }
  render() {

    const {
      error,
      coupon,
      loading,
    } = this.props;
    console.log(1, loading, coupon)
    return (
      <LoadingOverlay active={loading} spinner text="Checking coupon ...">
        <div className="register-page">
          <Container>
            <Row>
              <Col
                className="mr-auto ml-auto"
                lg="5"
                md="5"
                style={{ zIndex: 100 }}
              >
                <div action="" className="form" method="">
                  <Card className="card-signup text-center">
                    <CardHeader>
                      <CardTitle tag="h4" style={{ fontWeight: "bold" }}>Add billing information</CardTitle>
                      <p style={{ textAlign: "left", fontWeight: 400, fontFamily: 'circular' }}>
                        Don't worry, your subscription will only be charged after your 10 day FREE trial period ends and you can cancel anytime.
                      </p>
                    </CardHeader>
                    <CardBody>
                      {error && (
                        <InputGroup>
                          <Input hidden invalid />
                          <FormFeedback>{error}</FormFeedback>
                        </InputGroup>
                      )}
                      <FormGroup check className="text-left">
                        <span
                          className="text-primary ml-1"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.setState({
                              isShowPromoInput: !this.state.isShowPromoInput,
                            });
                          }}
                        >
                          Enter promo code
                        </span>
                      </FormGroup>
                      {this.state.isShowPromoInput && (
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText className="iconwrap">
                              <i className="fa fa-gift" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <div style={{ width: "100%" }}>
                            <Input
                              style={{ borderRadius: '4px' }}
                              placeholder="Promo code"
                              type="text"
                              className="fullname"
                              value={this.state.promoCode}
                              onKeyPress={(e) => this.handleKeyPress(e)}
                              onChange={(e) => {
                                this.setState({ promoCode: e.target.value });
                              }}
                            />
                            {!coupon && <p
                              className="ml-1"
                              style={{ cursor: "pointer", textAlign: 'right', color: '#007bff', marginTop: '7px' }}
                              onClick={() => {
                                this.checkCoupon(this.state.promoCode);
                              }}
                            >
                              Click here to see if this coupon is valid
                            </p>}
                            {coupon && <p
                              className="ml-1"
                              style={{ cursor: "pointer", textAlign: 'right', color: '#007bff', marginTop: '7px' }}
                            >
                              {/* {coupon.valid && (<p style={{ marginBottom: '0px' }}> this code is available</p>)} */}
                              {coupon.amount_off > 0 && (<p style={{ marginBottom: '0px' }}> ${coupon.amount_off / 100} discount applied: ${(49 - coupon.amount_off / 100).toFixed(2)} </p>)}
                              {coupon.percent_off > 0 && (<p style={{ marginBottom: '0px' }}> {coupon.percent_off}% discount applied: ${(49 * (1 - coupon.percent_off / 100)).toFixed(2)}</p>)}
                              {/* {coupon.duration_in_months > 0 && (<p style={{ marginBottom: '0px' }}> the duration is {coupon.duration_in_months} months</p>)} */}
                            </p>}
                          </div>
                        </InputGroup>
                      )}
                      <StripeProvider apiKey={stripePublicKey}>
                        <CheckoutForm
                          addPaymentMethod={this.addPaymentMethod}
                        />
                      </StripeProvider>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${require("assets/img/bg/david-marcu.jpg")})`,
            }}
          />
        </div>
      </LoadingOverlay >
    );
  }
}

const mapStateToProps = (state) => ({
  verifyToken: state.authentication.verifyToken,
  plans: state.plans.plans,
  error: state.authentication.error,
  loading: state.authentication.loading,
  coupon: state.authentication.coupon,
});

const mapDispatchToProps = (dispatch) => ({
  requestPayment: (verifyToken, token, promoCode) =>
    dispatch(requestPayment(verifyToken, token, promoCode)),
  checkCoupon: (promoCode) =>
    dispatch(checkCoupon(promoCode)),
  requestGetPlans: () => dispatch(requestGetPlans()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps)(AddPayment));
