import React, { useState } from "react";
// reactstrap components
import {
  Input,
  InputGroup,
  FormFeedback,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";

import { validator } from "../../../utils";
import { useEffect } from "react";

const CampaignCard = ({ type, sequences, saveCampaign, campaigns }) => {
  const [name, changeName] = useState(-1);
  const [linkedinURL, changeLinkedinURL] = useState(-1);
  const [excludeOption, changeExcludeOption] = useState(true);
  const [isRunning, changeIsRunning] = useState(true);
  const [includeCommentsOption, changeIncludeCommentsOption] = useState(true);
  const [includeReactOption, changeIncludeReactOption] = useState(true);

  const [sequence, changeSequence] = useState(-1);

  const [fileSrc, setFileSrc] = useState(null);

  let urlPlaceholder = null;

  useEffect(() => {
    if (sequences && sequences.length > 0) {
      changeSequence(sequences[0]._id);
    }
  }, [sequences]);
  console.log(" type ;", type);
  switch (type) {
    case "SALES_NAVIGATOR":
      urlPlaceholder = "Enter a Sales Navigator search URL";
      break;
    case "RECRUITER_LITE":
      urlPlaceholder = "Enter a Recruiter Lite search URL";
      break;
    case "BASIC_QUERY":
      urlPlaceholder = "Enter a LinkedIn search URL";
      break;
    case "CUSTOM":
      urlPlaceholder =
        "Enter a list of LinkedIn profile URLs; one URL per line";
      break;
    case "FACEBOOK":
      urlPlaceholder = "Enter the Facegroup URL that you joined";
      break;
    case "POSTENGAMENET":
      urlPlaceholder = "Enter a Linkedin Post Link";
      break;
    default:
      urlPlaceholder = "Enter a Sales Navigator search URL";
  }

  const setName = (e) => changeName(e.target.value);
  const setLinkedinURL = (e) => changeLinkedinURL(e.target.value);
  const toggleExcludeOption = () => changeExcludeOption(!excludeOption);
  const toggleIncludeReactOption = () =>
    changeIncludeReactOption(!includeReactOption);
  const toggleIncludeCommentsOption = () =>
    changeIncludeCommentsOption(!includeCommentsOption);

  const toggleIsRunning = () => changeIsRunning(!isRunning);
  const setSequence = (e) => {
    changeSequence(e.target.value);
  };

  const onFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      setFileSrc(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name === -1) {
      changeName("");
      return;
    }
    if (
      urlPlaceholder &&
      linkedinURL === -1 &&
      type !== "CUSTOM" &&
      type !== "FOLLOWERS"
    ) {
      changeLinkedinURL("");
      return;
    }

    if (sequence === -1) {
      changeSequence("");
      return;
    }

    if (validator.checkRequiredValidation(name)) {
      return;
    }

    if (
      campaigns.findIndex(
        (camp) =>
          camp.name.split(" -> ")[1].replace(/\W/g, "-") ===
          name.replace(/\W/g, "-")
      ) !== -1
    ) {
      return;
    }

    if (
      (type !== "CUSTOM" || type !== "FOLLOWERS" || type !== "FACEBOOK") &&
      urlPlaceholder &&
      validator.checkRequiredValidation(linkedinURL)
    ) {
      return;
    }

    if (validator.checkRequiredValidation(sequence)) {
      return;
    }

    if (
      type === "BASIC_QUERY" &&
      validator.checkBasicQueryValidation(linkedinURL)
    ) {
      return;
    }
    if (
      type === "SALES_NAVIGATOR" &&
      validator.checkSalesNavValidation(linkedinURL)
    ) {
      return;
    }

    if (
      type === "RECRUITER_LITE" &&
      validator.checkRecruiterValidation(linkedinURL)
    ) {
      return;
    }

    if (
      type === "POSTENGAMENET" &&
      validator.checkPostLinkValidation(linkedinURL)
    ) {
      return;
    }

    const sourceLinks =
      type === "CUSTOM"
        ? []
        : type === "FOLLOWERS"
          ? []
          : urlPlaceholder
            ? linkedinURL.split("\n")
            : [];
    if (type === "CUSTOM" && !fileSrc) return;
    if (!includeCommentsOption && !includeReactOption) {
      alert(" Please enable comment user or react user");
    }

    saveCampaign({
      name,
      sourceLinks,
      excludeOption,
      sequenceId: sequence,
      isRunning,
      isStarted: isRunning,
      file: fileSrc,
      includeCommentsOption,
      includeReactOption,
    });
  };

  return (
    <>
      <div className="fade-in">
        <div className="inputfield">
          <p className="inputfieldname">Name</p>
          <InputGroup>
            <Input
              placeholder="Descriptor of this audience"
              type="text"
              invalid={
                validator.checkRequiredValidation(name) ||
                (name !== -1 &&
                  campaigns.findIndex(
                    (camp) =>
                      camp.name.split(" -> ")[1].replace(/\W/g, "-") ===
                      name.replace(/\W/g, "-")
                  ) !== -1)
              }
              onChange={setName}
            />
            <FormFeedback>
              {validator.checkRequiredValidation(name)
                ? "Campaign Name Required"
                : "Campaign Name already exists"}
            </FormFeedback>
          </InputGroup>
        </div>
        {urlPlaceholder &&
          (type === "CUSTOM" ? (
            <div className="inputfield">
              <p className="inputfieldname">Select a CSV file</p>
              <Input
                type="file"
                name="csv_file"
                id="csvFile"
                accept=".csv"
                onChange={onFileChange}
              />
              <div>
                <br />
                <p className="alert">
                  <span
                    data-notify="icon"
                    className="text-primary nc-icon nc-alert-circle-i"
                  ></span>
                  <div style={{ marginLeft: 50 }} className="text-primary">
                    We require column header 'profileUrl' in order to import
                    CSV.
                    <br />
                    <a
                      className=""
                      href="assets/files/pp-sample-import.csv"
                      download
                    >
                      <u>Download an example CSV</u>
                    </a>
                  </div>
                </p>
              </div>
            </div>
          ) : type === "FOLLOWERS" ? (
            <div></div>
          ) : type === "POSTENGAMENET" ? (
            <div className="inputfield">
              <p className="inputfieldname">Linkedin Post URL</p>
              <InputGroup>
                <Input
                  placeholder={urlPlaceholder}
                  type="text"
                  invalid={
                    validator.checkRequiredValidation(linkedinURL) ||
                    validator.checkPostLinkValidation(linkedinURL)
                  }
                  onChange={setLinkedinURL}
                />
                <FormFeedback>
                  {validator.checkRequiredValidation(linkedinURL)
                    ? `Linkedin URL Required`
                    : // : validator.checkSalesNavSavedValidation(linkedinURL)
                    //   ? 'Forbidden URL parameter "savedSearchId"'
                    `Invalid URL format for this campaign type.`}
                </FormFeedback>
              </InputGroup>
            </div>
          ) : (
            <div className="inputfield">
              <p className="inputfieldname">
                {type === "FACEBOOK" ? "Facebook Group Url" : "Linkedin URL"}
              </p>
              <InputGroup>
                <Input
                  placeholder={urlPlaceholder}
                  type="text"
                  invalid={
                    validator.checkRequiredValidation(linkedinURL) ||
                    (type === "BASIC_QUERY" &&
                      validator.checkBasicQueryValidation(linkedinURL)) ||
                    (type === "SALES_NAVIGATOR" &&
                      validator.checkSalesNavValidation(linkedinURL)) ||
                    (type === "RECRUITER_LITE" &&
                      validator.checkRecruiterValidation(linkedinURL))
                  }
                  onChange={setLinkedinURL}
                />
                <FormFeedback>
                  {validator.checkRequiredValidation(linkedinURL)
                    ? `Linkedin URL Required`
                    : `Invalid URL format for this campaign type.`}
                </FormFeedback>
              </InputGroup>
            </div>
          ))}
        {type === "POSTENGAMENET" && (
          <div className="inputfield run-immediately">
            <Switch
              offColor="primary"
              onColor="primary"
              defaultValue={includeCommentsOption}
              onChange={toggleIncludeCommentsOption}
            />{" "}
            &nbsp; Include the comment users
            <i
              className="nc-icon nc-alert-circle-i"
              id={`tooltip-prev-${type}-2`}
            ></i>
            <UncontrolledTooltip
              placement="bottom"
              delay={0}
              target={`tooltip-prev-${type}-2`}
            >
              All users that commented will be included
            </UncontrolledTooltip>
          </div>
        )}
        {type === "POSTENGAMENET" && (
          <div className="inputfield run-immediately">
            <Switch
              offColor="primary"
              onColor="primary"
              defaultValue={includeReactOption}
              onChange={toggleIncludeReactOption}
            />{" "}
            &nbsp; Include the reaction users
            <i
              className="nc-icon nc-alert-circle-i"
              id={`tooltip-prev-${type}-1`}
            ></i>
            <UncontrolledTooltip
              placement="bottom"
              delay={0}
              target={`tooltip-prev-${type}-1`}
            >
              All users that reacted(Like, Celebrate, Support, Love....) will be
              included
            </UncontrolledTooltip>
          </div>
        )}

        <div className="inputfield run-immediately">
          <Switch
            offColor="primary"
            onColor="primary"
            defaultValue={excludeOption}
            onChange={toggleExcludeOption}
          />{" "}
          &nbsp; Exclude if in previous campaign
          <i
            className="nc-icon nc-alert-circle-i"
            id={`tooltip-prev-${type}`}
          ></i>
          <UncontrolledTooltip
            placement="bottom"
            delay={0}
            target={`tooltip-prev-${type}`}
          >
            Exclude prospects from this campaign if they've been approached
            previously in other campaigns
          </UncontrolledTooltip>
        </div>
        <div className="inputfield">
          <p className="inputfieldname">Sequence for this audience</p>
          <Input
            type="select"
            value={sequence}
            onChange={setSequence}
            style={{ height: 32 }}
            invalid={validator.checkRequiredValidation(sequence)}
          >
            {sequences.map((seq) => (
              <option value={seq._id} key={seq.name}>
                {seq.name}
              </option>
            ))}
          </Input>
          <FormFeedback>
            Please select a sequence for this audience
          </FormFeedback>
        </div>
        <div className="inputfield run-immediately">
          <Switch
            offColor="primary"
            onColor="primary"
            defaultValue={isRunning}
            onChange={toggleIsRunning}
          />{" "}
          &nbsp; Run this campaign immediately
          <i
            className="nc-icon nc-alert-circle-i"
            id={`tooltip-set-on-${type}`}
          ></i>
          <UncontrolledTooltip
            placement="bottom"
            delay={0}
            target={`tooltip-set-on-${type}`}
          >
            Set to 'on' if you want to run this campaign immediately or 'off' to
            save for later.
          </UncontrolledTooltip>
        </div>
        <div className="pull-right">
          <div>
            <Button color="primary" onClick={(e) => handleSubmit(e)}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignCard;
