import { types } from "../actions";

const { ADMIN: ACTION_HEADER } = types;

const initialState = {
  filelist: [],
  userlist: [],
  proxylist: [],
  proxy: {},
  loading: false,
  error: "",
};

function adminReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_HEADER.REQUEST_FILELIST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case ACTION_HEADER.SUCCESS_FILELIST:
      return {
        ...state,
        loading: false,
        filelist: action.filelist,
      };
    case ACTION_HEADER.REQUEST_USERLIST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.REQUEST_USER_INFO:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.REQUEST_PROXYLIST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.REQUEST_PROXYUPDATE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.REQUEST_PROXYSAVE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ACTION_HEADER.SUCCESS_USERLIST:
      return {
        ...state,
        loading: false,
        userlist: action.userlist,
      };
    case ACTION_HEADER.SUCCESS_PROXYLIST:
      return {
        ...state,
        loading: false,
        proxylist: action.proxylist,
      };
    case ACTION_HEADER.SUCCESS_PROXYUPDATE:
      let data = [];
      data = state.proxylist.data.map((el) => {
        if (el.id === action.proxy.id) {
          el.server = action.proxy.server;
          el.user = action.proxy.user;
        }
        return el;
      });
      return {
        ...state,
        loading: false,
        proxylist: { ...state.proxylist, data },
      };
    case ACTION_HEADER.SUCCESS_USER_INFO:
      state.userlist.data.userData.map((el) => {
        if (el.userId === action.userInfo.userId) {
          el.linkedinData = action.userInfo.data;
        }
        return el;
      });
      return {
        ...state,
        loading: false,
        userlist: state.userlist,
      };
    case ACTION_HEADER.SUCCESS_PROXYSAVE:
      return {
        ...state,
        loading: false,
        proxylist: {
          ...state.proxylist,
          data: [...state.proxylist.data, action.proxy],
        },
      };
    case ACTION_HEADER.FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
}

export default adminReducer;
