/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { withRouter } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";

function UpgradeModal({ show, change, history, message, account }) {
  if (!show) show = false;
  const onConfirm = () => {
    // const { account } = match.params;
    change();
    history.push({
      pathname: "/admin/" + account + "/user-profile",
      state: {
        upgradePlan: true,
      },
    });
  };

  const onCancel = () => {
    change();
  };
  return (
    <ReactBSAlert
      custom
      style={{ display: "block" }}
      title={`Upgrade your plan`}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Upgrade"
      cancelBtnText="Close"
      showCancel
      show={show}
      customIcon={<i className="nc-icon nc-user-run upgrade-icon" />}
    >
      <p> {message ? message : "Upgrade your plan to use this feature."} </p>
    </ReactBSAlert>
  );
}

export default withRouter(UpgradeModal);
