import React, { useState, useEffect, Fragment } from "react";
import { FormGroup, UncontrolledTooltip } from "reactstrap";

import Switch from "react-bootstrap-switch";
import PMPagination from "../../../components/PMPagination";
import Select from "react-select";
import moment from "moment";
import UpgradeModal from "./../../../components/UpgradeModal";

const ProspectRow = (props) => {
  const {
    data,
    handleSwitch,
    toggleMessagingModal,
    toggleAssignModal,
    updateReadStatus,
    setProspectInfoToggleFun,
  } = props;

  const [imageError, setImageError] = useState(false);
  const [switchBtn, showSwitchBtn] = useState(data.notExcluded);

  const last_message =
    data.messaged.length > 0
      ? data.messaged.slice(-1)[0].messages.slice(-1)[0]
      : null;
  const imageErrorHandler = (e) => {
    setImageError(true);
  };

  const renderTags = (tags, messaged) => {
    const badgeColors = {
      Replied: "success",
      Accepted: "info",
      Visited: "danger",
      Requested: "dull-orange",
      Messaged: "warning",
    };
    return (
      <div className="activity-badges">
        {tags &&
          tags.map((tag, index) => (
            <div key={`badge-${index}`} className="el-inline">
              <span
                className={`badge badge-${badgeColors[tag] ? badgeColors[tag] : "primary"
                  } badge-pill`}
              >
                {tag}
              </span>
            </div>
          ))}
        {data.customTags &&
          data.customTags.map((tag) => (
            <div key={`badge-${tag._id}`} className="el-inline">
              <span
                className={`badge badge-${tag.color ? tag.color : "primary"
                  } badge-pill`}
              >
                {tag.title}
              </span>
            </div>
          ))}
        <div
          className="el-inline"
          style={{ cursor: "pointer" }}
          onClick={() =>
            toggleAssignModal(
              data._id,
              data.tags,
              data.campaignId,
              data.targetIndex
            )
          }
        >
          <span className="badge badge-default badge-pill">
            <i className="fa fa-tag"></i>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="prospects-row">
      <div
        className="prospect-container"
        style={{ borderColor: data.isUnRead ? "#51bcda" : "none" }}
      >
        <div className="card-content row">
          <div className="avatar col-sm-2">
            <div>
              <a
                rel="noreferrer noopener"
                href={data.targetLink || data.salesLink}
                target="_blank"
              >
                <img
                  alt={data.name}
                  src={
                    imageError
                      ? require("assets/img/placeholder.jpg")
                      : data.imageurl &&
                        data.imageurl.startsWith("https://media")
                        ? data.imageurl
                        : require("assets/img/placeholder.jpg")
                  }
                  onError={(e) => imageErrorHandler(e)}
                />
              </a>
            </div>
          </div>
          <div className="col-sm-8" style={{ padding: 0 }}>
            <p className="name-headline">
              <strong>
                {data.name} - {data.headline}
              </strong>
            </p>

            <p className="last-message msg-content">
              {last_message ? (
                <q>
                  <i>{last_message.msg}</i>
                </q>
              ) : (
                "No messages yet."
              )}
            </p>

            <p className="last-message msg-replied-time">
              {last_message ? (
                <i>
                  {data.isReplied ? " Replied on " : "Message sent at "}
                  {moment(new Date(last_message.msgTime * 1000)).format(
                    "DD/MM/YYYY"
                  )}
                </i>
              ) : null}
            </p>

            {/*<button className="btn btn-sm">Make a Note</button>*/}
          </div>
          <div
            className="col-sm-2"
            style={{
              color: `${data.actions.indexOf("Replied") > -1 ? "#6bd098" : "#66615B"
                }`,
            }}
          >
            {data.messaged.length > 0 && (
              <>
                <i
                  className="nc-icon nc-chat-33 chat-icon"
                  onClick={() => {
                    toggleMessagingModal(data);
                    if (data.isUnRead) updateReadStatus(data.messaged[0]._id);
                  }}
                />
                <br />
              </>
            )}
            {
              <>
                <i
                  className="nc-icon nc-globe"
                  id={"tooltip-contact-info" + data._id}
                  style={{
                    fontSize: 20,
                    marginTop: "5px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setProspectInfoToggleFun(data);
                  }}
                />
                <UncontrolledTooltip
                  placement="bottom"
                  delay={0}
                  target={`tooltip-contact-info` + data._id}
                >
                  {" "}
                  Contact Info and Notes{" "}
                </UncontrolledTooltip>
              </>
            }

            <br />
          </div>
        </div>
        <div className="card-content tags-content">
          <div className="tags">{renderTags(data.actions, data.messaged)}</div>
          <div className="switch" id={"tooltip-switch" + data._id}>
            <Switch
              key={`${data.name + data.notExcluded}`}
              offColor="success"
              offText={<i className="nc-icon nc-simple-remove" />}
              onColor="success"
              onText={<i className="nc-icon nc-check-2" />}
              value={switchBtn}
              onChange={(e, state) => {
                handleSwitch(
                  data.browserKey,
                  data.targetLink,
                  data.salesLink,
                  data.campaignId,
                  data.targetIndex,
                  e,
                  state
                );
                showSwitchBtn(!switchBtn);
              }}
            />
          </div>
          <UncontrolledTooltip
            placement="bottom"
            delay={0}
            target={"tooltip-switch" + data._id}
          >
            Toggle prospect's active status
          </UncontrolledTooltip>
        </div>
      </div>
    </div>
  );
};

const ProspectsContainer = (props) => {
  const { prospects } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [currentProspects, setCurrentProspects] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState({ value: 10, label: "10" });

  const [privacy, setPrivacy] = useState({});
  const [allowSequence, setAllowSequence] = useState(false);

  useEffect(() => {
    let privacyStr = localStorage.getItem("privacy");
    setPrivacy(JSON.parse(privacyStr));
    const pageLimit = itemsPerPage.value;
    const offset = (currentPage - 1) * pageLimit;
    const currentProspects = prospects.slice(offset, offset + pageLimit);
    setCurrentPage(currentPage);
    setCurrentProspects([...currentProspects]);
    setTotalPages(totalPages);
  }, [prospects]); //eslint-disable-line react-hooks/exhaustive-deps

  const onPageChanged = (data) => {
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentProspects = prospects.slice(offset, offset + pageLimit);
    setCurrentPage(currentPage);
    setCurrentProspects([...currentProspects]);
    setTotalPages(totalPages);
  };

  const pageLengths = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 500, label: "500" },
  ];
  const totalProspects = prospects.length;
  if (totalProspects === 0) return null;
  const headerClass = [
    "text-dark m-0",
    currentPage ? "border-gray border-right" : "",
  ]
    .join(" ")
    .trim();
  const getPagination = () => {
    // console.log(currentPage, 'currentPage')
    return (
      <div className="container-fluid">
        <div className="row d-flex flex-row">
          <div className="w-100 d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <p className={headerClass}>
                <strong className="text-secondary">{totalProspects}</strong>{" "}
                Results
              </p>
              {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}
            </div>
            <div className="d-flex flex-row py-2 align-items-center">
              <FormGroup style={{ width: 100 }} className="mr-3">
                <Select
                  className="react-select"
                  placeholder="Single Select"
                  classNamePrefix="react-select"
                  value={itemsPerPage}
                  onChange={(item) => {
                    setItemsPerPage(item);
                  }}
                  options={pageLengths}
                />
              </FormGroup>
              <PMPagination
                totalRecords={prospects.length}
                pageLimit={itemsPerPage.value}
                pageNeighbours={1}
                currentPage={currentPage}
                onPageChanged={(data) => onPageChanged(data)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      {getPagination()}
      {currentProspects &&
        currentProspects.map((prospect, index) => {
          if (
            privacy.MAX_PROSPECT <
            index + parseInt(itemsPerPage.value) * (currentPage - 1) + 1
          ) {
            return (
              <div
                className={"disabledPlanCard"}
                key={`div_${prospect._id} + ${prospect.isUnRead}`}
                onClick={() => {
                  setAllowSequence(!allowSequence);
                }}
              >
                <ProspectRow
                  key={`${prospect._id} + ${prospect.isUnRead}`}
                  data={prospect}
                  hideChatIcon={true}
                  {...props}
                />
              </div>
            );
          } else {
            return (
              <ProspectRow
                key={`${prospect._id} + ${prospect.isUnRead}`}
                data={prospect}
                hideChatIcon={false}
                {...props}
              />
            );
          }
        })}
      {getPagination()}
      <div className="mb-3"></div>
      {
        <UpgradeModal
          show={allowSequence}
          change={() => {
            setAllowSequence(!allowSequence);
          }}
        />
      }
    </Fragment>
  );
};

export default ProspectsContainer;
