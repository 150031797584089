import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, sequenceActions, notifyActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";

const { SEQUENCE } = types;
const {
  successUpdateSequence,
  successGetSequences,
  successDeleteSequence,
  fail,
  showSuccessMessage,
  successTestEmail,
} = sequenceActions;
const { apiError } = notifyActions;

const baseURL = backendUrl + "/sequence";
const baseURLLinkedin = backendUrl + "/linkedin";

const secondsToTimeout = (secs) => {
  const timeoutNumber =
    secs >= 3600 * 24
      ? secs / 3600 / 24
      : secs >= 3600
      ? secs / 3600
      : secs >= 60
      ? secs / 60
      : 0;
  const timeoutUnit =
    secs >= 3600 * 24 ? "days" : secs >= 3600 ? "hours" : "minutes";

  return { timeoutNumber: Math.floor(timeoutNumber), timeoutUnit };
};

const tofrdSequences = (sequences) => {
  return sequences.map(({ name, subject, msg, timeout, option }) => {
    if (name === "INMAIL") {
      return {
        name,
        toggle: true,
        subject,
        msg,
        sendOpenProfiles: option,
        ...secondsToTimeout(timeout),
      };
    } else
      return {
        name,
        subject,
        msg,
        stopPrevDetected: option,
        toggle: true,
        ...secondsToTimeout(timeout),
      };
  });
};

// add/update sequence request saga
function* requestUpdateSequence(action) {
  // const unicornRegex = /\ud83d[\ude00-\ude4f]/g;
  // const titleRegex = /(Miss|Mr|Mrs|Ms|Prof|Dr|Phd|Msc)\b/;
  const { sequence, account } = action;

  const sequenceSteps = sequence.steps.map((seq) => {
    return {
      ...seq,
      // msg: seq.msg && seq.msg.replace(unicornRegex, "").replace(titleRegex, ""),
    };
  });

  try {
    const isUpdate = !!sequence._id;
    const caller = sequence._id ? axiosInstance().put : axiosInstance().post;
    const resp = yield caller(
      isUpdate ? `${baseURL}/${sequence._id}` : baseURL,
      {
        name: sequence.name,
        liEmail: sequence.liEmail,
        sequences: sequenceSteps,
        id: sequence._id,
      }
    );

    yield put(
      successUpdateSequence({
        ...resp.data,
        sequences: tofrdSequences(resp.data.sequences),
      })
    );
    if (!isUpdate) {
      yield put(push(`/admin/${account}/sequences`));
    } else {
      yield put(showSuccessMessage("Sequence updated."));
    }
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error.msg));
  }
}

function* requestDeleteSequence(action) {
  const { _id, account } = action;
  try {
    yield axiosInstance().delete(`${baseURL}/${_id}`);
    yield put(successDeleteSequence(_id));
    yield put(push(`/admin/${account}/sequences`));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error.msg));
  }
}

// get sequences request saga
function* requestGetSequences(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(`${baseURL}?liEmail=${liEmail}`);
    const sequences = resp.data.docs.map((seq) => ({
      ...seq,
      sequences: tofrdSequences(seq.sequences),
      identifier: "seq-" + seq.name.replace(/\W/g, "-"),
    }));
    yield put(successGetSequences(sequences));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error.msg));
  }
}
function* requestTestEmail(action) {
  try {
    const { id, subject, message } = action;
    console.log("requestTestEmail", id, subject, message);
    const resp = yield axiosInstance().post(
      `${baseURLLinkedin}/${id}/testEmail`,
      {
        subject,
        message,
      }
    );
    yield put(successTestEmail(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(fail(error.msg));
  }
}
function* sequenceSaga() {
  yield all([
    takeLatest(SEQUENCE.REQUEST_UPDATE, requestUpdateSequence),
    takeLatest(SEQUENCE.REQUEST_TESTEMAIL, requestTestEmail),
    takeLatest(SEQUENCE.REQUEST_GET, requestGetSequences),
    takeLatest(SEQUENCE.REQUEST_DELETE, requestDeleteSequence),
  ]);
}

export default sequenceSaga;
