import { USER as ACTION_HEADER } from "./types";

export function requestLogin(email, password) {
  return { type: ACTION_HEADER.REQUEST_LOGIN, email, password };
}

export function successLogin(token, user) {
  return {
    type: ACTION_HEADER.SUCCESS_LOGIN,
    payload: { token, user, remember: false },
  };
}

export function failLogin(error) {
  return { type: ACTION_HEADER.FAIL_LOGIN, error };
}

export function logout() {
  return { type: ACTION_HEADER.LOGOUT };
}
export function requestPayment(
  verifyToken,
  token1,
  promoCode = ""
) {
  //alert(planID);
  return {
    type: ACTION_HEADER.REQUEST_PAYMENT,
    verifyToken,
    token1,
    promoCode,
  };
}

export function checkCoupon(
  promoCode
) {
  //alert(planID);
  return {
    type: ACTION_HEADER.REQUEST_CHECK_COUPON,
    promoCode,
  };
}

export function successCheckCoupon(coupon) {
  return {
    type: ACTION_HEADER.SUCCESS_CHECK_COUPON,
    payload: { coupon },
  };
}
export function failCheckCoupon(error) {
  return { type: ACTION_HEADER.FAIL_CHECK_COUPON, error };
}

export function requestRegister(
  name,
  email,
  password
) {
  //alert(planID);
  return {
    type: ACTION_HEADER.REQUEST_REGISTER,
    name,
    email,
    password
  };
}
export function successRegister(token) {
  return { type: ACTION_HEADER.SUCCESS_REGISTER, token };
}

export function failRegister(error) {
  return { type: ACTION_HEADER.FAIL_REGISTER, error };
}

export function requestGetUser() {
  return { type: ACTION_HEADER.REQUEST_GETUSER };
}

export function successGetUser(user) {
  return { type: ACTION_HEADER.SUCCESS_GETUSER, user };
}

export function failGetUser(error) {
  return { type: ACTION_HEADER.FAIL_GETUSER, error };
}

export function requestVerify(verifyToken, verifyCode) {
  return { type: ACTION_HEADER.REQUEST_VERIFY, verifyToken, verifyCode };
}

export function removeToken() {
  return { type: ACTION_HEADER.REMOVE_TOKEN };
}

export function requestForgotPassword(email) {
  return { type: ACTION_HEADER.REQUEST_FORGOT_PASSWORD, email };
}

export function successForgotPassword(res) {
  return { type: ACTION_HEADER.SUCCESS_FORGOT_PASSWORD, res };
}

export function failForgotPassword(error) {
  return { type: ACTION_HEADER.FAIL_FORGOT_PASSWORD, error };
}

export function requestResetPassword(data) {
  return { type: ACTION_HEADER.REQUEST_RESET_PASSWORD, data };
}

export function successResetPassword(res) {
  return { type: ACTION_HEADER.SUCCESS_RESET_PASSWORD, res };
}

export function failResetPassword(error) {
  return { type: ACTION_HEADER.FAIL_RESET_PASSWORD, error };
}

export function showSuccessMessage(message) {
  return { type: ACTION_HEADER.SHOW_SUCCESS_MESSAGE, message };
}

export function hideSuccessMessage() {
  return { type: ACTION_HEADER.HIDE_SUCCESS_MESSAGE };
}

export function showErrorMessage(message) {
  return { type: ACTION_HEADER.SHOW_ERROR_MESSAGE, message };
}

export function hideErrorMessage() {
  return { type: ACTION_HEADER.HIDE_ERROR_MESSAGE };
}

// export function requestChangeProfile(token) {
// 	return { type: USER.CHANGEPROFILE_REQUEST, token };
// }

// export function receiveChangeProfile(user) {
// 	return { type: USER.CHANGEPROFILE_SUCCESS, user };
// }
