import { all, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";
import { notifyActions, types, userActions } from "../actions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";


const { USER } = types;
const {
  successLogin,
  failLogin,
  successRegister,
  failRegister,
  successGetUser,
  failGetUser,
  successResetPassword,
  successForgotPassword,
  failForgotPassword,
  failResetPassword,
  showSuccessMessage,
  showErrorMessage,
  successCheckCoupon,
  failCheckCoupon
} = userActions;
const { apiError } = notifyActions

const loginURL = "/login";
const registerURL = "/register";
const userURL = "/users";
const verifyURL = "/reverify";
const forgotURL = "/forgot";
const resetURL = "/reset";
const addpayment = "/payment";
const checkcoupon = "/checkCoupon";


function* requestLogin(action) {

  const { email, password } = action;

  try {

    const resp = yield axiosInstance().post(backendUrl + loginURL, { email, password });
    const { token, user } = resp.data;


    if (token) {
      yield put(successLogin(token, user));

      if (!user.verified) {
        return yield put(push("/auth/verify"));
      }
      if (!user.paymentMethodAdded) {
        yield put(push("/auth/add-payment"));
      }

    } else {
      yield put(failLogin(resp.data));
    }

  } catch (e) {

    const error = getError(e);
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(apiError(error))
    yield put(failLogin(error.msg));
  }
}

function* requestRegister(action) {
  try {

    const { type, ...payloads } = action;
    const resp = yield axiosInstance().post(backendUrl + registerURL, payloads);
    window.$FPROM.trackSignup(
      { email: payloads.email },
      function () {
        console.log("Callback received!");
      }
    );

    const { token } = resp.data;
    yield put(successRegister(token));
    yield put(push("/auth/verify"));

  } catch (e) {

    const error = getError(e);
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(apiError(error))
    yield put(failRegister(error.msg));
  }
}

function* checkCoupon(action) {
  try {
    const resp = yield axiosInstance().post(backendUrl + checkcoupon, { promoCode: action.promoCode });
    const { validcoupon } = resp.data;
    yield put(successCheckCoupon(validcoupon));

  } catch (e) {
    const error = getError(e);
    yield put(failCheckCoupon(error.msg));
  }
}

function* requestPayment(action) {
  try {
    const resp = yield axiosInstance().post(backendUrl + addpayment, { token: action.verifyToken, token1: action.token1, promoCode: action.promoCode });
    console.log(7777, resp)
    const { token, user } = resp.data;
    yield put(successLogin(token, user));

  } catch (e) {
    const error = getError(e);
    yield put(failRegister(error.msg));
  }
}

function* requestGetUser(action) {
  try {

    const resp = yield axiosInstance().get(backendUrl + userURL);
    yield put(successGetUser(resp.data));

  } catch (e) {

    const error = getError(e);
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(apiError(error))
    yield put(failGetUser(error.msg));
  }
}

function* requestVerify(action) {
  try {
    const resp = yield axiosInstance().post(backendUrl + verifyURL, { token: action.verifyToken, verifyCode: action.verifyCode });
    yield put(push("/auth/add-payment"));
  } catch (e) {
    const error = getError(e);
    yield put(showErrorMessage(error.msg === "not valid" ? "The code is not valid" : error.msg));
  }

}

function* requestForgotPassword(action) {
  try {

    const resp = yield axiosInstance().post(backendUrl + forgotURL, { email: action.email });
    yield put(successForgotPassword(resp.data));
    yield put(showSuccessMessage("Email has been sent to your address."));

  } catch (e) {

    const error = getError(e);
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(apiError(error))
    yield put(showErrorMessage(error.msg === "USER_DOES_NOT_EXIST" ? "User does not exist." : error.msg));
    yield put(failForgotPassword(error.msg));
  }
}

function* requestResetPassword(action) {
  try {

    const resp = yield axiosInstance().post(backendUrl + resetURL, {
      id: action.data.id,
      password: action.data.password,
    });
    yield put(successResetPassword(resp.data));
    yield put(showSuccessMessage("Password changed."));

  } catch (e) {

    const error = getError(e);
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    }
    yield put(apiError(error))
    yield put(showErrorMessage(error.msg === "NOT_FOUND_OR_ALREADY_USED" ? "Code not found or already used." : error.msg));
    yield put(failResetPassword(error.msg));
  }
}

/*
function* changeUser(action) {
  try {
    const resp = yield axios.put(backendUrl + "/api/user", { token: action.token });
    yield put(receiveChangeProfile(resp.data));
    yield put(push("/signin"));
  } catch (e) {
    yield put(cancelChangeProfile(e.response ? e.response.data.error : "Network Error"));
  }
}
*/

function* userSaga() {
  yield all([
    takeLatest(USER.REQUEST_LOGIN, requestLogin),
    takeLatest(USER.REQUEST_REGISTER, requestRegister),
    takeLatest(USER.REQUEST_CHECK_COUPON, checkCoupon),
    takeLatest(USER.REQUEST_PAYMENT, requestPayment),
    takeLatest(USER.REQUEST_GETUSER, requestGetUser),
    takeLatest(USER.REQUEST_VERIFY, requestVerify),
    takeLatest(USER.REQUEST_RESET_PASSWORD, requestResetPassword),
    takeLatest(USER.REQUEST_FORGOT_PASSWORD, requestForgotPassword),
    // takeLatest(USER.CHANGEPROFILE_REQUEST, changeUser)
  ]);
}

export default userSaga;
