import { takeLatest, all, put } from "redux-saga/effects";
import { types, liaccountActions, errorActions } from "../actions";
import { backendUrl } from "../utils";
import axios from "axios";
import { successChangeSettings } from "actions/liaccountActions";
import { removeToken } from "actions/userActions";
import getError from "./getError";
import getError1 from "./getError1";

const { LIACCOUNT } = types;
const {
  successAddAccount,
  successGetAccounts,
  successGetAccountById,
  successGetMessageByLiId,
  successDeleteAccount,
  successReLogin,
  successEmailCredSave,
  fail,
  setReplies,
  successHeadEditAccount,
  failHeadEditAccount,
  successAboutEditAccount,
  failAboutEditAccount,
} = liaccountActions;
const { sleepModeException } = errorActions;

const baseURL = backendUrl + "/linkedin";
const registerURL = "/login";
const verifyURL = "/login-verify";
const reLoginWithPassURL = "/linkedin-relogin-pass";
const profileHeadlineEditURL = "/profileEdit";
const profileAboutlineEditURL = "/profile-about";

// add account request saga
function* requestAddAccount(action) {
  const { email, password, plan } = action;
  try {
    const resp = yield axios.post(baseURL + registerURL, {
      email,
      password,
      planType: plan,
    });
    // console.log("requestAddAccount-->", resp);
    yield put(successAddAccount(resp.data));
  } catch (e) {
    // console.log(e, e.response);
    const error = getError1(e);
    const reLoginUrl =
      e.response && e.response.data && e.response.data.errors.reLoginUrl
        ? e.response.data.errors.reLoginUrl
        : "";
    yield put(fail(error, { reLoginUrl }));
  }
}

// delete account request saga
function* requestDeleteAccount(action) {
  const { _id } = action;
  try {
    yield axios.delete(`${baseURL}/${_id}`);
    yield put(successDeleteAccount(_id));
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

// get accounts request saga
function* requestGetAccounts(action) {
  try {
    const resp = yield axios.get(`${baseURL}/simple/all`);
    // console.log("success get li accounts", resp.data);
    yield put(
      successGetAccounts(
        resp.data.docs.map((acc) => {
          const { imageurl: profileImg, profileLink } = acc;
          const identifier = profileLink.split("/").reverse()[1];
          return { ...acc, profileImg, identifier };
        })
      )
    );
    if (resp.data.docs[0]) {
      yield put(setReplies(resp.data.docs[0].repliedData));
    }
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        // console.log(error);
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

//get account request by id saga
function* requestGetAccountById(action) {
  const { id } = action;
  try {
    const resp = yield axios.get(`${baseURL}/${id}`);
    yield put(
      successGetAccountById({
        ...resp.data._doc,
        allMessageData: resp.data.allMessageData,
      })
    );
  } catch (e) {
    console.log("requestGetAccountById-----", e);
  }
}
function* requestGetMessageByLiId(action) {
  const { id } = action;
  try {
    const resp = yield axios.get(`${baseURL}/messages/${id}`);
    yield put(
      successGetMessageByLiId({
        allMessageData: resp.data,
      })
    );
  } catch (e) {
    console.log("requestGetMessageByLiId-----", e);
  }
}

// edit accounts request saga
function* requestHeadEditAccount(action) {
  const { email, headLine } = action;
  try {
    const resp = yield axios.post(baseURL + profileHeadlineEditURL, {
      email,
      headLine,
    });
    // console.log(resp);
    yield put(successHeadEditAccount(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(failHeadEditAccount(error));
  }
}
function* requestAboutEditAccount(action) {
  const { email, aboutLine } = action;
  try {
    const resp = yield axios.post(baseURL + profileAboutlineEditURL, {
      email,
      aboutLine,
    });
    // console.log(resp);
    yield put(successAboutEditAccount(resp.data));
  } catch (e) {
    const error = getError(e);
    if (error.message && error.message.indexOf("sleep mode") > -1) {
      yield put(sleepModeException());
      yield put(failAboutEditAccount(error));
    } else {
      yield put(failAboutEditAccount(error));
    }
  }
}

function* requestChangeSettings(action) {
  const { _id, settings } = action;
  try {
    // console.log("change settings ...", settings);
    yield axios.put(`${baseURL}/${_id}`, { settings });
    // console.log("success update settings", resp);
    yield put(successChangeSettings(_id, settings));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestEmailCredSave(action) {
  const { _id, emailData } = action;
  console.log("emailData");

  console.log(emailData);
  try {
    // console.log("change settings ...", settings);
    const resp = yield axios.post(`${baseURL}/${_id}/emailCred`, {
      provider: emailData.provider,
      email: emailData.smtpEmail,
      password: emailData.smtpPassword,
      sender_first: emailData.smtpFirstName,
      sender_last: emailData.smtpLastName,
    });
    console.log("success requestEmailCredSave settings", resp);
    yield put(successEmailCredSave(_id, emailData));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestSendPIN(action) {
  const { email, password, pin, reLoginUrl, relogin, planType } = action;
  try {
    const resp = yield axios.post(baseURL + verifyURL, {
      email,
      password,
      code: pin,
      reLoginUrl,
      relogin,
      planType,
    });
    // console.log("success send pin", resp);
    yield put(successAddAccount(resp.data));
  } catch (e) {
    let error = "Network Error";
    // console.log(e, e.response);
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
    yield put(fail(error));
  }
}

function* requestReLogin(action) {
  const { email, password, cb } = action;
  // console.log(action);
  try {
    const resp = yield axios.post(baseURL + reLoginWithPassURL, {
      email,
      password,
    });
    yield put(successReLogin(resp.data));
    // console.log("successReLogin resp.data", resp.data);
    yield cb("success");
  } catch (e) {
    if (e.response && e.response.status === 521) {
      // yield put(sleepModeException());
      // const error = getError(e);
      cb("error", "No such Li email.");
      // yield put(
      //   fail({
      //     status: 521,
      //     message: "No such Li email.",
      //   })
      // );
    } else {
      console.log("Network Error==", e, e.response);
      const error = getError1(e);
      const reLoginUrl =
        e.response && e.response.data && e.response.data.errors.reLoginUrl
          ? e.response.data.errors.reLoginUrl
          : "";
      console.log(reLoginUrl);
      if (e.response.data && e.response.data.errors && reLoginUrl !== "") {
        yield put(fail(error, { reLoginUrl }));
      } else {
        cb("error", e.response.data.errors.msg);
      }
    }
  }
}

function* requestUpdateProfile(action) {
  const { email, profileLink } = action;
  try {
    yield axios.post(`${baseURL}/profile`, { email, profileLink });
  } catch (e) {
    let error = "Network Error";
    if (e.response) {
      if (!e.response.data.errors) {
        error = e.response.data;
        if (error === "Unauthorized") {
          yield put(removeToken());
        }
      } else if (typeof e.response.data.errors.msg === "object") {
        error = e.response.data.errors.msg[0].msg;
      } else {
        error = e.response.data.errors.msg;
      }
    }
  }
}
function* liaccountSaga() {
  yield all([
    takeLatest(LIACCOUNT.REQUEST_ADD, requestAddAccount),
    takeLatest(LIACCOUNT.REQUEST_GET, requestGetAccounts),
    takeLatest(LIACCOUNT.REQUEST_DELETE, requestDeleteAccount),
    takeLatest(LIACCOUNT.REQUEST_HEAD_EDIT, requestHeadEditAccount),
    takeLatest(LIACCOUNT.REQUEST_ABOUT_EDIT, requestAboutEditAccount),
    takeLatest(LIACCOUNT.REQUEST_SETTINGS, requestChangeSettings),
    takeLatest(LIACCOUNT.REQUEST_EMAIL_CRED_SAVE, requestEmailCredSave),
    takeLatest(LIACCOUNT.REQUEST_SENDPIN, requestSendPIN),
    takeLatest(LIACCOUNT.REQUEST_RELOGIN, requestReLogin),
    takeLatest(LIACCOUNT.REQUEST_UPDATE_PROFILE, requestUpdateProfile),
    takeLatest(LIACCOUNT.REQUEST_GET_BY_ID, requestGetAccountById),
    takeLatest(LIACCOUNT.REQUEST_GET_MESSAGES_BY_ID, requestGetMessageByLiId),
  ]);
}

export default liaccountSaga;
