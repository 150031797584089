import { takeLatest, all, put, takeEvery } from "redux-saga/effects";
import {
  types,
  campaignActions,
  errorActions,
  notifyActions,
} from "../actions";
import axios from "axios";
import { push } from "connected-react-router";
import { upgradeToProSuccess } from "actions/campaignActions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";

const { CAMPAIGN: ACTION_HEADER } = types;
const {
  successCreateCampaign,
  successGetCampaigns,
  successGetFullCampaignData,
  successGetCampaignById,
  successDeleteCampaign,
  successStartCampaign,
  successPauseCampaign,
  successExcludeTarget,
  successUpdateNote,
  successAssignTags,
  successExport,
  failExport,
  successGetContactInfo,
  successGetTargetsByCampaign,
  finishLoading,
  fail,
} = campaignActions;
const { apiError } = notifyActions;
const { sleepModeException } = errorActions;
const baseURL = backendUrl + "/campaign";

// add/update sequence request saga
function* requestCreateCampaign(action) {
  const { campaign, account, connectionData, repliedData } = action;
  let formData = new FormData();
  formData.append("excludeOption", campaign.excludeOption);
  formData.append("file", campaign.file);
  formData.append("isRunning", campaign.isRunning);
  formData.append("isStarted", campaign.isStarted);
  formData.append("liEmail", campaign.liEmail);
  formData.append("name", campaign.name);
  formData.append("includeReactOption", campaign.includeReactOption);
  formData.append("includeCommentsOption", campaign.includeCommentsOption);
  formData.append("sequenceId", campaign.sequenceId);
  formData.append("sourceLinks", campaign.sourceLinks);
  formData.append("type", campaign.type);
  try {
    const resp = yield axios({
      method: "post",
      url: baseURL,
      heaers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    const campaignData = {
      ...resp.data,
      identifier: "camp-" + resp.data.name.split(" -> ")[1].replace(/\W/g, "-"),
      id: resp.data._id,
      connectionData: connectionData
        .map((dat) => {
          const target = resp.data.targets.filter(
            (campTarget) =>
              campTarget.targetLink.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
          )[0];
          return target
            ? {
                targetName: target.name,
                date: dat.date,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
      repliedData: repliedData
        .map((dat) => {
          const target = resp.data.targets.filter(
            (campTarget) =>
              campTarget.targetLink.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
          )[0];
          return target
            ? {
                targetName: target.name,
                date: dat.createdAt,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
    };

    yield put(successCreateCampaign(campaignData));
    yield put(push(`/admin/${account}/campaigns`));
  } catch (e) {
    if (
      e.response.data.message &&
      e.response.data.message.indexOf("sleep mode") > -1
    ) {
      yield put(sleepModeException());
    } else {
      const error = getError(e);
      yield put(apiError(error));
      if (error.code === 401) {
        yield put(removeToken());
      } else if (error.code === 500) {
        yield put(push("/admin/error-500"));
      } else if (error.code === 404) {
        yield put(push("/admin/error-404"));
      }
      yield put(fail(error.msg));
    }
  }
}

// delete campaign request saga
function* requestDeleteCampaign(action) {
  const { _id, account } = action;
  // console.log("delete campaign", _id);
  try {
    yield axiosInstance().delete(`${baseURL}/${_id}`);

    // console.log("success delete campaign", resp.data);
    yield put(successDeleteCampaign(_id));
    yield put(push(`/admin/${account}/campaigns`));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// start campaign request saga
function* requestStartCampaign(action) {
  const { _id, mode } = action;
  // console.log("start campaign", _id, mode);
  try {
    const resp = yield axiosInstance().post(`${baseURL}/${mode}/${_id}`);

    // console.log("success start campaign", resp.data);
    yield put(successStartCampaign(resp.data));
  } catch (e) {
    if (
      e.response.data.message &&
      e.response.data.message.indexOf("sleep mode") > -1
    ) {
      yield put(sleepModeException());
    } else {
      const error = getError(e);
      yield put(apiError(error));
      if (error.code === 401) {
        yield put(removeToken());
      } else if (error.code === 500) {
        yield put(push("/admin/error-500"));
      } else if (error.code === 404) {
        yield put(push("/admin/error-404"));
      }
      yield put(fail(error.msg));
    }
  }
}

// pause campaign request saga
function* requestPauseCampaign(action) {
  const { _id } = action;
  // console.log("pause campaign", _id);
  try {
    const resp = yield axiosInstance().post(`${baseURL}/pause/${_id}`);
    // console.log("success pause campaign", resp.data);
    yield put(successPauseCampaign(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestGetFullCampainData(action) {
  try {
    const { liEmail, connectionData, repliedData } = action;
    const resp = yield axiosInstance().get(`${baseURL}?liEmail=${liEmail}`);
    let campaigns = resp.data.map((camp) => ({ ...camp }));
    campaigns = resp.data.map((camp) => ({
      ...camp,
      identifier: "camp-" + camp.name.split(" -> ")[1].replace(/\W/g, "-"),
      connectionData: connectionData
        .map((dat) => {
          const target = camp.targets.filter((campTarget) => {
            const link = campTarget.targetLink || campTarget.salesLink;
            if (campTarget.salesLink) {
              return campTarget.name === dat.firstName + " " + dat.lastName;
            }
            return (
              link.split("/").reverse()[1] ===
              dat.targetLink.split("/").reverse()[1]
            );
          })[0];
          return target
            ? {
                targetName: target.name,
                date: dat.date,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
      repliedData: repliedData
        .map((dat) => {
          const target = camp.targets.filter((campTarget) => {
            const link = campTarget.targetLink || campTarget.salesLink;
            if (campTarget.salesLink) {
              return campTarget.name === dat.firstName + " " + dat.lastName;
            }
            if (dat.targetLink)
              return (
                link.split("/").reverse()[1] ===
                dat.targetLink.split("/").reverse()[1]
              );
            else if (dat.salesLink)
              return (
                link.split("/").reverse()[1] ===
                dat.salesLink.split("/").reverse()[0]
              );
            else return false;
          })[0];
          return target
            ? {
                targetName: target.name,
                date: dat.createdAt,
                targetLink: target.targetLink,
              }
            : null;
        })
        .filter(Boolean),
    }));
    yield put(successGetFullCampaignData(campaigns));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// get campaigns request saga - mini
function* requestGetCampaigns(action) {
  try {
    const { liEmail } = action;
    const resp = yield axiosInstance().get(
      `${baseURL}/minidata?liEmail=${liEmail}`
    );
    yield put(successGetCampaigns(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}
// get campaign by Id
function* requestGetCampaignById(action) {
  try {
    const { id } = action;
    const resp = yield axiosInstance().get(
      `${baseURL}/${id.split("-")[1]}/withTarget`
    );
    yield put(successGetCampaignById(resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

// Get Specific targets for Campaign

function* requestGetTargetsByCampaign(action) {
  try {
    const { id } = action;
    const resp = yield axiosInstance().get(`${baseURL}/target/${id}`);
    yield put(successGetTargetsByCampaign(id, resp.data));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestExcludeTarget(action) {
  const {
    browserKey,
    targetLink,
    salesLink,
    campIdentifier,
    targetIndex,
    status,
  } = action;
  try {
    yield axiosInstance().post(`${baseURL}/excludeTarget`, {
      browserKey,
      targetLink: targetLink ? targetLink : undefined,
      salesLink: targetLink ? undefined : salesLink,
      notExcluded: status,
    });
    yield put(successExcludeTarget(campIdentifier, targetIndex));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestUpdateNote(action) {
  const { salesLink, note } = action;
  try {
    yield axiosInstance().post(`${baseURL}/addNoteToTarget`, {
      salesLink,
      note,
    });

    yield put(successUpdateNote());
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestAssignTags(action) {
  const { id, tags, campIdentifier, targetIndex } = action;
  try {
    yield axiosInstance().post(`${backendUrl}/target/${id}/tags`, { tags });
    yield put(successAssignTags(tags, campIdentifier, targetIndex));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestExport(action) {
  const { id, syncType } = action;
  try {
    const resp = yield axiosInstance().get(
      `${baseURL}/${id}/${
        syncType === "replied" ? "replySheet" : "connectionSheet"
      }`
    );
    yield put(successExport(resp.data.sheetId));
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(failExport(error.msg));
  }
}

function* contactInfoPerOne({
  targetLink,
  salesLink,
  targetIndex,
  campIdentifier,
}) {
  try {
    const resp = yield axiosInstance().post(`${backendUrl}/target?`, {
      targetLink: targetLink ? targetLink : undefined,
      salesLink: targetLink ? undefined : salesLink,
    });
    yield put(
      successGetContactInfo(resp.data || "", campIdentifier, targetIndex)
    );
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    if (error.msg === "Unauthorized") {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
  }
}

function* requestGetContactInfo(action) {
  yield all(action.targets.map((target) => contactInfoPerOne(target)));
  yield put(finishLoading());
}
function* upgradeToProRequest(action) {
  try {
    yield axiosInstance().post(
      `${backendUrl}/stripe/upgradePlan/${action.accountId}/${action.planId}`
    );
    yield put(upgradeToProSuccess());
    yield action.cb("success", "Plan Upgrade successfully.");
  } catch (e) {
    const error = getError(e);
    yield put(apiError(error));
    yield action.cb("danger", error.msg);
    yield put(upgradeToProSuccess());
  }
}
// function* contactInfoPerArray(targets) {
//   try {
//     const resp = yield axios.post(`${backendUrl}/targets`, {
//       ...targets,
//     });
//     console.log("resp in Saga:>> ", resp);
//     // yield put(
//     //   successGetContactInfo(resp.data || "", campIdentifier, targetIndex)
//     // );
//   } catch (e) {
//     const error = getError(e);
//     if (error === "Unauthorized") {
//       yield put(removeToken());
//     }
//   }
// }

// function* requestGetContactInfo(action) {
//   yield contactInfoPerArray(action.targets);
//   yield put(finishLoading());
// }

function* campaignSaga() {
  yield all([
    takeLatest(ACTION_HEADER.REQUEST_CREATE, requestCreateCampaign),
    takeLatest(ACTION_HEADER.REQUEST_GET, requestGetCampaigns),
    takeLatest(ACTION_HEADER.REQUEST_GET_FULL, requestGetFullCampainData),
    takeLatest(ACTION_HEADER.REQUEST_GET_BY_ID, requestGetCampaignById),
    takeLatest(ACTION_HEADER.REQUEST_DELETE, requestDeleteCampaign),
    takeLatest(ACTION_HEADER.REQUEST_START, requestStartCampaign),
    takeLatest(ACTION_HEADER.REQUEST_PAUSE, requestPauseCampaign),
    takeLatest(ACTION_HEADER.EXCLUDE_TARGET, requestExcludeTarget),
    takeLatest(ACTION_HEADER.UPDATE_NOTE, requestUpdateNote),
    takeLatest(ACTION_HEADER.REQUEST_ASSIGN_TAGS, requestAssignTags),
    takeLatest(ACTION_HEADER.REQUEST_EXPORT, requestExport),
    takeLatest(ACTION_HEADER.TARGET_REQUEST_GET, requestGetTargetsByCampaign),
    takeEvery(ACTION_HEADER.REQUEST_CONTACT, requestGetContactInfo),
    takeEvery(ACTION_HEADER.UPGRADE_TO_PRO, upgradeToProRequest),
  ]);
}

export default campaignSaga;
