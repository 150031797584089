import React, { useState, useRef, useEffect } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import NotificationAlert from "react-notification-alert";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// reactstrap components
import {
  Button,
  Row,
  Col,
  Input,
  Form,
  FormFeedback,
  InputGroup,
  Container,
  Label,
} from "reactstrap";

import { integrationActions } from "../../../actions";
// form validator
import { validURL } from "../../../utils";

const { requestAddIntegration, hideSuccessMessage } = integrationActions;

const IntegrationAddEditForm = (props) => {
  const { account } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [callbackUrl, setCallbackUrl] = useState("");
  const [action, setAction] = useState(new Map());
  const [actionBody, setActionBody] = useState([]);
  const [selectCampaign, setSelectCampaign] = useState(new Map());
  const [error, setError] = useState({});
  const notificationAlert = useRef();
  const allActions = [
    {
      label: "Send updates for new connections",
      value: "connect",
      isChecked: false,
      textStyle: "",
      postBody: ["targetName", "targetLink", "date"],
    },
    {
      label: "Send updates for new replies",
      value: "replies",
      isChecked: false,
      textStyle: "",
      postBody: ["targetName", "targetLink", "date"],
    },
  ];

  useEffect(() => {
    if (props.campaigns.length > 0) {
      setLoading(false);
    }
  }, [props.campaigns]);

  const submitForm = (e) => {
    e.preventDefault();
    let actionArray = [];
    action.forEach((item, key) => {
      if (item) {
        actionArray.push(key);
      }
    });
    let selectCampaignArray = [];
    selectCampaign.forEach((item, key) => {
      if (item) {
        selectCampaignArray.push(key);
      }
    });
    if (callbackUrl.trim() === "") {
      return setError({
        ...error,
        callbackUrl: true,
        callbackUrlError: false,
      });
    }
    // if (validURL(callbackUrl) === true) {
    //   return setError({
    //     ...error,
    //     callbackUrl: true,
    //     callbackUrlError: true,
    //   });
    // }
    if (actionArray.length === 0) {
      return setError({
        ...error,
        action: true,
      });
    }
    if (selectCampaignArray.length === 0) {
      return setError({
        ...error,
        selectCampaign: true,
      });
    }
    const liEmail = props.liaccount.email;

    props.requestAddIntegrationDesp(
      {
        callbackUrl,
        actions: actionArray,
        campaigns: selectCampaignArray,
        liEmail,
        type: "campaign",
        postBody: actionBody,
      },
      account
    );
    setLoading(true);
  };

  const goBack = () => {
    return props.history.push({
      pathname: `/admin/${account}/integration`,
      state: { state: "simple" },
    });
  };

  const testActionZapier = () => {
    if (callbackUrl && actionBody) {
      let actionBodyJson = {};
      for (let y = 0; y < actionBody.length; y++) {
        const element = actionBody[y];
        actionBodyJson[element] = "a";
      }
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", callbackUrl);
        xhr.send(
          JSON.stringify({
            firstName: "John",
            lastName: "Doe",
            targetLink: "https://www.linkedin.com/in/andy-ayer-a2313516a/",
            address: "123 Cool Lane. Chicago, IL",
            birthday: "12/2/84",
            connected_date: "November 6, 2020",
            phone: "7731231231",
            skype: "chillyboy69",
            website: "coolstuff.com",
            wechat: "chillyboy69",
            email: "johnDoe@gmail.com",
            lastMessage: "Hello John Doe - let's get zoomin soon.",
            repliedTime: "2020-10-21 17:45",
            company: "Acme Company",
            jobTitle: "Prospect Job Title",
            avatarURL: "https://perfectprospect.io/assets/img/pp-logo.png",
          })
        );
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            let options = {
              place: "tc",
              message: (
                <div>
                  <div>
                    Success! Please hit save and then complete the process on
                    Zapier.
                  </div>
                </div>
              ),
              type: "success",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            notificationAlert.current.notificationAlert(options);
          }
          if (xhr.readyState === 4 && xhr.status !== 200) {
            let options = {
              place: "tc",
              message: (
                <div>
                  <div>Callback url failed.</div>
                </div>
              ),
              type: "danger",
              icon: "now-ui-icons ui-1_bell-53",
              autoDismiss: 7,
            };
            notificationAlert.current.notificationAlert(options);
          }
        };
      } catch (e) {
        let options = {
          place: "tc",
          message: (
            <div>
              <div>Callback url failed.</div>
            </div>
          ),
          type: "danger",
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        notificationAlert.current.notificationAlert(options);
      }
    }
  };

  return (
    <div className="content Integrationlist">
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading Integration"
      ></LoadingOverlay>
      <NotificationAlert ref={notificationAlert} />
      <Form className="form" method="" onSubmit={submitForm}>
        <Row>
          <Col>
            <div className="ml-auto mr-auto col-12 col-xl-9">
              <h3>Add Zapier Webhooks</h3>
              <p>
                Export data to your favorite CRM by integrating with Zapier.
              </p>
              <p>
                <a
                  href="https://www.youtube.com/watch?v=BO2La5Qura4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="nc-icon nc-bulb-63" /> Watch the step-by-step
                  tutorial here.
                </a>
              </p>
              <div className="card">
                <div className="card-body">
                  {error.selectCampaign && (
                    <div className="alert alert-danger">
                      Please select campaign
                    </div>
                  )}
                  {error.action && (
                    <div className="alert alert-danger">
                      Please select any action
                    </div>
                  )}

                  <InputGroup className="Integration-name-field">
                    <Input
                      style={{ marginBottom: 10 }}
                      className="fade-in"
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setCallbackUrl(e.target.value);
                          setError({
                            ...error,
                            callbackUrl: false,
                          });
                        }
                      }}
                      invalid={error.callbackUrl}
                      placeholder="Enter Callback URL here"
                    ></Input>
                    <FormFeedback>
                      {error.callbackUrlError ? (
                        <span>Callback URL is invalid</span>
                      ) : (
                        <span>Callback URL is required</span>
                      )}
                    </FormFeedback>
                  </InputGroup>
                  <InputGroup className="Integration-name-field">
                    <Label>
                      <h5> Type of updates to send: </h5>
                    </Label>
                    <Container>
                      {allActions.map((item, index) => {
                        return (
                          <Col key={index} className={"form-check"}>
                            <InputGroup>
                              <Label
                                className={"form-check-label"}
                                htmlFor={item.value}
                              >
                                <Input
                                  type="checkbox"
                                  name="action"
                                  id={item.value}
                                  className={"form-check-input"}
                                  value={item.value}
                                  onChange={(e) => {
                                    setError({
                                      ...error,
                                      action: false,
                                    });
                                    setAction(
                                      action.set(
                                        e.target.value,
                                        e.target.checked
                                      )
                                    );
                                    setActionBody(item.postBody);
                                  }}
                                />
                                <span className="form-check-sign">
                                  <span className="check"></span>
                                </span>
                                {item.label}
                              </Label>
                            </InputGroup>
                          </Col>
                        );
                      })}
                    </Container>
                  </InputGroup>
                  <InputGroup className="Integration-name-field">
                    <Label>
                      <h5>Choose which campaigns to connect:</h5>
                    </Label>
                    <Container>
                      {props.campaigns &&
                        props.campaigns
                          .sort((itemA, itemB) => {
                            return (
                              new Date(itemB.createdAt) -
                              new Date(itemA.createdAt)
                            );
                          })
                          .map((campaign, index) => {
                            return (
                              <Col key={index} className="form-check">
                                <InputGroup>
                                  <Label
                                    className="form-check-label"
                                    htmlFor={campaign.name}
                                  >
                                    {campaign.name.substring(
                                      campaign.name.lastIndexOf(">") + 2
                                    )}
                                    <Input
                                      type="checkbox"
                                      name="campaign"
                                      className="form-check-input"
                                      value={campaign.name}
                                      id={campaign.name}
                                      onChange={(e) => {
                                        setSelectCampaign(
                                          selectCampaign.set(
                                            campaign.name,
                                            e.target.checked
                                          )
                                        );
                                        setError({
                                          ...error,
                                          selectCampaign: false,
                                        });
                                      }}
                                    />
                                    <span className="form-check-sign">
                                      <span className="check"></span>
                                    </span>
                                  </Label>
                                </InputGroup>
                              </Col>
                            );
                          })}
                      <div className="py-2">
                        <Button
                          type="button"
                          className="btn-warning"
                          onClick={testActionZapier}
                        >
                          Test Zapier
                        </Button>
                        {/* <Button type="button" onClick={exportActionZapier}>
                            Export Zapier
                          </Button> */}
                        <Button type="submit" color="primary">
                          Save
                        </Button>
                        <Button type="button" onClick={goBack}>
                          Cancel
                        </Button>
                      </div>
                    </Container>
                  </InputGroup>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  liaccount: state.liaccounts.liaccount,
  sequences: state.sequences.sequences,
  campaigns: state.campaigns.campaigns,
  loading: state.sequences.loading,
  successMessage: state.sequences.successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestHideSuccess: () => dispatch(hideSuccessMessage()),
  requestAddIntegrationDesp: (data, liEmail) =>
    dispatch(requestAddIntegration(data, liEmail)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(IntegrationAddEditForm)
);
