import { takeLatest, all, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { types, planActions, notifyActions } from "../actions";
import {
  successActivatePlan,
  successCancelSubscription,
  successUpgradePlan,
  successUnsubscribe,
  successReactivate,
} from "actions/planActions";
import { requestGetAccounts } from "actions/liaccountActions";
import { requestGetUser } from "actions/userActions";
import { backendUrl } from "../utils";
import axiosInstance from "../utils/axiosInstance";
import getError from "./getError";
import { removeToken } from "actions/userActions";


const { PLAN } = types;
const { successGetPlans, fail } = planActions;
const { apiError } = notifyActions;

const planURL = backendUrl + "/stripe";

function* requestGetPlans() {
  try {
    const resp = yield axiosInstance().get(planURL + "/plans");
    yield put(successGetPlans(resp.data));
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestActivatePlan(action) {
  const { planId } = action;
  console.log("activate plan", planId);
  try {
    const resp = yield axiosInstance().get(planURL + "/endTrial");
    console.log("asdf", resp);
    if (planId) {
      yield axiosInstance().post(planURL + "/upgradePlan", {
        planID: planId,
      });
    }
    yield put(successActivatePlan());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestUpgradePlan(action) {
  const { planId } = action;
  try {
    const resp = yield axiosInstance().post(planURL + "/upgradePlan", {
      planID: planId,
    });
    console.log(resp);
    yield put(successUpgradePlan());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestUnsubscribe() {
  try {
    const resp = yield axiosInstance().get(planURL + "/unsubscribe");
    console.log(resp);
    yield put(successUnsubscribe());
    yield put(requestGetUser());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestCancelSubscription(action) {
  const { accountId } = action;
  try {
    const resp = yield axiosInstance().post(planURL + "/cancel_subscription", {
      li_id: accountId,
    });
    console.log(resp);
    yield put(requestGetAccounts());
    yield put(successCancelSubscription());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* requestReactivate(action) {
  const { accountId } = action;
  try {
    const resp = yield axiosInstance().post(planURL + "/reactivate", {
      li_id: accountId,
    });
    console.log(resp);
    yield put(requestGetAccounts());
    yield put(successReactivate());
  } catch (e) {

    const error = getError(e);
    yield put(apiError(error));
    if (error.code === 401) {
      yield put(removeToken());
    } else if (error.code === 500) {
      yield put(push("/admin/error-500"));
    } else if (error.code === 404) {
      yield put(push("/admin/error-404"));
    }
    yield put(fail(error.msg));
  }
}

function* planSaga() {
  yield all([
    takeLatest(PLAN.REQUEST_PLANS, requestGetPlans),
    takeLatest(PLAN.REQUEST_ACTIVATE, requestActivatePlan),
    takeLatest(PLAN.REQUEST_UPGRADE, requestUpgradePlan),
    takeLatest(PLAN.REQUEST_UNSUBSCRIBE, requestUnsubscribe),
    takeLatest(PLAN.REQUEST_CANCEL_SUBSCRIPTION, requestCancelSubscription),
    takeLatest(PLAN.REQUEST_REACTIVATE, requestReactivate),
    // takeLatest(USER.CHANGEPROFILE_REQUEST, changeUser)
  ]);
}

export default planSaga;
