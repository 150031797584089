import React, { useState, Fragment } from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Nav,
  NavLink,
  NavItem,
  Card,
  CardBody,
  TabPane,
  TabContent,
  Spinner,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import AccountSubscription from "../components/accountmanager/AccountSubscription";

import { planActions, userActions } from "../../actions";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { upgradeToProRequest } from "actions/campaignActions";
import NotificationAlert from "react-notification-alert";
import {
  requestGetAccountById,
  requestGetMessageByLiId,
} from "./../../actions/liaccountActions";

const { requestGetUser } = userActions;
const {
  requestGetPlans,
  requestUnsubscribe,
  requestReactivate,
  requestCancelSubscription,
} = planActions;

const ProfileManager = ({
  user,
  liaccounts,
  plans,
  loadingPlan,
  loadingAuth,
  history,
  ...restProps
}) => {
  const [type, setType] = useState("subscriptions");
  const [alert, setAlert] = useState(null);
  const [isRequesting, setIsRequesting] = useState(false);
  // const [currentPlan, setCurrentPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("Loading Account Manager");

  const [selectedSeat, setSelectedSeat] = useState("");

  useEffect(() => {
    if (!plans.length && user) {
      restProps.requestGetPlans();
    }
    // if (
    //   restProps.location.state &&
    //   restProps.location.state.upgradePlan &&
    //   restProps.location.state.upgradePlan === true
    // ) {
    //   setType("subscriptionsPlan");
    // }
    if (restProps.liaccount) {
      // setCurrentPlan(restProps.liaccount.planType);
    }
  }, [plans.length, user, restProps]);

  useEffect(() => {
    if (history.location.hash !== "") {
      setType(history.location.hash.substr(1));
    }
  }, [history]);

  const reactivateSubscription = (_id) => {
    restProps.requestReactivate(_id);
  };

  const cancelSubscription = (_id) => {
    restProps.requestCancelSubscription(_id);
    setAlert(null);
  };

  const showSubscriptionModal = (_id, isExpired, billDate) => {
    if (isExpired) {
      reactivateSubscription(_id);
    } else
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={`Your subscription will expire on ${billDate.format("ll")}`}
          onConfirm={() => {
            cancelSubscription(_id);
          }}
          onCancel={() => setAlert(null)}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Confirm Cancelation"
        ></ReactBSAlert>
      );
  };

  const goToStripePortal = () => {
    setIsRequesting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/stripe/create_customer_portal_session`
      )
      .then((res) => {
        setIsRequesting(false);
        const { data } = res;
        window.open(data.url, "_blank");
      })
      .catch((e) => {
        setIsRequesting(false);
        // console.log("getStripPortal error ---->", e);
      });
  };
  const notificationAlert = React.createRef();

  const submitSubscribe = (planName, id) => {
    setLoadingMsg("");
    setLoading(true);
    restProps.upgradeToProRequestFunc(
      id,
      planName.toLowerCase(),
      function (nottificationType, nottificationText) {
        let options = {
          place: "tc",
          message: (
            <div>
              <div>{nottificationText}</div>
            </div>
          ),
          type: nottificationType,
          icon: "now-ui-icons ui-1_bell-53",
          autoDismiss: 7,
        };
        if (notificationAlert.current)
          notificationAlert.current.notificationAlert(options);
        if (nottificationType === "success") {
          restProps.requestGetAccountById(id);
          restProps.requestGetMessageByLiId(id);
          window.location.reload();
        }
      }
    );
  };

  return (
    <>
      <LoadingOverlay
        active={!plans.length || loadingPlan || loading}
        spinner
        text={loadingMsg}
      ></LoadingOverlay>
      <div className="content campaignlist">
        {alert ? alert : null}
        <Col md="12" xl="12" className="ml-auto mr-auto">
          <h4 className="el-inline">Account Manager</h4>
          <NotificationAlert ref={notificationAlert} />
          <Card className="campaign-builder-card">
            <CardBody>
              <Row>
                <Col lg="4" md="5" sm="4" xs="6">
                  <div className="nav-tabs-navigation verical-navs mb-2">
                    <div className="nav-tabs-wrapper">
                      <Nav
                        className="flex-column nav-stacked"
                        role="tablist"
                        tabs
                      >
                        <NavItem onClick={() => setType("subscriptions")}>
                          <NavLink
                            data-toggle="tab"
                            href="#subscriptions"
                            role="tab"
                            className={type === "subscriptions" ? "active" : ""}
                            style={{ padding: "20px" }}
                          >
                            LinkedIn Account Seats
                          </NavLink>
                        </NavItem>
                        {user && Object.keys(user).length > 0 && (
                          <NavItem>
                            <NavLink
                              data-toggle="tab"
                              href="#Billing"
                              role="tab"
                              style={{ padding: "20px" }}
                              onClick={() => {
                                goToStripePortal();
                              }}
                            >
                              {isRequesting ? (
                                <Spinner color="primary" size="sm" />
                              ) : (
                                "Billing"
                              )}
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                  </div>
                </Col>
                <Col
                  lg="8"
                  md="7"
                  sm="8"
                  xs="6"
                  className="d-flex justify-content-between"
                >
                  <TabContent activeTab={type}>
                    <TabPane tabId={"subscriptions"} key={"subscriptions"}>
                      <Fragment>
                        {liaccounts.map((liaccount) => (
                          <AccountSubscription
                            key={liaccount._id}
                            liaccount={liaccount}
                            showSubscriptionModal={showSubscriptionModal}
                            collapsed={selectedSeat}
                            selectSeat={() => setSelectedSeat(liaccount._id)}
                            submitSubscribe={submitSubscribe}
                          />
                        ))}
                      </Fragment>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  liaccounts: state.liaccounts.liaccounts,
  liaccount: state.liaccounts.liaccount,
  plans: state.plans.plans,
  user: state.authentication.user,
  loadingPlan: state.plans.loading,
  loadingAuth: state.authentication.loading,
});

const mapDispatchToProps = (dispatch) => ({
  requestGetPlans: () => dispatch(requestGetPlans()),
  requestUnsubscribe: () => dispatch(requestUnsubscribe()),
  requestCancelSubscription: (accountId) =>
    dispatch(requestCancelSubscription(accountId)),
  requestReactivate: (accountId) => dispatch(requestReactivate(accountId)),
  requestGetUser: () => dispatch(requestGetUser()),
  upgradeToProRequestFunc: (planId, accountId, cb) =>
    dispatch(upgradeToProRequest(planId, accountId, cb)),
  requestGetAccountById: (accountId) =>
    dispatch(requestGetAccountById(accountId)),
  requestGetMessageByLiId: (id) => dispatch(requestGetMessageByLiId(id)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(ProfileManager)
);
