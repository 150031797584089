import axios from 'axios';


const axiosInstance = () => {

    const headers = { 'Content-Type': 'application/json' };

    const token = localStorage.getItem('rubato_idToken');

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return axios.create({
        baseURL: process.env.REACT_APP_SERVER_URL,
        headers
    });
};

axiosInstance().interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
