/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import ReactBSAlert from "react-bootstrap-sweetalert";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormFeedback,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";

import { validator } from "../../utils";
import { userActions } from "../../actions";
const {
  requestForgotPassword,
  hideSuccessMessage,
  hideErrorMessage,
} = userActions;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: -1,
      error: "",
      successMessage: "",
      errorMessage: "",
    };
    this.props.hideSuccessMessage();
    this.props.hideErrorMessage();
  }
  componentDidMount() {
    document.body.classList.toggle("reset-password-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("reset-password-page");
  }
  componentDidUpdate(prevProps) {
    if (this.props.error && this.props.error !== prevProps.error) {
      this.setState({
        error: this.props.error,
      });
    }
    if (
      this.props.successMessage &&
      this.props.successMessage !== prevProps.successMessage
    ) {
      this.setState({
        successMessage: this.props.successMessage,
      });
    }
    if (
      this.props.errorMessage &&
      this.props.errorMessage !== prevProps.errorMessage
    ) {
      this.setState({
        errorMessage: this.props.errorMessage,
      });
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;
    if (email === -1) {
      this.setState({ email: "" });
    }

    if (validator.checkEmailValidation(email) || email === -1) {
      return;
    }
    this.props.hideSuccessMessage();
    this.props.hideErrorMessage();
    this.props.requestForgotPassword(email);
  };
  setEmail = (e) => {
    this.setState({ email: e.target.value });
  };
  hideAlert = () => {
    this.setState({ successMessage: "", errorMessage: "" });
  };
  render() {
    const { email, successMessage, errorMessage } = this.state;
    return (
      <div className="reset-password-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4" md="6">
              <Form
                action=""
                className="form"
                method=""
                onSubmit={this.handleSubmit}
              >
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Forgot Password</h3>
                    </CardHeader>
                  </CardHeader>
                  <CardBody>
                    {/* {error && (
                      <InputGroup>
                        <Input hidden invalid />
                        <FormFeedback>{error}</FormFeedback>
                      </InputGroup>
                    )} */}
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        invalid={validator.checkEmailValidation(email)}
                        onChange={this.setEmail}
                        onFocus={this.setEmail}
                      />
                      <FormFeedback>
                        {email === "" ? `Email required` : `Invalid Email`}
                      </FormFeedback>
                    </InputGroup>
                  </CardBody>
                  <CardFooter>
                    <Button block className="btn-round mb-3" color="warning">
                      Reset
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
          className="full-page-background"
          style={{
            backgroundImage: `url(${require("assets/img/bg/joshua-earles.jpg")})`,
          }}
        />
        {successMessage && (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            {successMessage}
          </ReactBSAlert>
        )}
        {errorMessage && (
          <ReactBSAlert
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            {errorMessage}
          </ReactBSAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.authentication.error,
  loading: state.authentication.loading,
  successMessage: state.authentication.successMessage,
  errorMessage: state.authentication.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  requestForgotPassword: (email) => dispatch(requestForgotPassword(email)),
  hideSuccessMessage: () => dispatch(hideSuccessMessage()),
  hideErrorMessage: () => dispatch(hideErrorMessage()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
