import ManagePage from "views/pages/ManagePage.jsx";
import Login from "views/pages/Login.jsx";
import ResetPassword from "views/pages/ResetPassword.jsx";
import Register from "views/pages/Register.jsx";
import Verify from "views/pages/Verify.jsx";
import AddPayment from "views/pages/AddPayment.jsx";
import Timeline from "views/pages/Timeline.jsx";
import LIAccountPage from "views/pages/LIAccountPage";
import PlanPage from "views/pages/PlanPage";
import ForgotPassword from "views/pages/ForgotPassword";
import MaintainPage from "views/pages/MaintainPage";
import ErrorPage from "views/pages/ErrorHandler/ErrorPage";

const routes = [
  {
    path: "/",
    name: "Linkedin Accounts",
    icon: "nc-icon nc-single-02",
    component: LIAccountPage,
    layout: "/admin",
    isExact: true,
  },
  {
    path: "/maintain",
    name: "MaintainPage",
    component: MaintainPage,
    layout: "admin",
    isExact: true
  },
  {
    path: "/error-404",
    name: "ErrorPage",
    component: ErrorPage,
    layout: "/admin",
    isExact: true
  },
  {
    path: "/error-500",
    name: "ErrorPage",
    component: ErrorPage,
    layout: "/admin",
    isExact: true
  },
  {
    path: "/:account",
    name: "ManagePage",
    icon: "nc-icon nc-single-02",
    component: ManagePage,
    layout: "/admin",
    isExact: true,
  },
  {
    path: "/:account/:page",
    name: "ManagePage",
    icon: "nc-icon nc-single-02",
    component: ManagePage,
    layout: "/admin",
    isExact: true,
  },
  {
    path: "/:account/:page/:identifier",
    name: "ManagePage",
    icon: "nc-icon nc-single-02",
    component: ManagePage,
    layout: "/admin",
    isExact: true,
  },
  {
    path: "/:account/:page/:identifier/:type",
    name: "ManagePage",
    icon: "nc-icon nc-single-02",
    component: ManagePage,
    layout: "/admin",
    isExact: true,
  },
  {
    collapse: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/timeline",
        name: "Timeline",
        mini: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/pricing",
        name: "Pricing",
        mini: "R",
        component: PlanPage,
        layout: "/auth",
      },
      {
        path: "/verify",
        name: "Verify",
        mini: "R",
        component: Verify,
        layout: "/auth",
      },
      {
        path: "/add-payment",
        name: "AddPayment",
        mini: "R",
        component: AddPayment,
        layout: "/auth",
      },
      {
        path: "/reset-password/:token",
        name: "Reset Password",
        mini: "R",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        mini: "R",
        component: ForgotPassword,
        layout: "/auth",
      },
    ],
  }
];

export default routes;
